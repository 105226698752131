import { DataPointDemo } from "@kiosk/front/api/dashboard/types"

export const Governance1: DataPointDemo[] = [
  {
    id: "item-0",
    dimensionId: 5,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 83046.33,
  },
  {
    id: "item-1",
    dimensionId: 9,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 746759.13,
  },
  {
    id: "item-2",
    dimensionId: 3,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 992043.95,
  },
  {
    id: "item-3",
    dimensionId: 9,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 229143.97,
  },
  {
    id: "item-4",
    dimensionId: 1,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 915113.1,
  },
  {
    id: "item-5",
    dimensionId: 9,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 946728.47,
  },
  {
    id: "item-6",
    dimensionId: 2,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 599672.95,
  },
  {
    id: "item-7",
    dimensionId: 9,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 831019.42,
  },
  {
    id: "item-8",
    dimensionId: 8,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 399409.47,
  },
  {
    id: "item-9",
    dimensionId: 9,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 518185.28,
  },
  {
    id: "item-10",
    dimensionId: 5,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 163947.02,
  },
  {
    id: "item-11",
    dimensionId: 2,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 1399394.44,
  },
  {
    id: "item-12",
    dimensionId: 7,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 1422411.81,
  },
  {
    id: "item-13",
    dimensionId: 9,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 471817.74,
  },
  {
    id: "item-14",
    dimensionId: 6,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 804571.55,
  },
  {
    id: "item-15",
    dimensionId: 5,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 377011.71,
  },
  {
    id: "item-16",
    dimensionId: 6,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 1462971.58,
  },
  {
    id: "item-17",
    dimensionId: 2,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 540165.33,
  },
  {
    id: "item-18",
    dimensionId: 7,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 1001818.08,
  },
  {
    id: "item-19",
    dimensionId: 8,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 313656.92,
  },
  {
    id: "item-20",
    dimensionId: 4,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 1282203.18,
  },
  {
    id: "item-21",
    dimensionId: 9,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 25204.93,
  },
  {
    id: "item-22",
    dimensionId: 9,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 614550.13,
  },
  {
    id: "item-23",
    dimensionId: 2,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 165086.08,
  },
  {
    id: "item-24",
    dimensionId: 4,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 378732.73,
  },
  {
    id: "item-25",
    dimensionId: 4,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 574827.33,
  },
  {
    id: "item-26",
    dimensionId: 6,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 267718.96,
  },
  {
    id: "item-27",
    dimensionId: 4,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 1718982.19,
  },
  {
    id: "item-28",
    dimensionId: 8,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 1234109.64,
  },
  {
    id: "item-29",
    dimensionId: 1,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 668142.41,
  },
  {
    id: "item-30",
    dimensionId: 7,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 871846.38,
  },
  {
    id: "item-31",
    dimensionId: 3,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 925985.23,
  },
  {
    id: "item-32",
    dimensionId: 1,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 812911.97,
  },
  {
    id: "item-33",
    dimensionId: 2,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 1019332.73,
  },
  {
    id: "item-34",
    dimensionId: 5,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 1027609.81,
  },
  {
    id: "item-35",
    dimensionId: 6,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 1452714.77,
  },
  {
    id: "item-36",
    dimensionId: 3,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 474171.41,
  },
  {
    id: "item-37",
    dimensionId: 9,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 1312162.96,
  },
  {
    id: "item-38",
    dimensionId: 1,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 734538.16,
  },
  {
    id: "item-39",
    dimensionId: 7,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 1666476.21,
  },
  {
    id: "item-40",
    dimensionId: 7,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 93565.28,
  },
  {
    id: "item-41",
    dimensionId: 7,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 1562261.83,
  },
  {
    id: "item-42",
    dimensionId: 4,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 1372463.01,
  },
  {
    id: "item-43",
    dimensionId: 6,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 1190461.91,
  },
  {
    id: "item-44",
    dimensionId: 1,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 818969.6,
  },
  {
    id: "item-45",
    dimensionId: 8,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 1879766.97,
  },
  {
    id: "item-46",
    dimensionId: 5,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 295584.49,
  },
  {
    id: "item-47",
    dimensionId: 5,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 1228013.67,
  },
  {
    id: "item-48",
    dimensionId: 5,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 369185.56,
  },
  {
    id: "item-49",
    dimensionId: 9,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 913920.39,
  },
  {
    id: "item-50",
    dimensionId: 3,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 938296.84,
  },
  {
    id: "item-51",
    dimensionId: 2,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 1726567.93,
  },
  {
    id: "item-52",
    dimensionId: 8,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 1693981.98,
  },
  {
    id: "item-53",
    dimensionId: 3,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 1376331.18,
  },
  {
    id: "item-54",
    dimensionId: 7,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 1595510.04,
  },
  {
    id: "item-55",
    dimensionId: 8,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 2096771.23,
  },
  {
    id: "item-56",
    dimensionId: 7,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 1999112.88,
  },
  {
    id: "item-57",
    dimensionId: 9,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 1564315.88,
  },
  {
    id: "item-58",
    dimensionId: 9,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 1770334.57,
  },
  {
    id: "item-59",
    dimensionId: 5,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 881017.84,
  },
  {
    id: "item-60",
    dimensionId: 2,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 429907.21,
  },
  {
    id: "item-61",
    dimensionId: 7,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 1981675.87,
  },
  {
    id: "item-62",
    dimensionId: 5,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 588208.5,
  },
  {
    id: "item-63",
    dimensionId: 8,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 1114728.74,
  },
  {
    id: "item-64",
    dimensionId: 5,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 2134237.14,
  },
  {
    id: "item-65",
    dimensionId: 9,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 1842429.82,
  },
  {
    id: "item-66",
    dimensionId: 2,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 996770.81,
  },
  {
    id: "item-67",
    dimensionId: 8,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 1794245.45,
  },
  {
    id: "item-68",
    dimensionId: 5,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 2387645.11,
  },
  {
    id: "item-69",
    dimensionId: 1,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 1185843.27,
  },
  {
    id: "item-70",
    dimensionId: 6,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 990226.38,
  },
  {
    id: "item-71",
    dimensionId: 4,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 2169420,
  },
  {
    id: "item-72",
    dimensionId: 2,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 1818951.55,
  },
  {
    id: "item-73",
    dimensionId: 5,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 2522577.21,
  },
  {
    id: "item-74",
    dimensionId: 8,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 347414.25,
  },
  {
    id: "item-75",
    dimensionId: 2,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 1920388.84,
  },
  {
    id: "item-76",
    dimensionId: 1,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 507362.74,
  },
  {
    id: "item-77",
    dimensionId: 3,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 953505.27,
  },
  {
    id: "item-78",
    dimensionId: 8,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 830509.14,
  },
  {
    id: "item-79",
    dimensionId: 5,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 2340496.9,
  },
  {
    id: "item-80",
    dimensionId: 5,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 1063402.15,
  },
  {
    id: "item-81",
    dimensionId: 1,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 1400475.14,
  },
  {
    id: "item-82",
    dimensionId: 2,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 3066745.59,
  },
  {
    id: "item-83",
    dimensionId: 8,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 2389402.93,
  },
  {
    id: "item-84",
    dimensionId: 6,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 1073862.68,
  },
  {
    id: "item-85",
    dimensionId: 5,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 2139195.45,
  },
  {
    id: "item-86",
    dimensionId: 6,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 1912812.83,
  },
  {
    id: "item-87",
    dimensionId: 2,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 939461.55,
  },
  {
    id: "item-88",
    dimensionId: 6,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 677226.77,
  },
  {
    id: "item-89",
    dimensionId: 4,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 203663.06,
  },
  {
    id: "item-90",
    dimensionId: 5,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 1912976.21,
  },
  {
    id: "item-91",
    dimensionId: 6,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 4122399.01,
  },
  {
    id: "item-92",
    dimensionId: 9,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 4238462.81,
  },
  {
    id: "item-93",
    dimensionId: 5,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 3628477.6,
  },
  {
    id: "item-94",
    dimensionId: 4,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 4664145.2,
  },
  {
    id: "item-95",
    dimensionId: 7,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 2824471.72,
  },
  {
    id: "item-96",
    dimensionId: 1,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 1925382.07,
  },
  {
    id: "item-97",
    dimensionId: 3,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 4567624.88,
  },
  {
    id: "item-98",
    dimensionId: 7,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 40152.41,
  },
  {
    id: "item-99",
    dimensionId: 6,
    info: "+ 3% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Revenu total",
    labelEN: "Total revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 16437939.2,
  },
]
