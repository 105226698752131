import { DataPointDemo } from "@kiosk/front/api/dashboard/types"

export const Environment1: DataPointDemo[] = [
  {
    id: "item-0",
    dimensionId: 9,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 113.28,
  },
  {
    id: "item-1",
    dimensionId: 4,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 49.82,
  },
  {
    id: "item-2",
    dimensionId: 4,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 64.19,
  },
  {
    id: "item-3",
    dimensionId: 9,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 95.74,
  },
  {
    id: "item-4",
    dimensionId: 5,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 108.54,
  },
  {
    id: "item-5",
    dimensionId: 3,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 104.32,
  },
  {
    id: "item-6",
    dimensionId: 1,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 38.57,
  },
  {
    id: "item-7",
    dimensionId: 9,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 269.37,
  },
  {
    id: "item-8",
    dimensionId: 1,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 109.28,
  },
  {
    id: "item-9",
    dimensionId: 2,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 224.98,
  },
  {
    id: "item-10",
    dimensionId: 7,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 82.32,
  },
  {
    id: "item-11",
    dimensionId: 4,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 50.16,
  },
  {
    id: "item-12",
    dimensionId: 7,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 193.48,
  },
  {
    id: "item-13",
    dimensionId: 3,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 143.33,
  },
  {
    id: "item-14",
    dimensionId: 9,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 217.7,
  },
  {
    id: "item-15",
    dimensionId: 5,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 271.39,
  },
  {
    id: "item-16",
    dimensionId: 5,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 207.97,
  },
  {
    id: "item-17",
    dimensionId: 5,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 56.41,
  },
  {
    id: "item-18",
    dimensionId: 8,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 215.46,
  },
  {
    id: "item-19",
    dimensionId: 1,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 143.03,
  },
  {
    id: "item-20",
    dimensionId: 1,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 121.89,
  },
  {
    id: "item-21",
    dimensionId: 2,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 150.94,
  },
  {
    id: "item-22",
    dimensionId: 1,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 31.25,
  },
  {
    id: "item-23",
    dimensionId: 4,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 96.87,
  },
  {
    id: "item-24",
    dimensionId: 4,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 126.69,
  },
  {
    id: "item-25",
    dimensionId: 9,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 278.68,
  },
  {
    id: "item-26",
    dimensionId: 9,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 22.39,
  },
  {
    id: "item-27",
    dimensionId: 2,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 285.89,
  },
  {
    id: "item-28",
    dimensionId: 9,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 81.39,
  },
  {
    id: "item-29",
    dimensionId: 7,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 107.64,
  },
  {
    id: "item-30",
    dimensionId: 6,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 36.04,
  },
  {
    id: "item-31",
    dimensionId: 7,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 97.79,
  },
  {
    id: "item-32",
    dimensionId: 7,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 246.98,
  },
  {
    id: "item-33",
    dimensionId: 8,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 5.61,
  },
  {
    id: "item-34",
    dimensionId: 2,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 19.9,
  },
  {
    id: "item-35",
    dimensionId: 7,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 127.02,
  },
  {
    id: "item-36",
    dimensionId: 7,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 90.82,
  },
  {
    id: "item-37",
    dimensionId: 4,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 280.54,
  },
  {
    id: "item-38",
    dimensionId: 6,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 141.65,
  },
  {
    id: "item-39",
    dimensionId: 3,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 125.35,
  },
  {
    id: "item-40",
    dimensionId: 4,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 14.76,
  },
  {
    id: "item-41",
    dimensionId: 4,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 87.76,
  },
  {
    id: "item-42",
    dimensionId: 7,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 346.81,
  },
  {
    id: "item-43",
    dimensionId: 9,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 73.03,
  },
  {
    id: "item-44",
    dimensionId: 6,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 321.66,
  },
  {
    id: "item-45",
    dimensionId: 8,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 217.01,
  },
  {
    id: "item-46",
    dimensionId: 3,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 272.75,
  },
  {
    id: "item-47",
    dimensionId: 9,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 209.24,
  },
  {
    id: "item-48",
    dimensionId: 9,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 244.21,
  },
  {
    id: "item-49",
    dimensionId: 9,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 192.87,
  },
  {
    id: "item-50",
    dimensionId: 4,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 197.68,
  },
  {
    id: "item-51",
    dimensionId: 6,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 260.03,
  },
  {
    id: "item-52",
    dimensionId: 6,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 278.32,
  },
  {
    id: "item-53",
    dimensionId: 8,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 240.64,
  },
  {
    id: "item-54",
    dimensionId: 6,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 90.43,
  },
  {
    id: "item-55",
    dimensionId: 1,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 295.39,
  },
  {
    id: "item-56",
    dimensionId: 7,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 97.21,
  },
  {
    id: "item-57",
    dimensionId: 5,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 291.43,
  },
  {
    id: "item-58",
    dimensionId: 6,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 337.18,
  },
  {
    id: "item-59",
    dimensionId: 6,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 327.04,
  },
  {
    id: "item-60",
    dimensionId: 3,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 19.71,
  },
  {
    id: "item-61",
    dimensionId: 7,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 192.51,
  },
  {
    id: "item-62",
    dimensionId: 1,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 369.5,
  },
  {
    id: "item-63",
    dimensionId: 5,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 20.38,
  },
  {
    id: "item-64",
    dimensionId: 4,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 274.94,
  },
  {
    id: "item-65",
    dimensionId: 2,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 43.09,
  },
  {
    id: "item-66",
    dimensionId: 9,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 434.41,
  },
  {
    id: "item-67",
    dimensionId: 8,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 210.73,
  },
  {
    id: "item-68",
    dimensionId: 7,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 315.29,
  },
  {
    id: "item-69",
    dimensionId: 6,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 284.94,
  },
  {
    id: "item-70",
    dimensionId: 8,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 260.25,
  },
  {
    id: "item-71",
    dimensionId: 1,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 114.41,
  },
  {
    id: "item-72",
    dimensionId: 5,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 128.95,
  },
  {
    id: "item-73",
    dimensionId: 4,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 425.13,
  },
  {
    id: "item-74",
    dimensionId: 9,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 71.73,
  },
  {
    id: "item-75",
    dimensionId: 1,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 472.7,
  },
  {
    id: "item-76",
    dimensionId: 5,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 51.48,
  },
  {
    id: "item-77",
    dimensionId: 8,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 70.74,
  },
  {
    id: "item-78",
    dimensionId: 4,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 509.96,
  },
  {
    id: "item-79",
    dimensionId: 6,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 415.33,
  },
  {
    id: "item-80",
    dimensionId: 2,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 271.55,
  },
  {
    id: "item-81",
    dimensionId: 4,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 248.5,
  },
  {
    id: "item-82",
    dimensionId: 7,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 34.18,
  },
  {
    id: "item-83",
    dimensionId: 2,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 289.09,
  },
  {
    id: "item-84",
    dimensionId: 2,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 229.51,
  },
  {
    id: "item-85",
    dimensionId: 8,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 379.1,
  },
  {
    id: "item-86",
    dimensionId: 7,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 439.49,
  },
  {
    id: "item-87",
    dimensionId: 5,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 19.83,
  },
  {
    id: "item-88",
    dimensionId: 2,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 585.86,
  },
  {
    id: "item-89",
    dimensionId: 3,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 424.81,
  },
  {
    id: "item-90",
    dimensionId: 7,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 693.01,
  },
  {
    id: "item-91",
    dimensionId: 4,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 676.44,
  },
  {
    id: "item-92",
    dimensionId: 5,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 397.63,
  },
  {
    id: "item-93",
    dimensionId: 1,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 557.4,
  },
  {
    id: "item-94",
    dimensionId: 4,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 965.67,
  },
  {
    id: "item-95",
    dimensionId: 2,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 533.9,
  },
  {
    id: "item-96",
    dimensionId: 1,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 924.33,
  },
  {
    id: "item-97",
    dimensionId: 8,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 1240.29,
  },
  {
    id: "item-98",
    dimensionId: 4,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 1122.36,
  },
  {
    id: "item-99",
    dimensionId: 2,
    info: "+ 23% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre",
    labelEN: "Greenhouse gas emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 1390.75,
  },
]
