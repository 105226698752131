import { ReportsResponses } from "@kiosk/types/endpoints/reports"

export type Topic = ReportsResponses.GetCategories[number]["topics"][number]

export type DisclosureRequirement = Omit<
  Topic["disclosureRequirements"][number],
  "progress"
> & {
  progress?: number
}

export const topicSorter = (
  topic1: DisclosureRequirement,
  topic2: DisclosureRequirement,
) => {
  return topic1.order - topic2.order
}
