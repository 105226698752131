import { DataPointDemo } from "@kiosk/front/api/dashboard/types"

export const Environment8: DataPointDemo[] = [
  {
    id: "item-0",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 151378.1,
  },
  {
    id: "item-1",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 36229.83,
  },
  {
    id: "item-2",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 366686.79,
  },
  {
    id: "item-3",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 310561.01,
  },
  {
    id: "item-4",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 395568.61,
  },
  {
    id: "item-5",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 38126.62,
  },
  {
    id: "item-6",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 347175.97,
  },
  {
    id: "item-7",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 56712.02,
  },
  {
    id: "item-8",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 61398.95,
  },
  {
    id: "item-9",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 229223.09,
  },
  {
    id: "item-10",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 165513.24,
  },
  {
    id: "item-11",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 318409.99,
  },
  {
    id: "item-12",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 96587.68,
  },
  {
    id: "item-13",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 227766.95,
  },
  {
    id: "item-14",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 197450.09,
  },
  {
    id: "item-15",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 423504.76,
  },
  {
    id: "item-16",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 406055.7,
  },
  {
    id: "item-17",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 308008.82,
  },
  {
    id: "item-18",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 12274.05,
  },
  {
    id: "item-19",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 357890.9,
  },
  {
    id: "item-20",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 228343.49,
  },
  {
    id: "item-21",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 389437.8,
  },
  {
    id: "item-22",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 148939.97,
  },
  {
    id: "item-23",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 396784.74,
  },
  {
    id: "item-24",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 420527.19,
  },
  {
    id: "item-25",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 423261.15,
  },
  {
    id: "item-26",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 397850.69,
  },
  {
    id: "item-27",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 213659.96,
  },
  {
    id: "item-28",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 440808.08,
  },
  {
    id: "item-29",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 28770.14,
  },
  {
    id: "item-30",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 176305.85,
  },
  {
    id: "item-31",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 257814.77,
  },
  {
    id: "item-32",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 176802.62,
  },
  {
    id: "item-33",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 151387.35,
  },
  {
    id: "item-34",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 345032.03,
  },
  {
    id: "item-35",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 35905.04,
  },
  {
    id: "item-36",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 345433.93,
  },
  {
    id: "item-37",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 172690.56,
  },
  {
    id: "item-38",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 478676.51,
  },
  {
    id: "item-39",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 60633.8,
  },
  {
    id: "item-40",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 85963.69,
  },
  {
    id: "item-41",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 316664.94,
  },
  {
    id: "item-42",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 375655.31,
  },
  {
    id: "item-43",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 286614.2,
  },
  {
    id: "item-44",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 23681.88,
  },
  {
    id: "item-45",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 174435.3,
  },
  {
    id: "item-46",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 370629.03,
  },
  {
    id: "item-47",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 215702.26,
  },
  {
    id: "item-48",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 520425.12,
  },
  {
    id: "item-49",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 345477.52,
  },
  {
    id: "item-50",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 43876.4,
  },
  {
    id: "item-51",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 242177.09,
  },
  {
    id: "item-52",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 528449.94,
  },
  {
    id: "item-53",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 62974.5,
  },
  {
    id: "item-54",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 302994.04,
  },
  {
    id: "item-55",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 342996.88,
  },
  {
    id: "item-56",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 122729.3,
  },
  {
    id: "item-57",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 535860.92,
  },
  {
    id: "item-58",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 277140.19,
  },
  {
    id: "item-59",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 298202.29,
  },
  {
    id: "item-60",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 109199.19,
  },
  {
    id: "item-61",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 249262.41,
  },
  {
    id: "item-62",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 228197.48,
  },
  {
    id: "item-63",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 218447.43,
  },
  {
    id: "item-64",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 44525.6,
  },
  {
    id: "item-65",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 7398.53,
  },
  {
    id: "item-66",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 14046.28,
  },
  {
    id: "item-67",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 87867.12,
  },
  {
    id: "item-68",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 575873.64,
  },
  {
    id: "item-69",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 707393.79,
  },
  {
    id: "item-70",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 578384.91,
  },
  {
    id: "item-71",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 809.02,
  },
  {
    id: "item-72",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 626692.25,
  },
  {
    id: "item-73",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 13363.4,
  },
  {
    id: "item-74",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 364404.78,
  },
  {
    id: "item-75",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 309245.54,
  },
  {
    id: "item-76",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 27368.9,
  },
  {
    id: "item-77",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 440803.85,
  },
  {
    id: "item-78",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 873663.05,
  },
  {
    id: "item-79",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 504768.16,
  },
  {
    id: "item-80",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 382460.55,
  },
  {
    id: "item-81",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 795528.57,
  },
  {
    id: "item-82",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 591460.18,
  },
  {
    id: "item-83",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 697135.99,
  },
  {
    id: "item-84",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 283026.59,
  },
  {
    id: "item-85",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 177327.35,
  },
  {
    id: "item-86",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 940408.57,
  },
  {
    id: "item-87",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 939187.04,
  },
  {
    id: "item-88",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 183191.8,
  },
  {
    id: "item-89",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 1044731.61,
  },
  {
    id: "item-90",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 831277.69,
  },
  {
    id: "item-91",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 512994.81,
  },
  {
    id: "item-92",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 1162354.87,
  },
  {
    id: "item-93",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 900344.73,
  },
  {
    id: "item-94",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 565299.8,
  },
  {
    id: "item-95",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 768480.96,
  },
  {
    id: "item-96",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 1280650.05,
  },
  {
    id: "item-97",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 276580.39,
  },
  {
    id: "item-98",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 2105134.7,
  },
  {
    id: "item-99",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’énergie de source fossile",
    labelEN: "Fossil Energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 3451130.78,
  },
]
