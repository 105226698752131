import { DataPointDemo } from "@kiosk/front/api/dashboard/types"

export const Environment14: DataPointDemo[] = [
  {
    id: "item-0",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 0.29,
  },
  {
    id: "item-1",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 3.67,
  },
  {
    id: "item-2",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 7.54,
  },
  {
    id: "item-3",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 4.57,
  },
  {
    id: "item-4",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 7.64,
  },
  {
    id: "item-5",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 5.71,
  },
  {
    id: "item-6",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 7.37,
  },
  {
    id: "item-7",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 6.01,
  },
  {
    id: "item-8",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 1.04,
  },
  {
    id: "item-9",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 7.31,
  },
  {
    id: "item-10",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 4.92,
  },
  {
    id: "item-11",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 2.22,
  },
  {
    id: "item-12",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 6.43,
  },
  {
    id: "item-13",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 7.29,
  },
  {
    id: "item-14",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 7.18,
  },
  {
    id: "item-15",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 6.95,
  },
  {
    id: "item-16",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 4.18,
  },
  {
    id: "item-17",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 1.76,
  },
  {
    id: "item-18",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 1.82,
  },
  {
    id: "item-19",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 4.15,
  },
  {
    id: "item-20",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 4.67,
  },
  {
    id: "item-21",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 3.34,
  },
  {
    id: "item-22",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 6.31,
  },
  {
    id: "item-23",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 1.58,
  },
  {
    id: "item-24",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 3.05,
  },
  {
    id: "item-25",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 5.14,
  },
  {
    id: "item-26",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 0.3,
  },
  {
    id: "item-27",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 0.27,
  },
  {
    id: "item-28",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 0.06,
  },
  {
    id: "item-29",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 2.08,
  },
  {
    id: "item-30",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 5.66,
  },
  {
    id: "item-31",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 5.12,
  },
  {
    id: "item-32",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 0.41,
  },
  {
    id: "item-33",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 6.04,
  },
  {
    id: "item-34",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 5.64,
  },
  {
    id: "item-35",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 9.51,
  },
  {
    id: "item-36",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 4,
  },
  {
    id: "item-37",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 2.05,
  },
  {
    id: "item-38",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 8.85,
  },
  {
    id: "item-39",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 5.3,
  },
  {
    id: "item-40",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 2.88,
  },
  {
    id: "item-41",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 1.55,
  },
  {
    id: "item-42",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 0.42,
  },
  {
    id: "item-43",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 7.27,
  },
  {
    id: "item-44",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 6.89,
  },
  {
    id: "item-45",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 2.02,
  },
  {
    id: "item-46",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 1.6,
  },
  {
    id: "item-47",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 7.12,
  },
  {
    id: "item-48",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 5.72,
  },
  {
    id: "item-49",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 8.77,
  },
  {
    id: "item-50",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 9.37,
  },
  {
    id: "item-51",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 2.06,
  },
  {
    id: "item-52",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 5.19,
  },
  {
    id: "item-53",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 5.29,
  },
  {
    id: "item-54",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 1.9,
  },
  {
    id: "item-55",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 5.51,
  },
  {
    id: "item-56",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 2.88,
  },
  {
    id: "item-57",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 9.8,
  },
  {
    id: "item-58",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 9.99,
  },
  {
    id: "item-59",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 12.44,
  },
  {
    id: "item-60",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 9.75,
  },
  {
    id: "item-61",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 5.26,
  },
  {
    id: "item-62",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 10.1,
  },
  {
    id: "item-63",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 10.3,
  },
  {
    id: "item-64",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 9.58,
  },
  {
    id: "item-65",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 6.05,
  },
  {
    id: "item-66",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 4.41,
  },
  {
    id: "item-67",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 3.96,
  },
  {
    id: "item-68",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 5,
  },
  {
    id: "item-69",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 9.36,
  },
  {
    id: "item-70",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 14.85,
  },
  {
    id: "item-71",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 14.3,
  },
  {
    id: "item-72",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 13.24,
  },
  {
    id: "item-73",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 0.48,
  },
  {
    id: "item-74",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 1.46,
  },
  {
    id: "item-75",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 2.43,
  },
  {
    id: "item-76",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 12.71,
  },
  {
    id: "item-77",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 5.49,
  },
  {
    id: "item-78",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 3.6,
  },
  {
    id: "item-79",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 2.85,
  },
  {
    id: "item-80",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 11.28,
  },
  {
    id: "item-81",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 3.27,
  },
  {
    id: "item-82",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 9.02,
  },
  {
    id: "item-83",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 15.88,
  },
  {
    id: "item-84",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 8.67,
  },
  {
    id: "item-85",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 21.05,
  },
  {
    id: "item-86",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 9.28,
  },
  {
    id: "item-87",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 17.69,
  },
  {
    id: "item-88",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 11.1,
  },
  {
    id: "item-89",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 21.18,
  },
  {
    id: "item-90",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 16.53,
  },
  {
    id: "item-91",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 6.74,
  },
  {
    id: "item-92",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 23.82,
  },
  {
    id: "item-93",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 12.41,
  },
  {
    id: "item-94",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 2.45,
  },
  {
    id: "item-95",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 35.31,
  },
  {
    id: "item-96",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 12.11,
  },
  {
    id: "item-97",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 17.55,
  },
  {
    id: "item-98",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 46.79,
  },
  {
    id: "item-99",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantités de déchets dangereux",
    labelEN: "Quantity of hazardous waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 75.59,
  },
]
