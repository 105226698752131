import { Group, Stack, Text } from "@mantine/core"

import { SingleValueDataPointDemo } from "@kiosk/front/api/dashboard/types"
import { formatNumberDemo } from "@kiosk/front/utils/format"

type Props = Pick<SingleValueDataPointDemo, "info" | "unit" | "value"> & {
  label: string
}

export default function DataPointsSingleDemo({
  label,
  unit,
  info,
  value,
}: Readonly<Props>) {
  return (
    <Stack gap={12}>
      <Text mt="auto" c="gray.5" fw={500} fz="lg">
        {label}
      </Text>

      <Group align="baseline" gap={8}>
        <Text lh="normal" fw={600} fz="30px">
          {value ? formatNumberDemo(value) : "0"}
        </Text>

        <Text c="gray.5" fw={500} fz="xl">
          {unit}
        </Text>
      </Group>

      <Text c="gray.5" fw={400} fz="md">
        {info}
      </Text>
    </Stack>
  )
}
