import { List, Text } from "@mantine/core"
import { useTranslation } from "react-i18next"

import { DataPoint } from "@kiosk/types/data-points"

import { isDefined } from "@kiosk/shared/utils/helpers"

interface Props {
  dataPoints: DataPoint[]
}

export function DSNSummary(props: Props) {
  const { t } = useTranslation("sources")

  const { dataPoints } = props

  const femaleHeadcount = dataPoints.find(
    (dp) => dp.indicator === "headcount" && dp.dimensions?.gender === "female",
  )?.value
  const maleHeadcount = dataPoints.find(
    (dp) => dp.indicator === "headcount" && dp.dimensions?.gender === "male",
  )?.value
  const totalHeadcount =
    femaleHeadcount && maleHeadcount
      ? femaleHeadcount + maleHeadcount
      : undefined
  const genderPayGap = dataPoints.find(
    (dp) => dp.indicator === "gender_pay_gap",
  )?.value

  return (
    <>
      <Text size="xl">{t("dsnImport.summary")}</Text>
      <List>
        {isDefined(femaleHeadcount) && isDefined(maleHeadcount) && (
          <List.Item>
            {t("dsnImport.headcount", {
              total: totalHeadcount,
              female: femaleHeadcount,
              male: maleHeadcount,
            })}{" "}
          </List.Item>
        )}
        {isDefined(genderPayGap) && (
          <List.Item>
            {t("dsnImport.genderPayGap", { value: genderPayGap.toFixed(1) })}{" "}
          </List.Item>
        )}
      </List>
    </>
  )
}
