import { Group, Text, ThemeIcon } from "@mantine/core"

import { TablerIconComponent } from "@kiosk/front/utils/icon"

type Props = {
  backgroundColor: string
  icon: TablerIconComponent
  iconBackgroundColor: string
  name: string
}

export const CategoryTitleDemo = ({
  backgroundColor,
  icon: Icon,
  iconBackgroundColor,
  name,
}: Props) => {
  return (
    <Group bg={backgroundColor} gap={16} px={24} py={16}>
      <ThemeIcon
        bg={iconBackgroundColor}
        color="red"
        style={{ width: "40px", height: "40px" }}
        variant="light"
      >
        {Icon && (
          <Icon color="white" style={{ width: "24px", height: "24px" }} />
        )}
      </ThemeIcon>

      <Text fw={600} fz="xxl">
        {name}
      </Text>
    </Group>
  )
}
