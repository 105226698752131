import { Flex, Stack, Skeleton, Text as MantineText, Box } from "@mantine/core"
//@ts-ignore there is no type definition for this package
import HTMLRenderer from "react-html-renderer"
//@ts-ignore there is no type definition for this package
import XMLViewer from "react-xml-viewer"

import { DisclosureRequirementParagraph } from "@kiosk/types/prisma-client"

import { PreviewFooter } from "./PreviewFooter"

const Text = ({ children }: { children: JSX.Element }) => {
  return (
    <MantineText
      span
      fz="lg"
      c="gray.6"
      style={{
        textAlign: "justify",
      }}
    >
      {children}
    </MantineText>
  )
}

const Empty = ({ children }: { children: JSX.Element }) => {
  return <>{children}</>
}

const DisclosureRequirementPreviewSkeleton = () => {
  return (
    <Stack my={15} mx={90} h="50vh" justify="center">
      <Skeleton height={15} radius="xs" />
      <Skeleton height={15} mt={1} radius="xs" />
      <Skeleton height={15} mt={1} radius="xs" />
      <Skeleton height={15} mt={1} radius="xs" />
      <Skeleton height={15} mt={1} radius="xs" />
      <Skeleton height={15} mt={1} radius="xs" />
      <Skeleton height={15} mt={1} radius="xs" />
      <Skeleton height={15} mt={1} radius="xs" />
      <Skeleton height={15} mt={1} radius="xs" />
      <Skeleton height={15} mt={1} radius="xs" />
      <Skeleton height={15} mt={1} radius="xs" />
      <Skeleton height={15} mt={1} width="70%" radius="xs" />
    </Stack>
  )
}
interface DisclosureRequirementPreview {
  xmlMode: boolean
  loading: boolean
  previewParagraph: DisclosureRequirementParagraph
  onGenerate: () => void
}
export const DisclosureRequirementPreview = (
  props: DisclosureRequirementPreview,
) => {
  return (
    <>
      {props.loading ? (
        <DisclosureRequirementPreviewSkeleton />
      ) : (
        <Flex direction="row" wrap="wrap" mb="xl">
          {props.xmlMode ? (
            <Stack w="48vw">
              <XMLViewer xml={props.previewParagraph?.ixbrlParagraph} />
            </Stack>
          ) : (
            <Box style={{ textAlign: "justify" }} my="xl" mx={90}>
              <HTMLRenderer
                html={props.previewParagraph?.ixbrlParagraph}
                components={{
                  span: Text,
                  "ix:nonfraction": Empty,
                  "ix:nonnumeric": Empty,
                }}
              />
            </Box>
          )}
        </Flex>
      )}
      <PreviewFooter
        isPendingGeneration={Boolean(props.loading)}
        onGenerate={props.onGenerate}
      />
    </>
  )
}
