import { Badge, Group, rem, Stack, Text, ThemeIcon } from "@mantine/core"
import { IconChevronRight } from "@tabler/icons-react"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"

import { ReportsResponses } from "@kiosk/types/endpoints/reports"

import { topicSorter } from "@kiosk/shared/utils/topics/topicSorter"

import {
  useMaterialityMutation,
  useMaterialityQuery,
} from "@kiosk/front/api/reports"
import { Completion } from "@kiosk/front/components/Completion"
import { MaterialitySwitch } from "@kiosk/front/components/csrd/MaterialSwitch"
import { routes } from "@kiosk/front/utils/constants/index"

type Topic = ReportsResponses.GetCategories[number]["topics"][number]

type Props = {
  topic: Topic
}

export const CSRDSubTopicsList = ({ topic }: Props) => {
  const navigate = useNavigate()

  const materialityQuery = useMaterialityQuery()

  const nonMaterialEsrs = useMemo(
    () => materialityQuery.data ?? [],
    [materialityQuery.data],
  )
  const disclosureRequirements = topic.disclosureRequirements.sort(topicSorter)

  const hasMaterialitySwitch = topic.code !== "ESRS 2"

  const [isMaterial, setIsMaterial] = useState(
    !nonMaterialEsrs.includes(topic.id),
  )
  useEffect(
    () => setIsMaterial(!nonMaterialEsrs.includes(topic.id)),
    [nonMaterialEsrs, materialityQuery.data, topic.id],
  )

  const {
    mutateAsync: switchMateriality,
    isPending: switchMaterialityIsPending,
  } = useMaterialityMutation()

  const handleChangeSubTopic = (subTopicId: string) => {
    setIsMaterial(nonMaterialEsrs.includes(topic.id))
    navigate(
      routes.CSRD_DISCLOSURE_REQUIREMENT.path
        .replace(":datoTopicId", topic.id)
        .replace(":datoSubTopicId", subTopicId),
    )
  }

  const completeRequirements = topic.disclosureRequirements.filter(
    (dr) => dr.progress === 100,
  )
  const topicProgress =
    (100 * completeRequirements.length) / topic.disclosureRequirements.length

  return (
    <Stack p={24} gap={16}>
      <Group justify="space-between">
        <Group gap={16}>
          <Group gap={8}>
            <Text fz="sm" c="gray.9">
              {completeRequirements.length}/
              {topic.disclosureRequirements.length}
            </Text>
            <Completion progress={topicProgress} />
          </Group>
          <Group gap={8}>
            <Badge
              h={26}
              fz="xxs"
              w={63}
              px={4}
              color="gray.1"
              c="gray.7"
              fw={400}
              fs="italic"
            >
              {topic.code}
            </Badge>
            <Text fz="md" c="gray.9" fw={600}>
              {topic.name}
            </Text>
          </Group>
        </Group>
        {hasMaterialitySwitch ? (
          <MaterialitySwitch
            value={isMaterial}
            isPending={switchMaterialityIsPending}
            onClick={() => {
              setIsMaterial(!isMaterial)
              switchMateriality(topic.id)
            }}
          />
        ) : null}
      </Group>
      <Stack gap={0}>
        {disclosureRequirements.map(({ id, name, esrsCode, progress }) => (
          <Group
            p={16}
            key={id}
            gap={8}
            style={{
              cursor: "pointer",
              borderBottomStyle: "solid",
              borderBottomWidth: rem(1),
              borderBottomColor: "var(--mantine-color-gray-2)",
            }}
            onClick={() => handleChangeSubTopic(id)}
          >
            <Completion progress={progress} />
            <Badge
              h={26}
              fz="xxs"
              px={11}
              style={{
                borderColor: "var(--mantine-color-gray-2)",
                borderWidth: rem(1),
              }}
              color="white"
              c="gray.5"
              fw={400}
              fs="italic"
            >
              {esrsCode}
            </Badge>
            <Text flex={1} fz="sm" fw={500} c="gray.7">
              {name}
            </Text>
            <ThemeIcon c="gray.7" w={22} h={22}>
              <IconChevronRight />
            </ThemeIcon>
          </Group>
        ))}
      </Stack>
    </Stack>
  )
}
