import {
  Box,
  Button,
  Group,
  Menu,
  Progress,
  rem,
  Stack,
  Text,
} from "@mantine/core"
import { IconChevronDown, IconDownload } from "@tabler/icons-react"
import { useState } from "react"
import { createPortal } from "react-dom"
import { useTranslation } from "react-i18next"
import Swal from "sweetalert2"

import { useGenerateXBRLMutation } from "@kiosk/front/api/reports"
import { config } from "@kiosk/front/config"

interface ExportMenuProps {
  isDemo: boolean
}

const useSuccessModal = () => {
  const { t } = useTranslation("csrd")
  const [show, setShow] = useState(false)

  const showSwal = () => {
    Swal.fire({
      icon: "success",
      showCloseButton: true,
      showConfirmButton: false,
      didOpen: () => setShow(true),
      didClose: () => setShow(false),
    })
  }

  const SwalLoader = () => (
    <>
      {show &&
        createPortal(
          <Stack my={40}>
            <Text size="xl">{t("actions.exportXhtmlSucceed")}</Text>
            <Box>
              <Button onClick={() => Swal.close()}>{t("actions.close")}</Button>
            </Box>
          </Stack>,
          Swal.getHtmlContainer()!,
        )}
    </>
  )

  return { showSwal, SwalLoader }
}

export function ExportMenu({ isDemo }: ExportMenuProps) {
  const { t } = useTranslation(["common", "csrd"])
  const { showSwal, SwalLoader } = useSuccessModal()

  const { mutate: generateXBRL, isPending } = useGenerateXBRLMutation()

  const handleExportClick = async () => {
    generateXBRL()
    showSwal()
  }

  return (
    <Menu>
      <Menu.Target>
        <Button rightSection={<IconChevronDown size={20} />}>
          {t("csrd:actions.export")}
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item disabled={!isDemo} leftSection={<IconDownload size={20} />}>
          {t("csrd:actions.exportDoc")}
        </Menu.Item>
        <Menu.Item disabled={!isDemo} leftSection={<IconDownload size={20} />}>
          {t("csrd:actions.exportPdf")}
        </Menu.Item>
        <Menu.Item
          leftSection={<IconDownload size={20} />}
          onClick={handleExportClick}
          disabled={isPending}
        >
          {t("csrd:actions.exportXhtml")}
        </Menu.Item>
      </Menu.Dropdown>

      <SwalLoader />
    </Menu>
  )
}

export const CSRDHeader = ({ progress }: { progress: number }) => {
  const { t } = useTranslation("csrd")

  return (
    <Group
      px={24}
      py={16}
      justify="space-between"
      style={{
        borderBottomColor: "var(--mantine-color-gray-2)",
        borderBottomWidth: rem(1),
        borderBottomStyle: "solid",
      }}
    >
      <Stack
        w={360}
        px={24}
        py={16}
        style={{
          borderStyle: "solid",
          borderWidth: rem(1),
          borderColor: "var(--mantine-color-gray-2)",
          borderRadius: rem(6),
        }}
      >
        <Group justify="space-between">
          <Group gap={8}>
            <Text fz="sm" fw={500} c="gray.6">
              {t("overallCompletion")}
            </Text>
            <Text c="gray.9" fz="md" fw={600}>
              {progress}%
            </Text>
          </Group>
        </Group>
        <Progress value={progress} />
      </Stack>
      <Stack gap={8}>
        <ExportMenu isDemo={config.isDemo} />
      </Stack>
    </Group>
  )
}
