import { Box, Stack } from "@mantine/core"
import { useTranslation } from "react-i18next"

import { DashboardCategoryDemo } from "@kiosk/front/api/dashboard/types"
import { DASHBOARD_STYLE } from "@kiosk/front/constants/dashboard/style"

import { CategoryTitleDemo } from "./CategoryTitleDemo"
import { TopicDashboardDemo } from "./TopicDashboardDemo"

export type DashboardStyleKey = keyof typeof DASHBOARD_STYLE

type Props = Pick<
  DashboardCategoryDemo,
  "name" | "nameEN" | "nameFR" | "topics"
> & {
  name: DashboardStyleKey
}

export const CategoryDashboardDemo = ({
  name,
  nameFR,
  nameEN,
  topics,
}: Props) => {
  const { i18n } = useTranslation(["dashboard"])
  const currentLanguage = i18n.language

  return (
    <Box
      bd={`1px solid ${DASHBOARD_STYLE[name]?.borderColor}`}
      style={{
        borderRadius: 6,
        overflow: "hidden",
      }}
    >
      <CategoryTitleDemo
        backgroundColor={DASHBOARD_STYLE[name]?.color}
        icon={DASHBOARD_STYLE[name]?.icon}
        iconBackgroundColor={DASHBOARD_STYLE[name]?.iconBackgroundColor}
        name={currentLanguage === "fr" ? nameFR : nameEN}
      />

      <Stack gap={40}>
        {topics.map((topic, index) => (
          <TopicDashboardDemo
            index={index}
            color={DASHBOARD_STYLE[name]?.borderColor}
            key={topic.id}
            topic={topic}
          />
        ))}
      </Stack>
    </Box>
  )
}
