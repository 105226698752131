import {
  IconBolt,
  IconBuildingFactory2,
  IconMoodSick,
  IconRecycle,
  IconScale,
  IconSchool,
} from "@tabler/icons-react"

import { DashboardCategoryDemo } from "@kiosk/front/api/dashboard/types"

import { Environment1 } from "./environment1"
import { Environment11 } from "./environment11"
import { Environment12 } from "./environment12"
import { Environment13 } from "./environment13"
import { Environment14 } from "./environment14"
import { Environment2 } from "./environment2"
import { Environment3 } from "./environment3"
import { Environment4 } from "./environment4"
import { Environment6 } from "./environment6"
import { Environment7 } from "./environment7"
import { Environment8 } from "./environment8"
import { Environment9 } from "./environment9"
import { Governance1 } from "./governance1"
import { Governance2 } from "./governance2"
import { Governance3 } from "./governance3"
import { Governance4 } from "./governance4"
import { Governance5 } from "./governance5"
import { Governance6 } from "./governance6"
import { Social1 } from "./social1"
import { Social11 } from "./social11"
import { Social12 } from "./social12"
import { Social13 } from "./social13"
import { Social14 } from "./social14"
import { Social2 } from "./social2"
import { Social3 } from "./social3"
import { Social4 } from "./social4"
import { Social6 } from "./social6"
import { Social7 } from "./social7"
import { Social8 } from "./social8"
import { Social9 } from "./social9"

// TODO : Ne pas mettre les couleurs dans la BD
const ENVIRONMENT: DashboardCategoryDemo[] = [
  {
    id: "clm17ghq80000mbelch9k7w7e",
    name: "environment",
    nameFR: "Environnement",
    nameEN: "Environment",
    order: 1,
    topics: [
      {
        id: "clm17ghq80001mbelqcqk9x8g",
        icon: IconBuildingFactory2,
        iconColor: "emerald.5",
        iconBackgroundColor: "emerald.0",
        nameFR: "Changement climatique",
        nameEN: "Climate Change",
        dataPoints: [
          ...Environment1,
          ...Environment2,
          ...Environment3,
          ...Environment4,
          {
            id: "clm17ghq80006mbellzj3ubc6",
            dimensionId: 3,
            isMandatoryInDashboard: true,
            labelFR: "Emissions de gaz à effet de serre par scope",
            labelEN: "Greenhouse gas emissions per scope",
            numberType: "decimal",
            type: "dimensioned",
            chart: "donut",
            time: 2024,
            unit: "tCO2e",
            values: [
              {
                value: 1.5,
                baseColor: "emerald.7",
                dimensions: [
                  {
                    dimensionId: "clm17ghq80007mbelg2j4r9h9",
                    dimensionLabel: "Scope 1",
                    dimensionType: "explicit",
                    optionId: "clm17ghq80008mbelj5qfr2gf",
                    optionLabel: "Agriculture",
                  },
                ],
              },
              {
                value: 4,
                baseColor: "emerald.6",
                dimensions: [
                  {
                    dimensionId: "clm17ghq80009mbelzk2lrvj3",
                    dimensionLabel: "Scope 2",
                    dimensionType: "explicit",
                    optionId: "clm17ghq8000ambeljvl7xsm5",
                    optionLabel: "Industry",
                  },
                ],
              },
              {
                value: 94.5,
                baseColor: "emerald.5",
                dimensions: [
                  {
                    dimensionId: "clm17ghq8000bmbelkrxf2hv9",
                    dimensionLabel: "Scope 3",
                    dimensionType: "explicit",
                    optionId: "clm17ghq8000cmbelck0ddw5h",
                    optionLabel: "Services",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "clm17ghq8000dmbelcnf77tc7",
        icon: IconBolt,
        iconColor: "emerald.5",
        iconBackgroundColor: "emerald.0",
        nameFR: "Consommation d’énergie",
        nameEN: "Energy consumption",
        dataPoints: [
          ...Environment6,
          ...Environment7,
          ...Environment8,
          ...Environment9,
          {
            id: "clm17ghq8000imbelolzszpg4",
            baseColor: "emerald.4",
            dimensionId: 2,
            isMandatoryInDashboard: true,
            labelFR: "Consommation totale d’énergie (kWh)",
            labelEN: "Total energy consumption (kWh)",
            numberType: "decimal",
            type: "dimensioned",
            chart: "bar",
            time: 2024,
            unit: "tCO2e",
            values: [
              {
                value: 91000,
                baseColor: "emerald.4",
                dimensions: [
                  {
                    dimensionId: "clm17ghq8000jmbelb1vj5mw1",
                    dimensionLabel: "T1",
                    dimensionType: "explicit",
                    optionId: "clm17ghq8000kmbel7qkqklf5",
                    optionLabel: "Agriculture",
                  },
                ],
              },
              {
                value: 180000,
                baseColor: "emerald.4",
                dimensions: [
                  {
                    dimensionId: "clm17ghq8000lmbel1s3tcxn6",
                    dimensionLabel: "T2",
                    dimensionType: "explicit",
                    optionId: "clm17ghq8000mmbel3z3hb2r4",
                    optionLabel: "Industry",
                  },
                ],
              },
              {
                value: 120000,
                baseColor: "emerald.4",
                dimensions: [
                  {
                    dimensionId: "clm17ghq8000nmbeljz7jhnh6",
                    dimensionLabel: "T3",
                    dimensionType: "explicit",
                    optionId: "clm17ghq8000ombelb8hdq7x8",
                    optionLabel: "Services",
                  },
                ],
              },
              {
                value: 120000,
                baseColor: "emerald.4",
                dimensions: [
                  {
                    dimensionId: "clm17ghq8000pmbelcl6ywsq4",
                    dimensionLabel: "T4",
                    dimensionType: "explicit",
                    optionId: "clm17ghq8000qmbelccddzh8",
                    optionLabel: "Services",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "clm17ghq8000rmbelzybh17z5",
        icon: IconRecycle,
        iconColor: "emerald.5",
        iconBackgroundColor: "emerald.0",
        nameFR: "Gestion de l’eau et des déchets",
        nameEN: "Water and waste management",
        dataPoints: [
          ...Environment11,
          ...Environment12,
          ...Environment13,
          ...Environment14,
          {
            id: "clm17ghq8000wmbelpbm9zsa4",
            baseColor: "emerald.4",
            chart: "line",
            dimensionId: 4,
            isMandatoryInDashboard: true,
            labelFR: "Pourcentage des déchets non-recyclés",
            labelEN: "Percentage of non-recycled waste",
            numberType: "decimal",
            time: 2024,
            type: "dimensioned",
            unit: "%",
            values: [
              {
                value: 50,
                baseColor: "emerald.7",
                dimensions: [
                  {
                    dimensionId: "clm17ghq8000xmbelvkmffmj1",
                    dimensionLabel: "T1",
                    dimensionType: "explicit",
                    optionId: "clm17ghq8000ymbelalrhv5t8",
                    optionLabel: "Agriculture",
                  },
                ],
              },
              {
                value: 75,
                baseColor: "emerald.6",
                dimensions: [
                  {
                    dimensionId: "clm17ghq8000zmbelmkxlb28p",
                    dimensionLabel: "T2",
                    dimensionType: "explicit",
                    optionId: "clm17ghq80010mbelkjmb7r7e",
                    optionLabel: "Industry",
                  },
                ],
              },
              {
                value: 50,
                baseColor: "emerald.5",
                dimensions: [
                  {
                    dimensionId: "clm17ghq80011mbel9cfkldc3",
                    dimensionLabel: "T3",
                    dimensionType: "explicit",
                    optionId: "clm17ghq80012mbelxxpy0qk6",
                    optionLabel: "Services",
                  },
                ],
              },
              {
                value: 25,
                baseColor: "emerald.5",
                dimensions: [
                  {
                    dimensionId: "clm17ghq80013mbelxmdjktv8",
                    dimensionLabel: "T4",
                    dimensionType: "explicit",
                    optionId: "clm17ghq80014mbel1rr7hz6t",
                    optionLabel: "Services",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]

const SOCIAL: DashboardCategoryDemo[] = [
  {
    id: "clm17h7zg0000mbel4mdeq70b",
    name: "social",
    nameFR: "Social",
    nameEN: "Social",
    order: 2,
    topics: [
      {
        id: "clm17h7zg0001mbel82r2p4sd",
        icon: IconScale,
        iconColor: "yellow.5",
        iconBackgroundColor: "yellow.0",
        nameFR: "Collaborateurs",
        nameEN: "Employees",
        dataPoints: [
          ...Social1,
          ...Social2,
          ...Social3,
          ...Social4,
          {
            baseColor: "yellow.4",
            chart: "line",
            dimensionId: 3,
            id: "clm17h7zg0006mbel44cnvjz2",
            isMandatoryInDashboard: true,
            labelFR: "Nombre total d’employés",
            labelEN: "Total number of employees",
            numberType: "decimal",
            time: 2023,
            type: "dimensioned",
            unit: "",
            values: [
              {
                value: 1500,
                baseColor: "yellow.7",
                dimensions: [
                  {
                    dimensionId: "clm17h7zg0007mbelwhas8bz7",
                    dimensionLabel: "T1",
                    dimensionType: "explicit",
                    optionId: "clm17h7zg0008mbel0cujb42q",
                    optionLabel: "Agriculture",
                  },
                ],
              },
              {
                value: 2000,
                baseColor: "yellow.6",
                dimensions: [
                  {
                    dimensionId: "clm17h7zg0009mbelhi3hg73r",
                    dimensionLabel: "T2",
                    dimensionType: "explicit",
                    optionId: "clm17h7zg000ambel4xv29a9u",
                    optionLabel: "Industry",
                  },
                ],
              },
              {
                value: 2200,
                baseColor: "yellow.5",
                dimensions: [
                  {
                    dimensionId: "clm17h7zg000bmbeld2v3khg4",
                    dimensionLabel: "T3",
                    dimensionType: "explicit",
                    optionId: "clm17h7zg000cmbel4d39sft3",
                    optionLabel: "Services",
                  },
                ],
              },
              {
                value: 1900,
                baseColor: "yellow.5",
                dimensions: [
                  {
                    dimensionId: "clm17h7zg000dmbelj0wfl1b4",
                    dimensionLabel: "T4",
                    dimensionType: "explicit",
                    optionId: "clm17h7zg000embel7mtjcs6v",
                    optionLabel: "Services",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "clm17h7zg000fmbel5pxr8tsf",
        icon: IconSchool,
        iconColor: "yellow.5",
        iconBackgroundColor: "yellow.0",
        nameFR: "Écart de formation et de paie",
        nameEN: "Training and pay gap",
        dataPoints: [
          ...Social6,
          ...Social7,
          ...Social8,
          ...Social9,
          {
            id: "clm17h7zg000kmbelv5yjr1ur",
            baseColor: "yellow.4",
            chart: "line",
            dimensionId: 1,
            isMandatoryInDashboard: true,
            labelFR: "Écart salarial entre hommes et femmes",
            labelEN: "Gender pay gap",
            numberType: "decimal",
            time: 2022,
            type: "dimensioned",
            unit: "%",
            values: [
              {
                value: 50,
                baseColor: "yellow.7",
                dimensions: [
                  {
                    dimensionId: "clm17h7zg000lmbelv8bs7d0d",
                    dimensionLabel: "T1",
                    dimensionType: "explicit",
                    optionId: "clm17h7zg000mmbel5kzbl3uf",
                    optionLabel: "Agriculture",
                  },
                ],
              },
              {
                value: 25,
                baseColor: "yellow.6",
                dimensions: [
                  {
                    dimensionId: "clm17h7zg000nmbelhsie5h14",
                    dimensionLabel: "T2",
                    dimensionType: "explicit",
                    optionId: "clm17h7zg000ombel3le3jvn6",
                    optionLabel: "Industry",
                  },
                ],
              },
              {
                value: 85,
                baseColor: "yellow.5",
                dimensions: [
                  {
                    dimensionId: "clm17h7zg000pmbel9lwxyx8o",
                    dimensionLabel: "T3",
                    dimensionType: "explicit",
                    optionId: "clm17h7zg000qmbel9bck1fmf",
                    optionLabel: "Services",
                  },
                ],
              },
              {
                value: 25,
                baseColor: "yellow.5",
                dimensions: [
                  {
                    dimensionId: "clm17h7zg000rmbel7c0j73dh",
                    dimensionLabel: "T4",
                    dimensionType: "explicit",
                    optionId: "clm17h7zg000smbelb5yy24be",
                    optionLabel: "Services",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "clm17h7zg000tmbelgfut9ldn",
        icon: IconMoodSick,
        iconColor: "yellow.5",
        iconBackgroundColor: "yellow.0",
        nameFR: "Accidents",
        nameEN: "Incidents",
        dataPoints: [
          ...Social11,
          ...Social12,
          ...Social13,
          ...Social14,
          {
            id: "clm17h7zg000ymbel5gsxr3yo",
            baseColor: "yellow.4",
            chart: "bar",
            dimensionId: 4,
            isMandatoryInDashboard: true,
            labelFR: "Incidents liés au travail",
            labelEN: "Work-related incidents",
            numberType: "decimal",
            time: 2022,
            type: "dimensioned",
            unit: "",
            values: [
              {
                value: 91,
                baseColor: "yellow.4",
                dimensions: [
                  {
                    dimensionId: "clm17h7zg000zmbelkz7p2mzp",
                    dimensionLabel: "T1",
                    dimensionType: "explicit",
                    optionId: "clm17h7zg0010mbel7gpnp3n6",
                    optionLabel: "Agriculture",
                  },
                ],
              },
              {
                value: 180,
                baseColor: "yellow.4",
                dimensions: [
                  {
                    dimensionId: "clm17h7zg0011mbelg4efpnx8",
                    dimensionLabel: "T2",
                    dimensionType: "explicit",
                    optionId: "clm17h7zg0012mbelppp1zpd1",
                    optionLabel: "Industry",
                  },
                ],
              },
              {
                value: 120,
                baseColor: "yellow.4",
                dimensions: [
                  {
                    dimensionId: "clm17h7zg0013mbel3oc48bz8",
                    dimensionLabel: "T3",
                    dimensionType: "explicit",
                    optionId: "clm17h7zg0014mbelcg19s1ws",
                    optionLabel: "Services",
                  },
                ],
              },
              {
                value: 120,
                baseColor: "yellow.4",
                dimensions: [
                  {
                    dimensionId: "clm17h7zg0015mbel7j4q5mxq",
                    dimensionLabel: "T4",
                    dimensionType: "explicit",
                    optionId: "clm17h7zg0016mbelq2h1s25b",
                    optionLabel: "Services",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]

const GOVERNANCE: DashboardCategoryDemo[] = [
  {
    id: "clm17ihug0000mbel2nv2as56",
    name: "governance",
    nameFR: "Gouvernance",
    nameEN: "Governance",
    order: 3,
    topics: [
      {
        id: "clm17ihug0001mbelak3r8vtx",
        grid: 3,
        dataPoints: [
          ...Governance1,
          ...Governance2,
          ...Governance3,
          ...Governance4,
          ...Governance5,
          ...Governance6,
        ],
      },
    ],
  },
]

export const FAKE_DASHBOARD_DATA: DashboardCategoryDemo[] = [
  ...ENVIRONMENT,
  ...SOCIAL,
  ...GOVERNANCE,
]
