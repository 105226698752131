import { DataPointDemo } from "@kiosk/front/api/dashboard/types"

export const Environment11: DataPointDemo[] = [
  {
    id: "item-0",
    dimensionId: 3,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 1.61,
  },
  {
    id: "item-1",
    dimensionId: 2,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 179.38,
  },
  {
    id: "item-2",
    dimensionId: 7,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 24.94,
  },
  {
    id: "item-3",
    dimensionId: 6,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 161.2,
  },
  {
    id: "item-4",
    dimensionId: 4,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 72.95,
  },
  {
    id: "item-5",
    dimensionId: 5,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 12.24,
  },
  {
    id: "item-6",
    dimensionId: 7,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 28.61,
  },
  {
    id: "item-7",
    dimensionId: 6,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 52.83,
  },
  {
    id: "item-8",
    dimensionId: 6,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 141.52,
  },
  {
    id: "item-9",
    dimensionId: 9,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 186.79,
  },
  {
    id: "item-10",
    dimensionId: 7,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 90.1,
  },
  {
    id: "item-11",
    dimensionId: 2,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 44.16,
  },
  {
    id: "item-12",
    dimensionId: 6,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 24.91,
  },
  {
    id: "item-13",
    dimensionId: 9,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 232.3,
  },
  {
    id: "item-14",
    dimensionId: 5,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 58.87,
  },
  {
    id: "item-15",
    dimensionId: 1,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 13.22,
  },
  {
    id: "item-16",
    dimensionId: 7,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 203.67,
  },
  {
    id: "item-17",
    dimensionId: 7,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 6.58,
  },
  {
    id: "item-18",
    dimensionId: 4,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 26.15,
  },
  {
    id: "item-19",
    dimensionId: 1,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 11.07,
  },
  {
    id: "item-20",
    dimensionId: 4,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 238.59,
  },
  {
    id: "item-21",
    dimensionId: 2,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 223.96,
  },
  {
    id: "item-22",
    dimensionId: 6,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 109.89,
  },
  {
    id: "item-23",
    dimensionId: 3,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 64.33,
  },
  {
    id: "item-24",
    dimensionId: 9,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 231.32,
  },
  {
    id: "item-25",
    dimensionId: 9,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 236.04,
  },
  {
    id: "item-26",
    dimensionId: 9,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 202.78,
  },
  {
    id: "item-27",
    dimensionId: 4,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 242.41,
  },
  {
    id: "item-28",
    dimensionId: 2,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 135.84,
  },
  {
    id: "item-29",
    dimensionId: 1,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 231.42,
  },
  {
    id: "item-30",
    dimensionId: 6,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 112.92,
  },
  {
    id: "item-31",
    dimensionId: 8,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 187.19,
  },
  {
    id: "item-32",
    dimensionId: 6,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 46.13,
  },
  {
    id: "item-33",
    dimensionId: 1,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 192.43,
  },
  {
    id: "item-34",
    dimensionId: 2,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 249.63,
  },
  {
    id: "item-35",
    dimensionId: 9,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 218.42,
  },
  {
    id: "item-36",
    dimensionId: 2,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 301.93,
  },
  {
    id: "item-37",
    dimensionId: 6,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 171.44,
  },
  {
    id: "item-38",
    dimensionId: 8,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 74.94,
  },
  {
    id: "item-39",
    dimensionId: 5,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 112.33,
  },
  {
    id: "item-40",
    dimensionId: 9,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 317.76,
  },
  {
    id: "item-41",
    dimensionId: 5,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 154.21,
  },
  {
    id: "item-42",
    dimensionId: 5,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 120.6,
  },
  {
    id: "item-43",
    dimensionId: 4,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 117.03,
  },
  {
    id: "item-44",
    dimensionId: 9,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 214.14,
  },
  {
    id: "item-45",
    dimensionId: 4,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 315.37,
  },
  {
    id: "item-46",
    dimensionId: 3,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 228,
  },
  {
    id: "item-47",
    dimensionId: 2,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 288.02,
  },
  {
    id: "item-48",
    dimensionId: 1,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 265.88,
  },
  {
    id: "item-49",
    dimensionId: 6,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 131.55,
  },
  {
    id: "item-50",
    dimensionId: 3,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 131.52,
  },
  {
    id: "item-51",
    dimensionId: 2,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 105.86,
  },
  {
    id: "item-52",
    dimensionId: 1,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 142.11,
  },
  {
    id: "item-53",
    dimensionId: 4,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 76.81,
  },
  {
    id: "item-54",
    dimensionId: 9,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 254.03,
  },
  {
    id: "item-55",
    dimensionId: 1,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 297.9,
  },
  {
    id: "item-56",
    dimensionId: 1,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 271.29,
  },
  {
    id: "item-57",
    dimensionId: 1,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 51.77,
  },
  {
    id: "item-58",
    dimensionId: 5,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 105.24,
  },
  {
    id: "item-59",
    dimensionId: 4,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 200.64,
  },
  {
    id: "item-60",
    dimensionId: 2,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 390.03,
  },
  {
    id: "item-61",
    dimensionId: 4,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 330.29,
  },
  {
    id: "item-62",
    dimensionId: 2,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 219.29,
  },
  {
    id: "item-63",
    dimensionId: 3,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 70.19,
  },
  {
    id: "item-64",
    dimensionId: 3,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 94.18,
  },
  {
    id: "item-65",
    dimensionId: 3,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 244.3,
  },
  {
    id: "item-66",
    dimensionId: 3,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 204.6,
  },
  {
    id: "item-67",
    dimensionId: 6,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 3.28,
  },
  {
    id: "item-68",
    dimensionId: 6,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 433.6,
  },
  {
    id: "item-69",
    dimensionId: 9,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 119.26,
  },
  {
    id: "item-70",
    dimensionId: 4,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 123.56,
  },
  {
    id: "item-71",
    dimensionId: 5,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 419.2,
  },
  {
    id: "item-72",
    dimensionId: 7,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 90.72,
  },
  {
    id: "item-73",
    dimensionId: 8,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 460.87,
  },
  {
    id: "item-74",
    dimensionId: 5,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 63.65,
  },
  {
    id: "item-75",
    dimensionId: 8,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 216.6,
  },
  {
    id: "item-76",
    dimensionId: 3,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 488.47,
  },
  {
    id: "item-77",
    dimensionId: 4,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 189.89,
  },
  {
    id: "item-78",
    dimensionId: 6,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 116.47,
  },
  {
    id: "item-79",
    dimensionId: 9,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 63.31,
  },
  {
    id: "item-80",
    dimensionId: 4,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 435.87,
  },
  {
    id: "item-81",
    dimensionId: 4,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 139.44,
  },
  {
    id: "item-82",
    dimensionId: 1,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 130.48,
  },
  {
    id: "item-83",
    dimensionId: 9,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 342.94,
  },
  {
    id: "item-84",
    dimensionId: 7,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 0.65,
  },
  {
    id: "item-85",
    dimensionId: 2,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 703.2,
  },
  {
    id: "item-86",
    dimensionId: 3,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 688.78,
  },
  {
    id: "item-87",
    dimensionId: 7,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 597.64,
  },
  {
    id: "item-88",
    dimensionId: 9,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 549.64,
  },
  {
    id: "item-89",
    dimensionId: 5,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 568.76,
  },
  {
    id: "item-90",
    dimensionId: 2,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 437.84,
  },
  {
    id: "item-91",
    dimensionId: 4,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 736.7,
  },
  {
    id: "item-92",
    dimensionId: 4,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 208.31,
  },
  {
    id: "item-93",
    dimensionId: 7,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 359.83,
  },
  {
    id: "item-94",
    dimensionId: 9,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 98.48,
  },
  {
    id: "item-95",
    dimensionId: 3,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 441.42,
  },
  {
    id: "item-96",
    dimensionId: 9,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "L",
    value: 1181.32,
  },
  {
    id: "item-97",
    dimensionId: 7,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 933.02,
  },
  {
    id: "item-98",
    dimensionId: 2,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "L",
    value: 896.85,
  },
  {
    id: "item-99",
    dimensionId: 4,
    info: "+ 15% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’eau",
    labelEN: "Water consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "L",
    value: 2266.3,
  },
]
