import { Alert, Container, Flex, Loader, Stack } from "@mantine/core"
import { IconAlertCircle } from "@tabler/icons-react"
import { UseQueryResult } from "@tanstack/react-query"
import _, { Dictionary } from "lodash"
import { useTranslation } from "react-i18next"

/**
 * @interface SpinnerProps
 *
 * @description Props for the QueryWrapper spinner
 *
 * @prop {boolean} fluid - Whether the spinner should be centered and take all space
 * @prop {boolean} absolute - Whether the spinner should be absolutely positioned
 */
interface SpinnerProps {
  fluid?: boolean
  absolute?: boolean
}

const Spinner = ({ fluid, absolute }: SpinnerProps) => {
  if (fluid) {
    return (
      <Flex
        pos={absolute ? "absolute" : undefined}
        direction="row"
        h="100%"
        w="100%"
        justify="center"
        align="center"
      >
        <Loader />
      </Flex>
    )
  }

  return (
    <Container mt="5%">
      <Stack align="center">
        <Loader />
      </Stack>
    </Container>
  )
}

interface QueryWrapperProps<TData, TError> {
  query: UseQueryResult<TData, TError>
  emptyPlaceholder?: () => JSX.Element | null
  allowEmptyArray?: boolean
  allowEmptyObject?: boolean
  fluid?: boolean
  absolute?: boolean
  children: (props: { data: TData }) => JSX.Element | null
}

/**
 * QueryWrapper wraps a component with the query it needs to get its data.
 *
 * This way, we can generalize the pattern of loading/erroring on data loads.
 *
 * Example:
 * <QueryWrapper
 *   query={disclosureRequirementAnswersQuery}
 *   allowEmptyArray
 * >
 *   {({ data }) => {EmptyPlaceholder
 *     return (
 *       <IRO2Page
 *         initialValues={data}
 *         disclosureRequirement={response.disclosureRequirement}
 *       />
 *     )
 *   }}
 * </QueryWrapper>
 */
export const QueryWrapper = <TData, TError>({
  query,
  emptyPlaceholder: EmptyPlaceholder,
  allowEmptyArray = false,
  allowEmptyObject = false,
  children,
  fluid,
  absolute,
}: QueryWrapperProps<TData, TError>) => {
  const { t } = useTranslation()
  const { data, isLoading, isError } = query

  if (isError) {
    return (
      <Container mt="5%">
        <Alert
          color="red"
          title={t("queryWrapper.error.title")}
          icon={<IconAlertCircle />}
        >
          {t("queryWrapper.error.message")}
        </Alert>
      </Container>
    )
  }

  if (isLoading) return <Spinner fluid={fluid} absolute={absolute} />

  if (
    (!allowEmptyObject && !data) ||
    (_.isEmpty(data) && !allowEmptyArray && !allowEmptyObject)
  ) {
    return EmptyPlaceholder ? (
      <EmptyPlaceholder />
    ) : (
      <Container mt="5%">
        <Alert
          color="red"
          title={t("queryWrapper.empty.title")}
          icon={<IconAlertCircle />}
        >
          {t("queryWrapper.empty.message")}
        </Alert>
      </Container>
    )
  }

  return data ? children({ data }) : <></>
}

interface QueryConfig<TData, TError> {
  query: UseQueryResult<TData, TError>
  emptyPlaceholder?: () => JSX.Element | null
  allowEmptyArray?: boolean
  allowEmptyObject?: boolean
}

type QueriesConfig<TData, TError> = Dictionary<QueryConfig<TData, TError>>

type ExtractData<TData, TError, T extends QueriesConfig<TData, TError>> = {
  [K in keyof T]: NonNullable<T[K]["query"]["data"]>
}

interface MultiQueryWrapperProps<
  TData,
  TError,
  T extends QueriesConfig<TData, TError>,
> {
  queries: T
  children: (data: ExtractData<TData, TError, T>) => JSX.Element | null
  absolute?: boolean
  fluid?: boolean
}

/**
 * MultiQueryWrapper is similar to QueryWrapper, but it allows to wrap multiple queries.
 *
 * This is useful if a component depends on queries from multiple sources.
 * For example, Dato and the backend, or multiple endpoints on the backend.
 */
export function MultiQueryWrapper<
  TData,
  TError,
  T extends QueriesConfig<TData, TError>,
>({
  queries,
  children,
  fluid,
  absolute,
}: MultiQueryWrapperProps<TData, TError, T>): JSX.Element | null {
  const { t } = useTranslation()

  if (Object.values(queries).some(({ query }) => query.isLoading)) {
    return <Spinner fluid={fluid} absolute={absolute} />
  }

  const errorQuery = Object.values(queries).find(({ query }) => query.isError)
  if (errorQuery) {
    return (
      <Container mt="5%">
        <Alert
          color="red"
          title={t("queryWrapper.error.title")}
          icon={<IconAlertCircle />}
        >
          {t("queryWrapper.error.message")}
        </Alert>
      </Container>
    )
  }

  const emptyQuery = Object.entries(queries).find(
    ([_key, { query, allowEmptyArray, allowEmptyObject }]) => {
      return (
        (!allowEmptyObject && !query.data) ||
        (!allowEmptyArray && !allowEmptyObject && _.isEmpty(query.data))
      )
    },
  )
  if (emptyQuery) {
    const [key, { emptyPlaceholder: EmptyPlaceholder }] = emptyQuery
    return EmptyPlaceholder ? (
      <EmptyPlaceholder />
    ) : (
      <Container mt="5%">
        <Alert
          color="red"
          title={t("queryWrapper.empty.title")}
          icon={<IconAlertCircle />}
        >
          {t("queryWrapper.empty.message")} (Query: {key})
        </Alert>
      </Container>
    )
  }

  const data = _.mapValues(
    queries,
    (queryConfig) => queryConfig.query.data,
  ) as ExtractData<TData, TError, T>
  return children(data)
}
