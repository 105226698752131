import { DataPointDemo } from "@kiosk/front/api/dashboard/types"

export const Governance5: DataPointDemo[] = [
  {
    id: "item-0",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.02,
  },
  {
    id: "item-1",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.09,
  },
  {
    id: "item-2",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.07,
  },
  {
    id: "item-3",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.1,
  },
  {
    id: "item-4",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.01,
  },
  {
    id: "item-5",
    dimensionId: 7,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.02,
  },
  {
    id: "item-6",
    dimensionId: 2,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.04,
  },
  {
    id: "item-7",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.09,
  },
  {
    id: "item-8",
    dimensionId: 7,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.07,
  },
  {
    id: "item-9",
    dimensionId: 2,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.02,
  },
  {
    id: "item-10",
    dimensionId: 1,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.06,
  },
  {
    id: "item-11",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.1,
  },
  {
    id: "item-12",
    dimensionId: 1,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.04,
  },
  {
    id: "item-13",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.05,
  },
  {
    id: "item-14",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.01,
  },
  {
    id: "item-15",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.01,
  },
  {
    id: "item-16",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.07,
  },
  {
    id: "item-17",
    dimensionId: 5,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.04,
  },
  {
    id: "item-18",
    dimensionId: 7,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.01,
  },
  {
    id: "item-19",
    dimensionId: 7,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0,
  },
  {
    id: "item-20",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.01,
  },
  {
    id: "item-21",
    dimensionId: 1,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.1,
  },
  {
    id: "item-22",
    dimensionId: 7,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.03,
  },
  {
    id: "item-23",
    dimensionId: 1,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.05,
  },
  {
    id: "item-24",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.05,
  },
  {
    id: "item-25",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.03,
  },
  {
    id: "item-26",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.08,
  },
  {
    id: "item-27",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.1,
  },
  {
    id: "item-28",
    dimensionId: 1,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.05,
  },
  {
    id: "item-29",
    dimensionId: 7,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.02,
  },
  {
    id: "item-30",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.03,
  },
  {
    id: "item-31",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.11,
  },
  {
    id: "item-32",
    dimensionId: 1,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.08,
  },
  {
    id: "item-33",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.02,
  },
  {
    id: "item-34",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.06,
  },
  {
    id: "item-35",
    dimensionId: 5,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.03,
  },
  {
    id: "item-36",
    dimensionId: 7,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0,
  },
  {
    id: "item-37",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.06,
  },
  {
    id: "item-38",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.09,
  },
  {
    id: "item-39",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.05,
  },
  {
    id: "item-40",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.11,
  },
  {
    id: "item-41",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.09,
  },
  {
    id: "item-42",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.01,
  },
  {
    id: "item-43",
    dimensionId: 2,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.02,
  },
  {
    id: "item-44",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.05,
  },
  {
    id: "item-45",
    dimensionId: 1,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.04,
  },
  {
    id: "item-46",
    dimensionId: 7,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.01,
  },
  {
    id: "item-47",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.11,
  },
  {
    id: "item-48",
    dimensionId: 7,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.04,
  },
  {
    id: "item-49",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.08,
  },
  {
    id: "item-50",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.11,
  },
  {
    id: "item-51",
    dimensionId: 1,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.05,
  },
  {
    id: "item-52",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.04,
  },
  {
    id: "item-53",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.02,
  },
  {
    id: "item-54",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.01,
  },
  {
    id: "item-55",
    dimensionId: 1,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.04,
  },
  {
    id: "item-56",
    dimensionId: 5,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.07,
  },
  {
    id: "item-57",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.03,
  },
  {
    id: "item-58",
    dimensionId: 1,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.11,
  },
  {
    id: "item-59",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.02,
  },
  {
    id: "item-60",
    dimensionId: 1,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.05,
  },
  {
    id: "item-61",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.03,
  },
  {
    id: "item-62",
    dimensionId: 5,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.05,
  },
  {
    id: "item-63",
    dimensionId: 7,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.12,
  },
  {
    id: "item-64",
    dimensionId: 1,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.07,
  },
  {
    id: "item-65",
    dimensionId: 1,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.03,
  },
  {
    id: "item-66",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.05,
  },
  {
    id: "item-67",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.11,
  },
  {
    id: "item-68",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.1,
  },
  {
    id: "item-69",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.11,
  },
  {
    id: "item-70",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.03,
  },
  {
    id: "item-71",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.07,
  },
  {
    id: "item-72",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.03,
  },
  {
    id: "item-73",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.04,
  },
  {
    id: "item-74",
    dimensionId: 5,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.04,
  },
  {
    id: "item-75",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.03,
  },
  {
    id: "item-76",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.12,
  },
  {
    id: "item-77",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.12,
  },
  {
    id: "item-78",
    dimensionId: 1,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.1,
  },
  {
    id: "item-79",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.08,
  },
  {
    id: "item-80",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.04,
  },
  {
    id: "item-81",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.06,
  },
  {
    id: "item-82",
    dimensionId: 5,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.11,
  },
  {
    id: "item-83",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.14,
  },
  {
    id: "item-84",
    dimensionId: 2,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.06,
  },
  {
    id: "item-85",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.12,
  },
  {
    id: "item-86",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.05,
  },
  {
    id: "item-87",
    dimensionId: 5,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.1,
  },
  {
    id: "item-88",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.09,
  },
  {
    id: "item-89",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.11,
  },
  {
    id: "item-90",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.11,
  },
  {
    id: "item-91",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.14,
  },
  {
    id: "item-92",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0,
  },
  {
    id: "item-93",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0,
  },
  {
    id: "item-94",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.07,
  },
  {
    id: "item-95",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.07,
  },
  {
    id: "item-96",
    dimensionId: 2,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.15,
  },
  {
    id: "item-97",
    dimensionId: 5,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.03,
  },
  {
    id: "item-98",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.1,
  },
  {
    id: "item-99",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.05,
  },
  {
    id: "item-100",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.13,
  },
  {
    id: "item-101",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0,
  },
  {
    id: "item-102",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.08,
  },
  {
    id: "item-103",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.04,
  },
  {
    id: "item-104",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.08,
  },
  {
    id: "item-105",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.05,
  },
  {
    id: "item-106",
    dimensionId: 5,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.09,
  },
  {
    id: "item-107",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.02,
  },
  {
    id: "item-108",
    dimensionId: 5,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.07,
  },
  {
    id: "item-109",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.15,
  },
  {
    id: "item-110",
    dimensionId: 5,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.11,
  },
  {
    id: "item-111",
    dimensionId: 7,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.12,
  },
  {
    id: "item-112",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.07,
  },
  {
    id: "item-113",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.09,
  },
  {
    id: "item-114",
    dimensionId: 5,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.15,
  },
  {
    id: "item-115",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.09,
  },
  {
    id: "item-116",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.13,
  },
  {
    id: "item-117",
    dimensionId: 7,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.12,
  },
  {
    id: "item-118",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.11,
  },
  {
    id: "item-119",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.07,
  },
  {
    id: "item-120",
    dimensionId: 5,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.11,
  },
  {
    id: "item-121",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.07,
  },
  {
    id: "item-122",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.05,
  },
  {
    id: "item-123",
    dimensionId: 2,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.03,
  },
  {
    id: "item-124",
    dimensionId: 2,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.03,
  },
  {
    id: "item-125",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.07,
  },
  {
    id: "item-126",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.09,
  },
  {
    id: "item-127",
    dimensionId: 2,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.11,
  },
  {
    id: "item-128",
    dimensionId: 2,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.08,
  },
  {
    id: "item-129",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.13,
  },
  {
    id: "item-130",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.11,
  },
  {
    id: "item-131",
    dimensionId: 2,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.09,
  },
  {
    id: "item-132",
    dimensionId: 1,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.08,
  },
  {
    id: "item-133",
    dimensionId: 1,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.05,
  },
  {
    id: "item-134",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.1,
  },
  {
    id: "item-135",
    dimensionId: 7,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.15,
  },
  {
    id: "item-136",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.03,
  },
  {
    id: "item-137",
    dimensionId: 5,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.12,
  },
  {
    id: "item-138",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.03,
  },
  {
    id: "item-139",
    dimensionId: 5,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.04,
  },
  {
    id: "item-140",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.03,
  },
  {
    id: "item-141",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.08,
  },
  {
    id: "item-142",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.12,
  },
  {
    id: "item-143",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.19,
  },
  {
    id: "item-144",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.09,
  },
  {
    id: "item-145",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.19,
  },
  {
    id: "item-146",
    dimensionId: 1,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.01,
  },
  {
    id: "item-147",
    dimensionId: 5,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.19,
  },
  {
    id: "item-148",
    dimensionId: 1,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0,
  },
  {
    id: "item-149",
    dimensionId: 7,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.02,
  },
  {
    id: "item-150",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.06,
  },
  {
    id: "item-151",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.01,
  },
  {
    id: "item-152",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.16,
  },
  {
    id: "item-153",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.11,
  },
  {
    id: "item-154",
    dimensionId: 5,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.02,
  },
  {
    id: "item-155",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.14,
  },
  {
    id: "item-156",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.04,
  },
  {
    id: "item-157",
    dimensionId: 1,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.19,
  },
  {
    id: "item-158",
    dimensionId: 2,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.15,
  },
  {
    id: "item-159",
    dimensionId: 2,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.21,
  },
  {
    id: "item-160",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.23,
  },
  {
    id: "item-161",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.05,
  },
  {
    id: "item-162",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.07,
  },
  {
    id: "item-163",
    dimensionId: 5,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.1,
  },
  {
    id: "item-164",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.08,
  },
  {
    id: "item-165",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.07,
  },
  {
    id: "item-166",
    dimensionId: 7,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.02,
  },
  {
    id: "item-167",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.19,
  },
  {
    id: "item-168",
    dimensionId: 5,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.23,
  },
  {
    id: "item-169",
    dimensionId: 7,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.17,
  },
  {
    id: "item-170",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.28,
  },
  {
    id: "item-171",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.2,
  },
  {
    id: "item-172",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.07,
  },
  {
    id: "item-173",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.2,
  },
  {
    id: "item-174",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.25,
  },
  {
    id: "item-175",
    dimensionId: 1,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.07,
  },
  {
    id: "item-176",
    dimensionId: 2,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.22,
  },
  {
    id: "item-177",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.24,
  },
  {
    id: "item-178",
    dimensionId: 2,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.1,
  },
  {
    id: "item-179",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.01,
  },
  {
    id: "item-180",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.1,
  },
  {
    id: "item-181",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.27,
  },
  {
    id: "item-182",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.31,
  },
  {
    id: "item-183",
    dimensionId: 7,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.2,
  },
  {
    id: "item-184",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.02,
  },
  {
    id: "item-185",
    dimensionId: 8,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.03,
  },
  {
    id: "item-186",
    dimensionId: 5,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.03,
  },
  {
    id: "item-187",
    dimensionId: 7,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.06,
  },
  {
    id: "item-188",
    dimensionId: 2,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.06,
  },
  {
    id: "item-189",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.5,
  },
  {
    id: "item-190",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.04,
  },
  {
    id: "item-191",
    dimensionId: 1,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.23,
  },
  {
    id: "item-192",
    dimensionId: 2,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.33,
  },
  {
    id: "item-193",
    dimensionId: 2,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.35,
  },
  {
    id: "item-194",
    dimensionId: 7,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.41,
  },
  {
    id: "item-195",
    dimensionId: 1,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.62,
  },
  {
    id: "item-196",
    dimensionId: 4,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.67,
  },
  {
    id: "item-197",
    dimensionId: 9,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.53,
  },
  {
    id: "item-198",
    dimensionId: 3,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.7,
  },
  {
    id: "item-199",
    dimensionId: 6,
    info: "+ 30% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "% de femmes au conseil d’administration",
    labelEN: "Percentage of Women on board",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 1.33,
  },
]
