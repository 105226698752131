import { DataPointDemo } from "@kiosk/front/api/dashboard/types"

export const Social4: DataPointDemo[] = [
  {
    id: "item-0",
    dimensionId: 3,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 5.85,
  },
  {
    id: "item-1",
    dimensionId: 6,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 5.07,
  },
  {
    id: "item-2",
    dimensionId: 3,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 3.94,
  },
  {
    id: "item-3",
    dimensionId: 8,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 2.9,
  },
  {
    id: "item-4",
    dimensionId: 3,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 7.18,
  },
  {
    id: "item-5",
    dimensionId: 5,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 6.17,
  },
  {
    id: "item-6",
    dimensionId: 8,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 8.05,
  },
  {
    id: "item-7",
    dimensionId: 2,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 2.89,
  },
  {
    id: "item-8",
    dimensionId: 1,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 4.45,
  },
  {
    id: "item-9",
    dimensionId: 5,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 3.02,
  },
  {
    id: "item-10",
    dimensionId: 4,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 1.69,
  },
  {
    id: "item-11",
    dimensionId: 6,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 5.73,
  },
  {
    id: "item-12",
    dimensionId: 4,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 7.28,
  },
  {
    id: "item-13",
    dimensionId: 6,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 7.55,
  },
  {
    id: "item-14",
    dimensionId: 9,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 7.95,
  },
  {
    id: "item-15",
    dimensionId: 9,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 5.19,
  },
  {
    id: "item-16",
    dimensionId: 6,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.13,
  },
  {
    id: "item-17",
    dimensionId: 6,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 4.49,
  },
  {
    id: "item-18",
    dimensionId: 3,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 2.56,
  },
  {
    id: "item-19",
    dimensionId: 1,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 7.71,
  },
  {
    id: "item-20",
    dimensionId: 1,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.66,
  },
  {
    id: "item-21",
    dimensionId: 7,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 2.24,
  },
  {
    id: "item-22",
    dimensionId: 6,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 4.6,
  },
  {
    id: "item-23",
    dimensionId: 1,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 8.04,
  },
  {
    id: "item-24",
    dimensionId: 9,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 2.01,
  },
  {
    id: "item-25",
    dimensionId: 2,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.32,
  },
  {
    id: "item-26",
    dimensionId: 3,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 2.27,
  },
  {
    id: "item-27",
    dimensionId: 3,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 4.33,
  },
  {
    id: "item-28",
    dimensionId: 8,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 8.58,
  },
  {
    id: "item-29",
    dimensionId: 3,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.14,
  },
  {
    id: "item-30",
    dimensionId: 2,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.72,
  },
  {
    id: "item-31",
    dimensionId: 2,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 7.13,
  },
  {
    id: "item-32",
    dimensionId: 2,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 5.53,
  },
  {
    id: "item-33",
    dimensionId: 8,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 2.97,
  },
  {
    id: "item-34",
    dimensionId: 1,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 2.65,
  },
  {
    id: "item-35",
    dimensionId: 2,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 8.11,
  },
  {
    id: "item-36",
    dimensionId: 5,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 3.29,
  },
  {
    id: "item-37",
    dimensionId: 9,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 8.57,
  },
  {
    id: "item-38",
    dimensionId: 6,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 3.11,
  },
  {
    id: "item-39",
    dimensionId: 9,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 8.55,
  },
  {
    id: "item-40",
    dimensionId: 9,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 8.14,
  },
  {
    id: "item-41",
    dimensionId: 9,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 1.2,
  },
  {
    id: "item-42",
    dimensionId: 8,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 4.9,
  },
  {
    id: "item-43",
    dimensionId: 4,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 3.96,
  },
  {
    id: "item-44",
    dimensionId: 4,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.46,
  },
  {
    id: "item-45",
    dimensionId: 1,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 5.15,
  },
  {
    id: "item-46",
    dimensionId: 3,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 8.74,
  },
  {
    id: "item-47",
    dimensionId: 5,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 2.43,
  },
  {
    id: "item-48",
    dimensionId: 8,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 10.14,
  },
  {
    id: "item-49",
    dimensionId: 9,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 6.61,
  },
  {
    id: "item-50",
    dimensionId: 6,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 3.22,
  },
  {
    id: "item-51",
    dimensionId: 3,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 10.55,
  },
  {
    id: "item-52",
    dimensionId: 9,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 8.77,
  },
  {
    id: "item-53",
    dimensionId: 6,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 8.66,
  },
  {
    id: "item-54",
    dimensionId: 3,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.18,
  },
  {
    id: "item-55",
    dimensionId: 7,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 1.24,
  },
  {
    id: "item-56",
    dimensionId: 6,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.79,
  },
  {
    id: "item-57",
    dimensionId: 3,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 2.31,
  },
  {
    id: "item-58",
    dimensionId: 6,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 11.94,
  },
  {
    id: "item-59",
    dimensionId: 3,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 5.79,
  },
  {
    id: "item-60",
    dimensionId: 2,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 7.98,
  },
  {
    id: "item-61",
    dimensionId: 3,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 8.62,
  },
  {
    id: "item-62",
    dimensionId: 3,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 11.02,
  },
  {
    id: "item-63",
    dimensionId: 8,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 7.75,
  },
  {
    id: "item-64",
    dimensionId: 6,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 7.32,
  },
  {
    id: "item-65",
    dimensionId: 7,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 8.25,
  },
  {
    id: "item-66",
    dimensionId: 5,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.28,
  },
  {
    id: "item-67",
    dimensionId: 3,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 2.45,
  },
  {
    id: "item-68",
    dimensionId: 3,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 10.62,
  },
  {
    id: "item-69",
    dimensionId: 3,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 13.5,
  },
  {
    id: "item-70",
    dimensionId: 7,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 11.85,
  },
  {
    id: "item-71",
    dimensionId: 8,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 2.54,
  },
  {
    id: "item-72",
    dimensionId: 3,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 7.13,
  },
  {
    id: "item-73",
    dimensionId: 3,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 14.85,
  },
  {
    id: "item-74",
    dimensionId: 7,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 13.83,
  },
  {
    id: "item-75",
    dimensionId: 9,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 5.45,
  },
  {
    id: "item-76",
    dimensionId: 8,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 7.26,
  },
  {
    id: "item-77",
    dimensionId: 2,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 6.69,
  },
  {
    id: "item-78",
    dimensionId: 9,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 15.23,
  },
  {
    id: "item-79",
    dimensionId: 6,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 7.85,
  },
  {
    id: "item-80",
    dimensionId: 4,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 4.73,
  },
  {
    id: "item-81",
    dimensionId: 5,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 4.62,
  },
  {
    id: "item-82",
    dimensionId: 6,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 4,
  },
  {
    id: "item-83",
    dimensionId: 7,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 14.63,
  },
  {
    id: "item-84",
    dimensionId: 4,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 3.89,
  },
  {
    id: "item-85",
    dimensionId: 6,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 1.72,
  },
  {
    id: "item-86",
    dimensionId: 7,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.67,
  },
  {
    id: "item-87",
    dimensionId: 8,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 10.18,
  },
  {
    id: "item-88",
    dimensionId: 7,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 6.02,
  },
  {
    id: "item-89",
    dimensionId: 8,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 2.94,
  },
  {
    id: "item-90",
    dimensionId: 6,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 25.74,
  },
  {
    id: "item-91",
    dimensionId: 8,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 16.73,
  },
  {
    id: "item-92",
    dimensionId: 5,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 2.46,
  },
  {
    id: "item-93",
    dimensionId: 4,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 7.24,
  },
  {
    id: "item-94",
    dimensionId: 2,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 17.42,
  },
  {
    id: "item-95",
    dimensionId: 8,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 27.4,
  },
  {
    id: "item-96",
    dimensionId: 4,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 20.27,
  },
  {
    id: "item-97",
    dimensionId: 6,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 2.19,
  },
  {
    id: "item-98",
    dimensionId: 2,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 74.04,
  },
  {
    id: "item-99",
    dimensionId: 8,
    info: "+ 18% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre de femmes",
    labelEN: "Number of women",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 83.84,
  },
]
