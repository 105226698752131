import { UseQueryResult, useMutation, useQuery } from "@tanstack/react-query"
import _ from "lodash"

import { DashboardResponses } from "@kiosk/types/endpoints/dashboard"
import { DisclosureRequirementParagraph } from "@kiosk/types/prisma-client"

import { listDimensions } from "@kiosk/front/api/dimensions/queries"
import { getSurveyQuestionsDataModel } from "@kiosk/front/api/surveys/questions/model/surveyQuestionsDataModel"
import { useUser } from "@kiosk/front/components/auth/UserContext"
import { apiClient } from "@kiosk/front/lib/apiClient"
import { enrichDimensionBreakdowns } from "@kiosk/front/utils/dimensions"
import { downloadFile } from "@kiosk/front/utils/files"

import { dashboardKeys } from "./dashboardKeys"
import { DashboardCategory, DataPoint } from "./types"

export const useListDashboardDataPointsQuery = () => {
  return useQuery<DashboardCategory[]>({
    queryKey: dashboardKeys.allDataPoints(),
    queryFn: async () => {
      // TODO: this function should be in the backend and return the formatted data directly
      const [dataPoints, csrdCategories, dimensions] = await Promise.all([
        apiClient
          .get<DashboardResponses.DataPoint[]>("/dashboard")
          .then((response) => response.data),
        getSurveyQuestionsDataModel(),
        listDimensions(),
      ])

      const dataPointsByQuestionId = _.groupBy(
        dataPoints,
        (dataPoint) => dataPoint.datoQuestionId,
      )
      const answeredQuestionIds = Object.keys(dataPointsByQuestionId)

      return csrdCategories
        .map((category) => {
          const categoryTopics = category.topics.map((topic) => {
            const topicQuestions = topic.disclosureRequirements.flatMap(
              (dr) => dr.questions,
            )

            const questionsToDisplay = topicQuestions.filter(
              (question) =>
                answeredQuestionIds.includes(question.id) ||
                question.isMandatoryInDashboard,
            )

            const answeredQuestionsWithDimensions: DataPoint[] =
              questionsToDisplay.map((question) => {
                if (!dataPointsByQuestionId[question.id]) {
                  return {
                    ..._.omit(question, ["dimensionIds"]),
                    type: "single-value",
                    value: null,
                  }
                }

                if (question.dimensionIds.length === 0) {
                  return {
                    ..._.omit(question, ["dimensionIds"]),
                    type: "single-value",
                    value: dataPointsByQuestionId[question.id][0].answer.value,
                  }
                }
                return {
                  ..._.omit(question, ["dimensionIds"]),
                  type: "dimensioned",
                  values: dataPointsByQuestionId[question.id].map(
                    (dataPoint) => {
                      const questionDimensions = enrichDimensionBreakdowns(
                        dimensions,
                        dataPoint.dimensions,
                      )
                      return {
                        value: dataPoint.answer.value,
                        dimensions: questionDimensions,
                      }
                    },
                  ),
                }
              })

            return {
              ..._.omit(topic, ["disclosureRequirements"]),
              dataPoints: answeredQuestionsWithDimensions,
            }
          })

          return {
            ...category,
            topics: categoryTopics.filter(
              (topic) => topic.dataPoints.length > 0,
            ),
          }
        })
        .filter((category) => category.topics.length > 0)
    },
  })
}

export const useExportDashboardToCSVMutation = () => {
  const { data: dashboardCategories } = useListDashboardDataPointsQuery()

  return useMutation({
    mutationFn: async () => {
      if (!dashboardCategories) return
      const csvData = _.sortBy(dashboardCategories, (c) => c.order).flatMap(
        (category) =>
          category.topics.flatMap((topic) =>
            topic.dataPoints.flatMap((dataPoint) => {
              switch (dataPoint.type) {
                case "single-value":
                  return [
                    [
                      category.name,
                      topic.name,
                      dataPoint.label,
                      dataPoint.unit,
                      dataPoint.value,
                    ],
                  ]
                case "dimensioned":
                  return dataPoint.values.map((value) => {
                    return [
                      category.name,
                      topic.name,
                      dataPoint.label,
                      dataPoint.unit,
                      value.value,
                      ...value.dimensions.flatMap((dimension) => [
                        dimension.dimensionLabel,
                        dimension.optionLabel,
                      ]),
                    ]
                  })
              }
            }),
          ),
      )

      const longestRow = Math.max(...csvData.map((l) => l.length))
      const headerRow = [
        "Category",
        "Topic",
        "Question",
        "Unit",
        "Value",
        ..._.times(Math.ceil((longestRow - 5) / 2), (i) => [
          `Dimension ${i + 1}`,
          `Option ${i + 1}`,
        ]).flat(),
      ]

      downloadFile(
        [headerRow, ...csvData].map((l) => l.join(";")).join("\n"),
        "dashboard.csv",
        "text/csv",
      )
    },
  })
}

/**
 * @function useDisclosureRequirementParagraphByDisclosureRequirementId
 * @description returns a list of all the disclosure requirement paragraph for the current company by disclosure requirement id
 * @param {string} disclosureRequirementId
 * @returns {UseQueryResult<DisclosureRequirementParagraph>}
 */

export const useDisclosureRequirementParagraphByDisclosureRequirementId = (
  disclosureRequirementId: string,
): UseQueryResult<DisclosureRequirementParagraph> => {
  const { companyId } = useUser()

  return useQuery<DisclosureRequirementParagraph>({
    queryKey: dashboardKeys.allDataPointsWithDependency(
      disclosureRequirementId,
    ),
    queryFn: async () => {
      const data = await apiClient.get<DisclosureRequirementParagraph>(
        `/disclosureRequirement/${companyId}/${disclosureRequirementId}/xbrl_paragraphs`,
      )

      return data.data
    },
  })
}
