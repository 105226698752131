import { SegmentedControl } from "@mantine/core"
import { useTranslation } from "react-i18next"

interface DisclosureRequirementTabProps {
  onChange: (tab: string) => void
  value: string
}
export const DisclosureRequirementTab = (
  props: DisclosureRequirementTabProps,
) => {
  const { t } = useTranslation("csrd")
  return (
    <SegmentedControl
      value={props.value}
      onChange={props.onChange}
      data={[t("preview.tabForm"), t("preview.tabPreview")]}
    />
  )
}
