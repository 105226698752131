import { Button, Group, Select, Stack, Title } from "@mantine/core"
import { IconDownload } from "@tabler/icons-react"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import {
  useExportDashboardToCSVMutation,
  useListDashboardDataPointsQuery,
} from "@kiosk/front/api/dashboard/queries"
import {
  DashboardCategory,
  DashboardCategoryDemo,
  DataPointDemo,
} from "@kiosk/front/api/dashboard/types"
import { useUser } from "@kiosk/front/components/auth/UserContext"
import { CategoryDashboard } from "@kiosk/front/components/dashboard/CategoryDashboard"
import {
  CategoryDashboardDemo,
  DashboardStyleKey,
} from "@kiosk/front/components/dashboard/CategoryDashboardDemo"
import { PageLayout } from "@kiosk/front/components/layout/PageLayout"
import { QueryWrapper } from "@kiosk/front/components/layout/QueryWrapper"
import { config } from "@kiosk/front/config"
import { FAKE_DASHBOARD_DATA } from "@kiosk/front/constants/dashboard/data"
import { DIMENSIONS } from "@kiosk/front/constants/dashboard/dimension"
import {
  sortCategoryByOrder,
  sortCategoryByOrderDemo,
} from "@kiosk/front/utils/sort"

import { EmptyDashboard } from "./EmptyDashboard"

// Types
type FilterState = {
  zone: string
  filiale: string
  time: string
}

interface DashboardContentProps {
  dashboardCategories: DashboardCategory[] | DashboardCategoryDemo[]
}

// Filtre les DataPoints en fonction des filtres sélectionnés
const filterDataPoints = (
  dataPoints: DataPointDemo[],
  filters: FilterState,
): DataPointDemo[] => {
  const { zone, filiale, time } = filters

  return dataPoints.map((dataPoint) => {
    const dimension = DIMENSIONS.find(
      (dim) => dim.id === String(dataPoint.dimensionId),
    )

    // Si la valeur du filtre est "Zone", "Filiale" ou "Temps", on ne filtre pas selon ce critère.
    const zoneMatch = zone === "all" || dimension?.country === zone
    const filialeMatch =
      filiale === "all" || (dimension && dimension.business_unit === filiale)
    const timeMatch = time === "all" || dataPoint.time === parseInt(time, 10)

    if (dataPoint.type === "dimensioned") {
      return {
        ...dataPoint,
        values:
          zoneMatch && filialeMatch && timeMatch ? dataPoint.values : undefined,
      }
    }

    return {
      ...dataPoint,
      value:
        zoneMatch && filialeMatch && timeMatch ? dataPoint.value : undefined,
    }
  })
}

const DashboardContent = ({ dashboardCategories }: DashboardContentProps) => {
  const user = useUser()
  const { t } = useTranslation(["dashboard"])

  const { mutateAsync: exportToCSV, isPending } =
    useExportDashboardToCSVMutation()

  const [zone, setZone] = useState("all")
  const [filiale, setFiliale] = useState("all")
  const [time, setTime] = useState("all")

  const filters = { zone, filiale, time }

  // Applique les filtres à chaque catégorie et topic du Dashboard
  const filteredCategories = FAKE_DASHBOARD_DATA.map((category) => ({
    ...category,
    topics: category.topics.map((topic) => ({
      ...topic,
      dataPoints: filterDataPoints(topic.dataPoints, filters),
    })),
  }))

  return (
    <PageLayout contained>
      <Stack gap={32} h="100%" mb={32}>
        <Group justify="space-between">
          <Title m={0} mb={12}>
            {t("title", { firstName: user.firstName })}
          </Title>

          <Group>
            {config.isDemo ? (
              <Group>
                <Select
                  placeholder="Zone"
                  data={[
                    { value: "all", label: t("select.zone.all") },
                    { value: "france", label: t("select.zone.france") },
                    { value: "spain", label: t("select.zone.spain") },
                    { value: "italia", label: t("select.zone.italia") },
                  ]}
                  onChange={(value) => {
                    if (value) setZone(value)
                  }}
                  value={zone}
                  w={150}
                />

                <Select
                  data={[
                    { value: "all", label: t("select.subsidiary.all") },
                    { value: "hr", label: t("select.subsidiary.hr") },
                    { value: "tech", label: t("select.subsidiary.tech") },
                    {
                      value: "operations",
                      label: t("select.subsidiary.operations"),
                    },
                  ]}
                  onChange={(value) => {
                    if (value) setFiliale(value)
                  }}
                  value={filiale}
                  w={150}
                />

                <Select
                  data={[
                    { value: "all", label: t("select.year.all") },
                    { value: "2024", label: "2024" },
                    { value: "2023", label: "2023" },
                    { value: "2022", label: "2022" },
                  ]}
                  onChange={(value) => {
                    if (value) setTime(value)
                  }}
                  value={time}
                  w={150}
                />
              </Group>
            ) : null}

            <Button
              leftSection={<IconDownload />}
              loading={isPending}
              onClick={() => exportToCSV()}
            >
              {t("actions.export")}
            </Button>
          </Group>
        </Group>

        {config.isDemo ? (
          <Stack gap={48} pb={40}>
            {sortCategoryByOrderDemo(
              filteredCategories as DashboardCategoryDemo[],
            ).map((category) => (
              <CategoryDashboardDemo
                key={category.id}
                nameEN={category.nameEN}
                nameFR={category.nameFR}
                name={category.name as DashboardStyleKey}
                topics={category.topics}
              />
            ))}
          </Stack>
        ) : (
          <Stack gap={48}>
            {sortCategoryByOrder(
              dashboardCategories as DashboardCategory[],
            )?.map((category) => (
              <CategoryDashboard category={category} key={category.id} />
            ))}
          </Stack>
        )}

        {dashboardCategories.length === 0 && <EmptyDashboard />}
      </Stack>
    </PageLayout>
  )
}

export const Dashboard = () => {
  const dataPointsQuery = useListDashboardDataPointsQuery()

  return (
    <PageLayout contained>
      <QueryWrapper fluid query={dataPointsQuery}>
        {({ data: dashboardCategories }) => (
          <DashboardContent dashboardCategories={dashboardCategories} />
        )}
      </QueryWrapper>
    </PageLayout>
  )
}
