import { Text, Button, Drawer, Group, Stack, TextInput } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { IconBuilding } from "@tabler/icons-react"
import { useTranslation } from "react-i18next"

import { CompanyBody, companySchema } from "@kiosk/shared/schemas/companies"

import { useCreateCompanyMutation } from "@kiosk/front/api/companies/createCompany"
import { PictureUploader } from "@kiosk/front/components/PictureUploader"

export const CompanyForm = () => {
  const { t } = useTranslation(["common", "company"])
  const [opened, { open, close }] = useDisclosure(false)

  const form = useForm<CompanyBody>({
    validate: zodResolver(companySchema),
    validateInputOnBlur: true,
    validateInputOnChange: true,
    initialValues: {
      name: "",
      email: "",
      firstName: "",
      lastName: "",
      logoUrl: "",
    },
  })

  const handleCancel = () => {
    form.reset()
    close()
  }
  const { mutateAsync: createOrganization, isPending } =
    useCreateCompanyMutation(handleCancel)

  const handleOrganizationCreation = async () => {
    await createOrganization(form.values)
  }

  return (
    <>
      <Button onClick={open}>{t("company:newCompany")}</Button>
      <Drawer.Root position="right" size="md" opened={opened} onClose={close}>
        <Drawer.Overlay />
        <Drawer.Content>
          <Stack h="100%" gap={32}>
            <Drawer.Header
              p={24}
              style={{ borderBottom: "1px solid var(--mantine-color-gray-3)" }}
            >
              <Drawer.Title fz="lg" fw={600} c="black">
                {t("company:addCompany")}
              </Drawer.Title>
              <Drawer.CloseButton />
            </Drawer.Header>
            <Stack h="100%" justify="space-between">
              <Drawer.Body px={24}>
                <form
                  id="add-company"
                  onSubmit={form.onSubmit(handleOrganizationCreation)}
                >
                  <Stack>
                    <Text fw={500}>{t("company:columns.logo")}</Text>
                    <PictureUploader
                      placeholder={<IconBuilding />}
                      destination="company-logo/"
                      {...form.getInputProps("logoUrl")}
                    />
                    <TextInput
                      label={t("company:companyName")}
                      w="100%"
                      placeholder={t("company:companyName")}
                      withAsterisk
                      {...form.getInputProps("name")}
                    />
                    <TextInput
                      label={t("fields.firstNameLabel")}
                      placeholder={t("fields.firstNamePlaceholder")}
                      withAsterisk
                      {...form.getInputProps("firstName")}
                    />
                    <TextInput
                      error
                      label={t("fields.lastNameLabel")}
                      placeholder={t("fields.lastNamePlaceholder")}
                      withAsterisk
                      {...form.getInputProps("lastName")}
                    />
                    <TextInput
                      type="email"
                      label={t("company:companyContact")}
                      placeholder={t("columns.email")}
                      withAsterisk
                      {...form.getInputProps("email")}
                    />
                  </Stack>
                </form>
              </Drawer.Body>
              <Group
                style={{ borderTop: "1px solid var(--mantine-color-gray-3)" }}
                gap={10}
                p={24}
                justify="flex-end"
              >
                <Button variant="outline" color="gray" onClick={handleCancel}>
                  {t("buttons.cancel")}
                </Button>
                <Button
                  form="add-company"
                  type="submit"
                  disabled={!form.isValid()}
                  loading={isPending}
                >
                  {t("buttons.save")}
                </Button>
              </Group>
            </Stack>
          </Stack>
        </Drawer.Content>
      </Drawer.Root>
    </>
  )
}
