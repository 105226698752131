import { DataPointDemo } from "@kiosk/front/api/dashboard/types"

export const Environment9: DataPointDemo[] = [
  {
    id: "item-0",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 61309.63,
  },
  {
    id: "item-1",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 31455.85,
  },
  {
    id: "item-2",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 114408.81,
  },
  {
    id: "item-3",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 118930.7,
  },
  {
    id: "item-4",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 71714.94,
  },
  {
    id: "item-5",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 3702.86,
  },
  {
    id: "item-6",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 65536.21,
  },
  {
    id: "item-7",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 161004.45,
  },
  {
    id: "item-8",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 179355.91,
  },
  {
    id: "item-9",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 3178.46,
  },
  {
    id: "item-10",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 68840.64,
  },
  {
    id: "item-11",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 139138.76,
  },
  {
    id: "item-12",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 92705.01,
  },
  {
    id: "item-13",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 138569.26,
  },
  {
    id: "item-14",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 12757.24,
  },
  {
    id: "item-15",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 172777.76,
  },
  {
    id: "item-16",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 194299.15,
  },
  {
    id: "item-17",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 113878.87,
  },
  {
    id: "item-18",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 205573.74,
  },
  {
    id: "item-19",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 141555.27,
  },
  {
    id: "item-20",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 206748.37,
  },
  {
    id: "item-21",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 189023.95,
  },
  {
    id: "item-22",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 24581.44,
  },
  {
    id: "item-23",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 165285.11,
  },
  {
    id: "item-24",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 38044.97,
  },
  {
    id: "item-25",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 108824.36,
  },
  {
    id: "item-26",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 177093.64,
  },
  {
    id: "item-27",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 201512.95,
  },
  {
    id: "item-28",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 85888.03,
  },
  {
    id: "item-29",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 10148.66,
  },
  {
    id: "item-30",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 163534.18,
  },
  {
    id: "item-31",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 43668.01,
  },
  {
    id: "item-32",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 41246.74,
  },
  {
    id: "item-33",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 72232.71,
  },
  {
    id: "item-34",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 22093.46,
  },
  {
    id: "item-35",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 127745.5,
  },
  {
    id: "item-36",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 154632.7,
  },
  {
    id: "item-37",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 37352.73,
  },
  {
    id: "item-38",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 54198.76,
  },
  {
    id: "item-39",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 242301.61,
  },
  {
    id: "item-40",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 185777.38,
  },
  {
    id: "item-41",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 192515.99,
  },
  {
    id: "item-42",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 59809.81,
  },
  {
    id: "item-43",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 142347.25,
  },
  {
    id: "item-44",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 256656.28,
  },
  {
    id: "item-45",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 113974.87,
  },
  {
    id: "item-46",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 142256.77,
  },
  {
    id: "item-47",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 198961.53,
  },
  {
    id: "item-48",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 157429.63,
  },
  {
    id: "item-49",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 265863.94,
  },
  {
    id: "item-50",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 104927.76,
  },
  {
    id: "item-51",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 102084.78,
  },
  {
    id: "item-52",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 203753.49,
  },
  {
    id: "item-53",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 55228.3,
  },
  {
    id: "item-54",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 129666.57,
  },
  {
    id: "item-55",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 146395.3,
  },
  {
    id: "item-56",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 135510.89,
  },
  {
    id: "item-57",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 56819.33,
  },
  {
    id: "item-58",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 218096.8,
  },
  {
    id: "item-59",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 275850.9,
  },
  {
    id: "item-60",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 196833.07,
  },
  {
    id: "item-61",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 234218.48,
  },
  {
    id: "item-62",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 20755.89,
  },
  {
    id: "item-63",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 271575.79,
  },
  {
    id: "item-64",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 89926.71,
  },
  {
    id: "item-65",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 150959.98,
  },
  {
    id: "item-66",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 170633.44,
  },
  {
    id: "item-67",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 177641.87,
  },
  {
    id: "item-68",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 212228.63,
  },
  {
    id: "item-69",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 172614.71,
  },
  {
    id: "item-70",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 190542.04,
  },
  {
    id: "item-71",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 211739.97,
  },
  {
    id: "item-72",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 196865.35,
  },
  {
    id: "item-73",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 247841.37,
  },
  {
    id: "item-74",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 205714.02,
  },
  {
    id: "item-75",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 351696.76,
  },
  {
    id: "item-76",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 176069.2,
  },
  {
    id: "item-77",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 25413.44,
  },
  {
    id: "item-78",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 88566.18,
  },
  {
    id: "item-79",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 106134.02,
  },
  {
    id: "item-80",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 70711.94,
  },
  {
    id: "item-81",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 114480.34,
  },
  {
    id: "item-82",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 29072.16,
  },
  {
    id: "item-83",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 473483.02,
  },
  {
    id: "item-84",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 374952.59,
  },
  {
    id: "item-85",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 296432.89,
  },
  {
    id: "item-86",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 344358.04,
  },
  {
    id: "item-87",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 500275.29,
  },
  {
    id: "item-88",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 188892.01,
  },
  {
    id: "item-89",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 364921.53,
  },
  {
    id: "item-90",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 201430.93,
  },
  {
    id: "item-91",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 452385.65,
  },
  {
    id: "item-92",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 541051.87,
  },
  {
    id: "item-93",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 41913.78,
  },
  {
    id: "item-94",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 305806.67,
  },
  {
    id: "item-95",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 307527.29,
  },
  {
    id: "item-96",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 652464.71,
  },
  {
    id: "item-97",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 985733.82,
  },
  {
    id: "item-98",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 34988.37,
  },
  {
    id: "item-99",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation d’électricité de source renouvelable",
    labelEN: "Renewable energy electricity consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 2389698.21,
  },
]
