import { DataPointDemo } from "@kiosk/front/api/dashboard/types"

export const Environment13: DataPointDemo[] = [
  {
    id: "item-0",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 218.84,
  },
  {
    id: "item-1",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 190.61,
  },
  {
    id: "item-2",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 234.17,
  },
  {
    id: "item-3",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 132.68,
  },
  {
    id: "item-4",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 29.93,
  },
  {
    id: "item-5",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 40.95,
  },
  {
    id: "item-6",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 57.97,
  },
  {
    id: "item-7",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 238.7,
  },
  {
    id: "item-8",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 172.55,
  },
  {
    id: "item-9",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 253.67,
  },
  {
    id: "item-10",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 181.3,
  },
  {
    id: "item-11",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 197.87,
  },
  {
    id: "item-12",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 242.32,
  },
  {
    id: "item-13",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 171.96,
  },
  {
    id: "item-14",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 192.81,
  },
  {
    id: "item-15",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 210.59,
  },
  {
    id: "item-16",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 203.14,
  },
  {
    id: "item-17",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 68.58,
  },
  {
    id: "item-18",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 44.18,
  },
  {
    id: "item-19",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 139.16,
  },
  {
    id: "item-20",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 190.65,
  },
  {
    id: "item-21",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 5.8,
  },
  {
    id: "item-22",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 195.89,
  },
  {
    id: "item-23",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 207.76,
  },
  {
    id: "item-24",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 42,
  },
  {
    id: "item-25",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 268.85,
  },
  {
    id: "item-26",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 227.09,
  },
  {
    id: "item-27",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 114.44,
  },
  {
    id: "item-28",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 52.15,
  },
  {
    id: "item-29",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 81.43,
  },
  {
    id: "item-30",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 62.22,
  },
  {
    id: "item-31",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 87.46,
  },
  {
    id: "item-32",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 156.11,
  },
  {
    id: "item-33",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 178.28,
  },
  {
    id: "item-34",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 110.27,
  },
  {
    id: "item-35",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 221.06,
  },
  {
    id: "item-36",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 142.38,
  },
  {
    id: "item-37",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 189.38,
  },
  {
    id: "item-38",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 219.67,
  },
  {
    id: "item-39",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 22.58,
  },
  {
    id: "item-40",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 175.52,
  },
  {
    id: "item-41",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 227.99,
  },
  {
    id: "item-42",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 244.92,
  },
  {
    id: "item-43",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 86.92,
  },
  {
    id: "item-44",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 188.11,
  },
  {
    id: "item-45",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 248.31,
  },
  {
    id: "item-46",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 85.11,
  },
  {
    id: "item-47",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 276.04,
  },
  {
    id: "item-48",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 315.23,
  },
  {
    id: "item-49",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 21.33,
  },
  {
    id: "item-50",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 289.32,
  },
  {
    id: "item-51",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 53.61,
  },
  {
    id: "item-52",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 31.91,
  },
  {
    id: "item-53",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 20.47,
  },
  {
    id: "item-54",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 350.55,
  },
  {
    id: "item-55",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 48.51,
  },
  {
    id: "item-56",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 10.88,
  },
  {
    id: "item-57",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 130.81,
  },
  {
    id: "item-58",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 181.21,
  },
  {
    id: "item-59",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 90.03,
  },
  {
    id: "item-60",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 329.6,
  },
  {
    id: "item-61",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 342.44,
  },
  {
    id: "item-62",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 198.48,
  },
  {
    id: "item-63",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 119.62,
  },
  {
    id: "item-64",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 57.2,
  },
  {
    id: "item-65",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 37.98,
  },
  {
    id: "item-66",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 336.48,
  },
  {
    id: "item-67",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 174.69,
  },
  {
    id: "item-68",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 140.12,
  },
  {
    id: "item-69",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 46.67,
  },
  {
    id: "item-70",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 267.58,
  },
  {
    id: "item-71",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 371.37,
  },
  {
    id: "item-72",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 179.44,
  },
  {
    id: "item-73",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 95.99,
  },
  {
    id: "item-74",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 256.86,
  },
  {
    id: "item-75",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 477.57,
  },
  {
    id: "item-76",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 29.39,
  },
  {
    id: "item-77",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 290.13,
  },
  {
    id: "item-78",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 256.58,
  },
  {
    id: "item-79",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 179.78,
  },
  {
    id: "item-80",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 377.87,
  },
  {
    id: "item-81",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 28.94,
  },
  {
    id: "item-82",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 313.1,
  },
  {
    id: "item-83",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 609.56,
  },
  {
    id: "item-84",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 150.01,
  },
  {
    id: "item-85",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 396.27,
  },
  {
    id: "item-86",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 99.43,
  },
  {
    id: "item-87",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 332.78,
  },
  {
    id: "item-88",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 363.09,
  },
  {
    id: "item-89",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 270.68,
  },
  {
    id: "item-90",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 270.31,
  },
  {
    id: "item-91",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 181.17,
  },
  {
    id: "item-92",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 565.45,
  },
  {
    id: "item-93",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 718.57,
  },
  {
    id: "item-94",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 550.31,
  },
  {
    id: "item-95",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 727.32,
  },
  {
    id: "item-96",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 447.56,
  },
  {
    id: "item-97",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 1311.01,
  },
  {
    id: "item-98",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 801.33,
  },
  {
    id: "item-99",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Quantité de déchets générés",
    labelEN: "Quantity of waste generated",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 2673.04,
  },
]
