import { showNotification } from "@mantine/notifications"
import { UseMutationResult, useMutation } from "@tanstack/react-query"
import { AxiosResponse } from "axios"
import { noop } from "lodash"
import { useTranslation } from "react-i18next"

import { DisclosureRequirementParagraph } from "@kiosk/types/prisma-client"

export const useAIGenerateDisclosureRequirementParagraphMutationMock =
  (actions: {
    onCancel: () => void
    onSuccess: (data: DisclosureRequirementParagraph) => void
  }): UseMutationResult<
    DisclosureRequirementParagraph,
    Error,
    string,
    unknown
  > => {
    const { t } = useTranslation(["csrd"])

    return useMutation({
      mutationFn: async (disclosureRequirementId: string) => {
        noop(disclosureRequirementId)
        const data: AxiosResponse<DisclosureRequirementParagraph> =
          await new Promise((resolve, _) => {
            setTimeout(() => {
              resolve({
                data: {},
              } as AxiosResponse<DisclosureRequirementParagraph>)
            }, 10000)
          })

        return data.data
      },
      onSuccess: async (data: DisclosureRequirementParagraph) => {
        noop(data)
        showNotification({
          message: t("aiGeneration.generated"),
        })
      },
      onError: () => {
        showNotification({ color: "red", message: t("aiGeneration.error") })
        actions.onCancel()
      },
    })
  }
