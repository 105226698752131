import { i18n } from "@kiosk/i18n/index"
import { notifications } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"
import _ from "lodash"

import { CSRDQuestionAnswer } from "@kiosk/types/csrd"
import { ReportsResponses } from "@kiosk/types/endpoints/reports"

import { reportsKeys } from "@kiosk/front/api/reports/reportsKey"
import { formatCsrdReportAnswerFormKey } from "@kiosk/front/components/csrd/utils"
import { config } from "@kiosk/front/config"
import { apiClient } from "@kiosk/front/lib/apiClient"
import { queryClient } from "@kiosk/front/lib/queryClient"
import { downloadFile } from "@kiosk/front/utils/files"

export const useDisclosureRequirementAnswersQuery = (
  disclosureRequirementId: string,
) => {
  return useQuery<Record<string, CSRDQuestionAnswer>>({
    queryKey: reportsKeys.getDisclosureRequirementAnswers(
      disclosureRequirementId,
    ),
    queryFn: async () => {
      const response =
        await apiClient.get<ReportsResponses.GetDisclosureRequirementAnswersResponse>(
          `/reports/${disclosureRequirementId}`,
        )

      return _.keyBy(
        response.data.map((answer) => ({
          datoQuestionId: answer.datoQuestionId,
          answer: answer.answer,
          dimensions: answer.dimensions,
        })),
        (answer) =>
          formatCsrdReportAnswerFormKey(
            answer.datoQuestionId,
            answer.dimensions,
          ),
      )
    },
  })
}

export const useMaterialityQuery = () => {
  return useQuery<string[]>({
    queryKey: reportsKeys.getMateriality(),
    queryFn: async () => {
      const response =
        await apiClient.get<ReportsResponses.MaterialityResponse>(
          "/reports/non-material-esrs",
        )

      if ("data" in response.data) {
        return response.data.data.non_material_topics
      }

      return []
    },
  })
}

export const useMaterialityMutation = () => {
  return useMutation({
    mutationFn: async (esrsId: string) => {
      const response = await apiClient.post("/reports/switch-materiality", {
        esrsId,
      })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: reportsKeys.getMateriality(),
      })
      queryClient.invalidateQueries({
        queryKey: reportsKeys.getDisclosureRequirementAnswers(
          config.disclosureRequirements.iro2,
        ),
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: i18n.t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}

export const useMaterialitiesMutation = () => {
  return useMutation({
    mutationFn: async (esrsIds: string[]) => {
      await Promise.all(
        esrsIds.map((esrsId) =>
          apiClient.post("/reports/switch-materiality", {
            esrsId,
          }),
        ),
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: reportsKeys.getMateriality(),
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: i18n.t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}

export const useUpsertDisclosureRequirementAnswersMutation = (
  disclosureRequirementId: string,
) => {
  return useMutation({
    mutationFn: async (dataPoints: CSRDQuestionAnswer[]) => {
      const response = await apiClient.post(
        `/reports/${disclosureRequirementId}`,
        dataPoints,
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: reportsKeys.getDisclosureRequirementAnswers(
          disclosureRequirementId,
        ),
      })
      queryClient.invalidateQueries({
        queryKey: reportsKeys.getCategories(),
      })
      notifications.show({
        title: i18n.t("common:messages.success"),
        message: i18n.t("common:messages.success"),
        color: "green",
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: i18n.t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}

export const useGenerateXBRLMutation = () => {
  return useMutation({
    mutationFn: async () => {
      const response =
        await apiClient.get<ReportsResponses.GetGenerate>("/reports/generate")
      return response.data
    },
    onSuccess: (data) => {
      downloadFile(
        data.xbrlContent,
        "xbrl-report.xhtml",
        "application/xhtml+xml",
      )
    },
    onError: ({ message }) => {
      notifications.show({
        title: i18n.t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}

export const useDisclosureRequirementCategoriesQuery = () => {
  return useQuery({
    queryKey: reportsKeys.getCategories(),
    queryFn: async () => {
      const response = await apiClient.get<ReportsResponses.GetCategories>(
        "/reports/categories",
      )

      return response.data
    },
  })
}

export const useMarkDisclosureRequirementAsDoneMutation = () => {
  return useMutation({
    mutationFn: async (disclosureRequirementId: string) => {
      return await apiClient.post(
        `/reports/${disclosureRequirementId}/mark-as-done`,
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: reportsKeys.getCategories(),
      })
      notifications.show({
        title: i18n.t("common:messages.success"),
        message: i18n.t("common:messages.success"),
        color: "green",
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: i18n.t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}
