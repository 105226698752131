import { Group, Pill, Stack, Text } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import _ from "lodash"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { CSRDQuestions, Content, CardRecordType } from "@kiosk/types/data/dato"

import { CSRDQuestionContent } from "@kiosk/front/components/csrd/CSRDQuestionContent"
import { QuestionTooltip } from "@kiosk/front/components/csrd/QuestionTooltip"
import {
  CardType,
  RelatedQuestionsCard,
} from "@kiosk/front/components/csrd/RelatedQuestionsCard"
import { RelatedQuestionsTable } from "@kiosk/front/components/csrd/RelatedQuestionsTable"
import { RelatedQuestionsTree } from "@kiosk/front/components/csrd/RelatedQuestionsTree"
import { RelatedQuestionsWrapper } from "@kiosk/front/components/csrd/RelatedQuestionsWrapper"
import { FormShape } from "@kiosk/front/components/csrd/types"
import {
  formatCsrdReportAnswerFormKey,
  getDimensionBreakdownParentQuestion,
} from "@kiosk/front/components/csrd/utils"
import { DimensionsBreakdownSelect } from "@kiosk/front/components/dimensions/DimensionsBreakdownSelect"
import { EnrichedDimensionBreakdown } from "@kiosk/front/utils/dimensions"

type QuestionTreeProps = {
  question: CSRDQuestions<Content>
  form: UseFormReturnType<FormShape>
  breakdown?: EnrichedDimensionBreakdown
}

const cardTypeToName: Record<CardRecordType, CardType> = {
  ActionCardRecord: "Action",
  PolicyCardRecord: "Policy",
  ResultsCardRecord: "Metric",
  TargetCardRecord: "Target",
}

export const CSRDQuestionTree = ({
  question,
  breakdown,
  form,
}: QuestionTreeProps) => {
  const { t } = useTranslation("csrd")

  const [dimensionBreakdowns, setDimensionBreakdowns] = useState<
    EnrichedDimensionBreakdown[]
  >([])

  const formInputKey = useMemo(
    () =>
      formatCsrdReportAnswerFormKey(
        question.id,
        breakdown?.map((d) => ({
          dimensionId: d.dimensionId,
          optionId: d.optionId,
        })),
      ),
    [breakdown, question.id],
  )

  const initialBreakdowns = useMemo(
    () => getDimensionBreakdownParentQuestion(question, form.values),
    [form.values, question],
  )

  // TODO: this complex hook demonstrate why this should be better to have conditional follow up questions stored in `question.relationQuestions` instead of its own field
  const relatedQuestions = useMemo(() => {
    const questionType = question.content?.__typename
    if (questionType === "ConditionalAnswerRecord") {
      const { followUpTriggerAnswer, followUpQuestions } = question.content
      const conditionalAnswer = _.get(form.getValues(), formInputKey)
      const isMatchingCondition =
        conditionalAnswer?.answer.value === followUpTriggerAnswer

      if (isMatchingCondition)
        return followUpQuestions.concat(question.relatedQuestions ?? [])
    }
    return question.relatedQuestions ?? []
  }, [form, formInputKey, question.content, question.relatedQuestions])

  const isTable = question.content?.__typename === "TableRecord"
  const isCard = question.content?.__typename === "CardRecord"
  const hasDimensionBreakdowns = !_.isEmpty(dimensionBreakdowns)

  return (
    <Stack px={24} gap={12}>
      <Stack gap={8}>
        <Group gap={8}>
          <Text fw={500}>{question.label}</Text>
          {question.hint && <QuestionTooltip content={question.hint} />}
        </Group>
        {question.content && (
          <CSRDQuestionContent
            datoQuestionId={question.id}
            content={question.content}
            breakdown={breakdown}
            formProps={form.getInputProps(formInputKey)}
          />
        )}
      </Stack>
      {relatedQuestions.length > 0 ? (
        question.dimensions?.length ? (
          <Stack>
            <DimensionsBreakdownSelect
              initialBreakdowns={initialBreakdowns}
              onGenerateBreakdowns={setDimensionBreakdowns}
              dimensionIds={question.dimensions.map((d) => d.id)}
            />
            {isTable && hasDimensionBreakdowns ? (
              <RelatedQuestionsTable
                relatedQuestions={relatedQuestions}
                dimensionBreakdowns={dimensionBreakdowns}
                form={form}
                title={question.label}
              />
            ) : isCard && hasDimensionBreakdowns ? (
              <RelatedQuestionsCard
                relatedQuestions={relatedQuestions}
                dimensionBreakdowns={dimensionBreakdowns}
                form={form}
                title={question.label}
                type={
                  (question.content.__typename === "CardRecord" &&
                    cardTypeToName[question.content.cardType.__typename]) ||
                  "Policy"
                } // TODO
              />
            ) : (
              dimensionBreakdowns.map((breakdown, index) => (
                <Stack
                  key={breakdown
                    .map((breakdownItem) => breakdownItem.dimensionId)
                    .join("-")}
                  bg="gray.0"
                  style={{
                    border: "1px solid var(--mantine-color-gray-2)",
                    borderRadius: 6,
                  }}
                >
                  <Stack p="md" gap="sm">
                    <Group justify="space-between">
                      <Text fw={400} size="md">
                        {t("breakdown.title")}
                      </Text>
                      <Text fw={400} size="md">
                        {index + 1} / {dimensionBreakdowns.length}
                      </Text>
                    </Group>
                    <Group gap="xs">
                      {breakdown.map((breakdownItem) => (
                        <Pill key={breakdownItem.optionId}>
                          {breakdownItem.optionLabel}
                        </Pill>
                      ))}
                    </Group>
                  </Stack>
                  <RelatedQuestionsTree
                    relatedQuestions={relatedQuestions}
                    breakdown={breakdown}
                    form={form}
                  />
                </Stack>
              ))
            )}
          </Stack>
        ) : (
          <RelatedQuestionsWrapper>
            {relatedQuestions.map((relatedQuestion) => (
              <CSRDQuestionTree
                key={relatedQuestion.id}
                question={relatedQuestion}
                breakdown={breakdown}
                form={form}
              />
            ))}
          </RelatedQuestionsWrapper>
        )
      ) : null}
    </Stack>
  )
}
