import { DataPointDemo } from "@kiosk/front/api/dashboard/types"

export const Environment6: DataPointDemo[] = [
  {
    id: "item-0",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 618162.54,
  },
  {
    id: "item-1",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 465384.48,
  },
  {
    id: "item-2",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 581680.18,
  },
  {
    id: "item-3",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 296515.75,
  },
  {
    id: "item-4",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 723657.5,
  },
  {
    id: "item-5",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 457127.01,
  },
  {
    id: "item-6",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 1117686.6,
  },
  {
    id: "item-7",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 384316.31,
  },
  {
    id: "item-8",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 413484.3,
  },
  {
    id: "item-9",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 513861.35,
  },
  {
    id: "item-10",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 635783.86,
  },
  {
    id: "item-11",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 79984.38,
  },
  {
    id: "item-12",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 536759.37,
  },
  {
    id: "item-13",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 22668.3,
  },
  {
    id: "item-14",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 556697.77,
  },
  {
    id: "item-15",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 414816.24,
  },
  {
    id: "item-16",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 221455.2,
  },
  {
    id: "item-17",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 420298.44,
  },
  {
    id: "item-18",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 794274.65,
  },
  {
    id: "item-19",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 143335.73,
  },
  {
    id: "item-20",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 997417.12,
  },
  {
    id: "item-21",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 814873.67,
  },
  {
    id: "item-22",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 1164011.68,
  },
  {
    id: "item-23",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 10671.55,
  },
  {
    id: "item-24",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 606829.45,
  },
  {
    id: "item-25",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 863065.3,
  },
  {
    id: "item-26",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 945204.16,
  },
  {
    id: "item-27",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 799170.97,
  },
  {
    id: "item-28",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 311371.01,
  },
  {
    id: "item-29",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 1298923.18,
  },
  {
    id: "item-30",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 130476.34,
  },
  {
    id: "item-31",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 914908.66,
  },
  {
    id: "item-32",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 661435.19,
  },
  {
    id: "item-33",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 1419371.33,
  },
  {
    id: "item-34",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 282149.26,
  },
  {
    id: "item-35",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 84461.74,
  },
  {
    id: "item-36",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 1569370.92,
  },
  {
    id: "item-37",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 1416430.8,
  },
  {
    id: "item-38",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 713987.21,
  },
  {
    id: "item-39",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 1643500.76,
  },
  {
    id: "item-40",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 539961.45,
  },
  {
    id: "item-41",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 284151.61,
  },
  {
    id: "item-42",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 1113042.26,
  },
  {
    id: "item-43",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 849132.01,
  },
  {
    id: "item-44",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 1004306.1,
  },
  {
    id: "item-45",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 850849.4,
  },
  {
    id: "item-46",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 1045208.9,
  },
  {
    id: "item-47",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 994406.07,
  },
  {
    id: "item-48",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 1707454.99,
  },
  {
    id: "item-49",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 1048286.19,
  },
  {
    id: "item-50",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 615045.83,
  },
  {
    id: "item-51",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 1099478.89,
  },
  {
    id: "item-52",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 283095,
  },
  {
    id: "item-53",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 505779.29,
  },
  {
    id: "item-54",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 1015193.37,
  },
  {
    id: "item-55",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 1027468.96,
  },
  {
    id: "item-56",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 338928.86,
  },
  {
    id: "item-57",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 2038333.05,
  },
  {
    id: "item-58",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 1286220.33,
  },
  {
    id: "item-59",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 1262113.61,
  },
  {
    id: "item-60",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 340953.51,
  },
  {
    id: "item-61",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 520829.2,
  },
  {
    id: "item-62",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 589069.29,
  },
  {
    id: "item-63",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 1136247.06,
  },
  {
    id: "item-64",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 2052480.55,
  },
  {
    id: "item-65",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 1358675.03,
  },
  {
    id: "item-66",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 2173964.55,
  },
  {
    id: "item-67",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 1426561.85,
  },
  {
    id: "item-68",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 1127535.08,
  },
  {
    id: "item-69",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 5438.06,
  },
  {
    id: "item-70",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 1654639.19,
  },
  {
    id: "item-71",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 275612.44,
  },
  {
    id: "item-72",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 941129.7,
  },
  {
    id: "item-73",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 1201046.73,
  },
  {
    id: "item-74",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 942762.54,
  },
  {
    id: "item-75",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 2144157.92,
  },
  {
    id: "item-76",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 754867.48,
  },
  {
    id: "item-77",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 216966.41,
  },
  {
    id: "item-78",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 920115.83,
  },
  {
    id: "item-79",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 1669176.65,
  },
  {
    id: "item-80",
    dimensionId: 2,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 2640694.51,
  },
  {
    id: "item-81",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 2800449.87,
  },
  {
    id: "item-82",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 1687419.15,
  },
  {
    id: "item-83",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 1806460.35,
  },
  {
    id: "item-84",
    dimensionId: 9,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 759956.87,
  },
  {
    id: "item-85",
    dimensionId: 4,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 490329.85,
  },
  {
    id: "item-86",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 1092055.05,
  },
  {
    id: "item-87",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 1064044.63,
  },
  {
    id: "item-88",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 348991.87,
  },
  {
    id: "item-89",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 3929449.3,
  },
  {
    id: "item-90",
    dimensionId: 6,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 1136527.7,
  },
  {
    id: "item-91",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 1050408.56,
  },
  {
    id: "item-92",
    dimensionId: 7,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 5185892.49,
  },
  {
    id: "item-93",
    dimensionId: 3,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 258568.39,
  },
  {
    id: "item-94",
    dimensionId: 5,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 960805.22,
  },
  {
    id: "item-95",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kWh",
    value: 782490.53,
  },
  {
    id: "item-96",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 3340970.92,
  },
  {
    id: "item-97",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 3003877.1,
  },
  {
    id: "item-98",
    dimensionId: 1,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kWh",
    value: 5038128.99,
  },
  {
    id: "item-99",
    dimensionId: 8,
    info: "+ 10% vs T3",
    isMandatoryInDashboard: true,
    labelFR: "Consommation totale d’énergie",
    labelEN: "Total energy consumption",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kWh",
    value: 26678418.15,
  },
]
