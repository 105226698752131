import { Button, Group, MantineStyleProp } from "@mantine/core"
import {
  IconCheck,
  IconCircleCheck,
  IconRefresh,
  IconRotateClockwise,
  IconSparkles,
} from "@tabler/icons-react"
import { useTranslation } from "react-i18next"

import { colors } from "@kiosk/front/assets/theme/colors"

import "./SaveFooter.css"
import { SparklingButton } from "../generic/SparklingButton"

const stickyBottomStyle: MantineStyleProp = {
  position: "fixed",
  bottom: 0,
  width: "51%",
  background: colors.gray[0],
  borderTop: "solid",
  borderTopWidth: 1,
  borderTopColor: colors.gray[2],
  zIndex: 1,
}

// TODO: translations

interface SaveFooterProps {
  hasUnsavedChanges: boolean
  isPendingUpdate: boolean
  isPendingGeneration: boolean
  onMarkAsDone: () => void
  onCancel: () => void
  onSave: () => void
  onGenerate: () => void
  isCompleted: boolean
}

export const SaveFooter = (props: SaveFooterProps) => {
  const { t } = useTranslation(["common", "csrd"])

  return (
    <Group
      justify="space-between"
      style={stickyBottomStyle}
      pt={12}
      pb={12}
      pr={26}
      pl={26}
    >
      <Group gap={20}>
        <Button onClick={props.onCancel} color={colors.gray[2]} c="black">
          {t("saveFooter.cancel")}
        </Button>
        <Button
          onClick={props.onMarkAsDone}
          variant="outline"
          color="white"
          c="black"
          style={{ border: `${colors.gray[3]} 1px solid` }}
        >
          <Group justify="space-between" gap={8}>
            <IconCircleCheck color={colors.emerald[5]} />
            {t("saveFooter.markAsDone")}
          </Group>
        </Button>
      </Group>
      <Group gap={20}>
        {props.hasUnsavedChanges ? (
          <Group c={colors.gray[4]} gap={8}>
            <IconRefresh size={22} />
            {t("saveFooter.unsavedChanges")}
          </Group>
        ) : props.isPendingUpdate ? (
          <Group c={colors.gray[6]} gap={8}>
            <IconRotateClockwise
              className="rotating"
              color={colors.emerald[5]}
              size={22}
            />
            {t("saveFooter.savingChanges")}
          </Group>
        ) : (
          <Group c={colors.gray[6]} gap={8}>
            <IconCheck color={colors.emerald[5]} size={22} />
            {t("saveFooter.changesSaved")}{" "}
          </Group>
        )}
        <Button
          type="submit"
          pl={40}
          pr={40}
          onClick={props.onSave}
          disabled={props.isPendingUpdate}
        >
          {t("saveFooter.save")}
        </Button>
        <SparklingButton
          disabled={!props.isCompleted}
          loadingLabel={t("csrd:aiGeneration.loading")}
          icon={<IconSparkles />}
          onPress={props.onGenerate}
          loading={props.isPendingGeneration}
        >
          {t("csrd:aiGeneration.generate")}
        </SparklingButton>
      </Group>
    </Group>
  )
}
