import { i18n } from "@kiosk/i18n"

export const roles = {
  KIOSK_ADMIN: i18n.t("roles.kioskAdmin"),
  COMPANY_ADMIN: i18n.t("roles.companyAdmin"),
  COMPANY_CONTRIBUTOR: i18n.t("roles.companyContributor"),
  COMPANY_VIEWER: i18n.t("roles.companyViewer"),
  COMPANY_AUDITOR: i18n.t("roles.companyAuditor"),
  COMPANY_VERIFIER: i18n.t("roles.companyVerifier"),
}

export const companyStep = {
  ACCOUNT_SETUP: 0,
  GEO_STRUCTURE: 1,
  FIRST_UPLOAD: 2,
  ONBOARDED: 3,
}
