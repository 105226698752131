export const DIMENSIONS = [
  {
    id: "1",
    country: "italia",
    business_unit: "tech",
  },
  {
    id: "2",
    country: "italia",
    business_unit: "hr",
  },
  {
    id: "3",
    country: "italia",
    business_unit: "operations",
  },
  {
    id: "4",
    country: "spain",
    business_unit: "tech",
  },
  {
    id: "5",
    country: "spain",
    business_unit: "hr",
  },
  {
    id: "6",
    country: "spain",
    business_unit: "operations",
  },
  {
    id: "7",
    country: "france",
    business_unit: "tech",
  },
  {
    id: "8",
    country: "france",
    business_unit: "hr",
  },
  {
    id: "9",
    country: "france",
    business_unit: "operations",
  },
]
