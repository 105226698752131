import {
  Group,
  parseThemeColor,
  Text,
  ThemeIcon,
  useMantineTheme,
} from "@mantine/core"
import { IconQuestionMark } from "@tabler/icons-react"

import { TablerIconComponent } from "@kiosk/front/utils/icon"

type Props = {
  icon?: TablerIconComponent
  iconColor?: string
  iconBackgroundColor?: string
  name?: string
}

// TODO: add icons to each topic in Dato
//   How ?
//   - Add all icons as svg/img in the assets folder
//   - Add a field in the topic model referencing a tabler icon (ex: Topic.icon = "IconHome", then do a dynamic import from @tabler/icons-react)
export const TopicTitleDemo = ({
  iconColor,
  iconBackgroundColor,
  icon: Icon,
  name,
}: Props) => {
  const theme = useMantineTheme()
  const iconParsedColor = parseThemeColor({ color: iconColor, theme })
  const parsedColor = parseThemeColor({ color: iconBackgroundColor, theme })

  return (
    <Group gap={16}>
      <ThemeIcon
        bg={parsedColor.value}
        style={{ width: "40px", height: "40px" }}
        variant="light"
      >
        {Icon ? (
          <Icon
            color={iconParsedColor.value}
            style={{ width: "24px", height: "24px" }}
          />
        ) : (
          <IconQuestionMark />
        )}
      </ThemeIcon>

      <Text c="gray.6" fw={600} size="xl">
        {name}
      </Text>
    </Group>
  )
}
