import { DataPointDemo } from "@kiosk/front/api/dashboard/types"

export const Social9: DataPointDemo[] = [
  {
    id: "item-0",
    dimensionId: 3,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.05,
  },
  {
    id: "item-1",
    dimensionId: 8,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.1,
  },
  {
    id: "item-2",
    dimensionId: 7,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.09,
  },
  {
    id: "item-3",
    dimensionId: 6,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.11,
  },
  {
    id: "item-4",
    dimensionId: 5,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.1,
  },
  {
    id: "item-5",
    dimensionId: 7,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.04,
  },
  {
    id: "item-6",
    dimensionId: 6,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.18,
  },
  {
    id: "item-7",
    dimensionId: 9,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0,
  },
  {
    id: "item-8",
    dimensionId: 7,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.19,
  },
  {
    id: "item-9",
    dimensionId: 9,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.12,
  },
  {
    id: "item-10",
    dimensionId: 6,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.02,
  },
  {
    id: "item-11",
    dimensionId: 2,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.23,
  },
  {
    id: "item-12",
    dimensionId: 8,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.05,
  },
  {
    id: "item-13",
    dimensionId: 3,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.08,
  },
  {
    id: "item-14",
    dimensionId: 2,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.13,
  },
  {
    id: "item-15",
    dimensionId: 9,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.07,
  },
  {
    id: "item-16",
    dimensionId: 4,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.16,
  },
  {
    id: "item-17",
    dimensionId: 1,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.2,
  },
  {
    id: "item-18",
    dimensionId: 2,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.18,
  },
  {
    id: "item-19",
    dimensionId: 1,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.14,
  },
  {
    id: "item-20",
    dimensionId: 1,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.05,
  },
  {
    id: "item-21",
    dimensionId: 7,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.24,
  },
  {
    id: "item-22",
    dimensionId: 3,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.24,
  },
  {
    id: "item-23",
    dimensionId: 2,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.19,
  },
  {
    id: "item-24",
    dimensionId: 8,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.08,
  },
  {
    id: "item-25",
    dimensionId: 7,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.28,
  },
  {
    id: "item-26",
    dimensionId: 3,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.01,
  },
  {
    id: "item-27",
    dimensionId: 5,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.09,
  },
  {
    id: "item-28",
    dimensionId: 9,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.05,
  },
  {
    id: "item-29",
    dimensionId: 5,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.02,
  },
  {
    id: "item-30",
    dimensionId: 1,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.12,
  },
  {
    id: "item-31",
    dimensionId: 5,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.09,
  },
  {
    id: "item-32",
    dimensionId: 6,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.19,
  },
  {
    id: "item-33",
    dimensionId: 4,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.24,
  },
  {
    id: "item-34",
    dimensionId: 9,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.07,
  },
  {
    id: "item-35",
    dimensionId: 7,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.26,
  },
  {
    id: "item-36",
    dimensionId: 7,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.14,
  },
  {
    id: "item-37",
    dimensionId: 3,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.06,
  },
  {
    id: "item-38",
    dimensionId: 1,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.23,
  },
  {
    id: "item-39",
    dimensionId: 7,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.29,
  },
  {
    id: "item-40",
    dimensionId: 1,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.3,
  },
  {
    id: "item-41",
    dimensionId: 3,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.18,
  },
  {
    id: "item-42",
    dimensionId: 4,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.1,
  },
  {
    id: "item-43",
    dimensionId: 1,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.11,
  },
  {
    id: "item-44",
    dimensionId: 9,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.13,
  },
  {
    id: "item-45",
    dimensionId: 4,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.21,
  },
  {
    id: "item-46",
    dimensionId: 2,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.11,
  },
  {
    id: "item-47",
    dimensionId: 4,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.32,
  },
  {
    id: "item-48",
    dimensionId: 5,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.2,
  },
  {
    id: "item-49",
    dimensionId: 4,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.05,
  },
  {
    id: "item-50",
    dimensionId: 1,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.22,
  },
  {
    id: "item-51",
    dimensionId: 1,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.11,
  },
  {
    id: "item-52",
    dimensionId: 1,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.32,
  },
  {
    id: "item-53",
    dimensionId: 3,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.2,
  },
  {
    id: "item-54",
    dimensionId: 6,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.02,
  },
  {
    id: "item-55",
    dimensionId: 1,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.26,
  },
  {
    id: "item-56",
    dimensionId: 2,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.29,
  },
  {
    id: "item-57",
    dimensionId: 1,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.35,
  },
  {
    id: "item-58",
    dimensionId: 2,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.37,
  },
  {
    id: "item-59",
    dimensionId: 7,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.35,
  },
  {
    id: "item-60",
    dimensionId: 4,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.12,
  },
  {
    id: "item-61",
    dimensionId: 9,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.14,
  },
  {
    id: "item-62",
    dimensionId: 6,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.33,
  },
  {
    id: "item-63",
    dimensionId: 4,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.22,
  },
  {
    id: "item-64",
    dimensionId: 3,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.01,
  },
  {
    id: "item-65",
    dimensionId: 4,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.04,
  },
  {
    id: "item-66",
    dimensionId: 8,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.3,
  },
  {
    id: "item-67",
    dimensionId: 5,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.36,
  },
  {
    id: "item-68",
    dimensionId: 5,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.03,
  },
  {
    id: "item-69",
    dimensionId: 6,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.45,
  },
  {
    id: "item-70",
    dimensionId: 9,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.36,
  },
  {
    id: "item-71",
    dimensionId: 2,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.42,
  },
  {
    id: "item-72",
    dimensionId: 6,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0,
  },
  {
    id: "item-73",
    dimensionId: 6,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.17,
  },
  {
    id: "item-74",
    dimensionId: 3,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.08,
  },
  {
    id: "item-75",
    dimensionId: 7,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.26,
  },
  {
    id: "item-76",
    dimensionId: 2,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.39,
  },
  {
    id: "item-77",
    dimensionId: 6,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.33,
  },
  {
    id: "item-78",
    dimensionId: 2,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.44,
  },
  {
    id: "item-79",
    dimensionId: 1,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.17,
  },
  {
    id: "item-80",
    dimensionId: 8,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.17,
  },
  {
    id: "item-81",
    dimensionId: 7,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.08,
  },
  {
    id: "item-82",
    dimensionId: 7,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.06,
  },
  {
    id: "item-83",
    dimensionId: 7,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.21,
  },
  {
    id: "item-84",
    dimensionId: 1,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.23,
  },
  {
    id: "item-85",
    dimensionId: 4,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.52,
  },
  {
    id: "item-86",
    dimensionId: 2,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.32,
  },
  {
    id: "item-87",
    dimensionId: 8,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.25,
  },
  {
    id: "item-88",
    dimensionId: 7,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.29,
  },
  {
    id: "item-89",
    dimensionId: 8,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.58,
  },
  {
    id: "item-90",
    dimensionId: 2,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.05,
  },
  {
    id: "item-91",
    dimensionId: 4,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.24,
  },
  {
    id: "item-92",
    dimensionId: 1,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.37,
  },
  {
    id: "item-93",
    dimensionId: 4,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.03,
  },
  {
    id: "item-94",
    dimensionId: 9,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.83,
  },
  {
    id: "item-95",
    dimensionId: 9,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.43,
  },
  {
    id: "item-96",
    dimensionId: 4,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.35,
  },
  {
    id: "item-97",
    dimensionId: 4,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 1.94,
  },
  {
    id: "item-98",
    dimensionId: 2,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 1.58,
  },
  {
    id: "item-99",
    dimensionId: 1,
    info: "+ 11% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Heures de formation annuelles par salarié féminin",
    labelEN: "Annual training hours per female employee",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 2.47,
  },
]
