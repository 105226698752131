import { DataPointDemo } from "@kiosk/front/api/dashboard/types"

export const Social14: DataPointDemo[] = [
  {
    id: "item-0",
    dimensionId: 6,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 12.42,
  },
  {
    id: "item-1",
    dimensionId: 3,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 47.18,
  },
  {
    id: "item-2",
    dimensionId: 9,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 37.36,
  },
  {
    id: "item-3",
    dimensionId: 8,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 30.61,
  },
  {
    id: "item-4",
    dimensionId: 2,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 47.91,
  },
  {
    id: "item-5",
    dimensionId: 9,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 80.15,
  },
  {
    id: "item-6",
    dimensionId: 3,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 76.26,
  },
  {
    id: "item-7",
    dimensionId: 8,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 64.82,
  },
  {
    id: "item-8",
    dimensionId: 8,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 17.35,
  },
  {
    id: "item-9",
    dimensionId: 2,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 14.47,
  },
  {
    id: "item-10",
    dimensionId: 3,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 6.1,
  },
  {
    id: "item-11",
    dimensionId: 6,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 32.99,
  },
  {
    id: "item-12",
    dimensionId: 7,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 25.27,
  },
  {
    id: "item-13",
    dimensionId: 4,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 7.77,
  },
  {
    id: "item-14",
    dimensionId: 3,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 80.07,
  },
  {
    id: "item-15",
    dimensionId: 4,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 2.52,
  },
  {
    id: "item-16",
    dimensionId: 5,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 56.24,
  },
  {
    id: "item-17",
    dimensionId: 8,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 56.64,
  },
  {
    id: "item-18",
    dimensionId: 6,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 60.83,
  },
  {
    id: "item-19",
    dimensionId: 8,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 82.82,
  },
  {
    id: "item-20",
    dimensionId: 3,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 17.6,
  },
  {
    id: "item-21",
    dimensionId: 7,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 11.62,
  },
  {
    id: "item-22",
    dimensionId: 5,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 6.08,
  },
  {
    id: "item-23",
    dimensionId: 4,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 9.38,
  },
  {
    id: "item-24",
    dimensionId: 4,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 2.67,
  },
  {
    id: "item-25",
    dimensionId: 8,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 57.16,
  },
  {
    id: "item-26",
    dimensionId: 1,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 61.16,
  },
  {
    id: "item-27",
    dimensionId: 8,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 65.37,
  },
  {
    id: "item-28",
    dimensionId: 8,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 60.46,
  },
  {
    id: "item-29",
    dimensionId: 2,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 20.31,
  },
  {
    id: "item-30",
    dimensionId: 4,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 75.62,
  },
  {
    id: "item-31",
    dimensionId: 6,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 22.94,
  },
  {
    id: "item-32",
    dimensionId: 7,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 43.3,
  },
  {
    id: "item-33",
    dimensionId: 5,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 64.18,
  },
  {
    id: "item-34",
    dimensionId: 2,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 55.14,
  },
  {
    id: "item-35",
    dimensionId: 3,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 51.7,
  },
  {
    id: "item-36",
    dimensionId: 4,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 85.82,
  },
  {
    id: "item-37",
    dimensionId: 8,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 18.72,
  },
  {
    id: "item-38",
    dimensionId: 3,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 99.22,
  },
  {
    id: "item-39",
    dimensionId: 1,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 41.73,
  },
  {
    id: "item-40",
    dimensionId: 9,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 7.98,
  },
  {
    id: "item-41",
    dimensionId: 2,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 24.26,
  },
  {
    id: "item-42",
    dimensionId: 9,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 65.25,
  },
  {
    id: "item-43",
    dimensionId: 5,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 103.6,
  },
  {
    id: "item-44",
    dimensionId: 1,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 78.79,
  },
  {
    id: "item-45",
    dimensionId: 6,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 57.08,
  },
  {
    id: "item-46",
    dimensionId: 5,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 59.19,
  },
  {
    id: "item-47",
    dimensionId: 8,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 83.94,
  },
  {
    id: "item-48",
    dimensionId: 3,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 61.65,
  },
  {
    id: "item-49",
    dimensionId: 5,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 70.55,
  },
  {
    id: "item-50",
    dimensionId: 9,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 6.4,
  },
  {
    id: "item-51",
    dimensionId: 6,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 71.34,
  },
  {
    id: "item-52",
    dimensionId: 2,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 24.87,
  },
  {
    id: "item-53",
    dimensionId: 6,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 94.27,
  },
  {
    id: "item-54",
    dimensionId: 7,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 70.78,
  },
  {
    id: "item-55",
    dimensionId: 6,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 111.7,
  },
  {
    id: "item-56",
    dimensionId: 6,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 53.15,
  },
  {
    id: "item-57",
    dimensionId: 7,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 38.13,
  },
  {
    id: "item-58",
    dimensionId: 5,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 24.3,
  },
  {
    id: "item-59",
    dimensionId: 7,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 113.4,
  },
  {
    id: "item-60",
    dimensionId: 7,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 74.26,
  },
  {
    id: "item-61",
    dimensionId: 2,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 65.55,
  },
  {
    id: "item-62",
    dimensionId: 5,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 79.06,
  },
  {
    id: "item-63",
    dimensionId: 1,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 49.78,
  },
  {
    id: "item-64",
    dimensionId: 7,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 108.79,
  },
  {
    id: "item-65",
    dimensionId: 8,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 66.17,
  },
  {
    id: "item-66",
    dimensionId: 9,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 67.49,
  },
  {
    id: "item-67",
    dimensionId: 4,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 58.88,
  },
  {
    id: "item-68",
    dimensionId: 6,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 37.11,
  },
  {
    id: "item-69",
    dimensionId: 9,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 24.08,
  },
  {
    id: "item-70",
    dimensionId: 8,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 51.37,
  },
  {
    id: "item-71",
    dimensionId: 9,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 139.55,
  },
  {
    id: "item-72",
    dimensionId: 5,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 1.55,
  },
  {
    id: "item-73",
    dimensionId: 5,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 113.06,
  },
  {
    id: "item-74",
    dimensionId: 8,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 75.07,
  },
  {
    id: "item-75",
    dimensionId: 9,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 10.29,
  },
  {
    id: "item-76",
    dimensionId: 9,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 162.06,
  },
  {
    id: "item-77",
    dimensionId: 4,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 27.75,
  },
  {
    id: "item-78",
    dimensionId: 3,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 133.32,
  },
  {
    id: "item-79",
    dimensionId: 4,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 158.99,
  },
  {
    id: "item-80",
    dimensionId: 2,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 107,
  },
  {
    id: "item-81",
    dimensionId: 5,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 39.44,
  },
  {
    id: "item-82",
    dimensionId: 7,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 123.02,
  },
  {
    id: "item-83",
    dimensionId: 7,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 103.64,
  },
  {
    id: "item-84",
    dimensionId: 2,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 36.26,
  },
  {
    id: "item-85",
    dimensionId: 9,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 52.62,
  },
  {
    id: "item-86",
    dimensionId: 6,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 115.92,
  },
  {
    id: "item-87",
    dimensionId: 3,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 45.7,
  },
  {
    id: "item-88",
    dimensionId: 1,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 215.67,
  },
  {
    id: "item-89",
    dimensionId: 6,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 229.12,
  },
  {
    id: "item-90",
    dimensionId: 6,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 124.29,
  },
  {
    id: "item-91",
    dimensionId: 8,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 101.48,
  },
  {
    id: "item-92",
    dimensionId: 8,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 6.71,
  },
  {
    id: "item-93",
    dimensionId: 3,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 43.64,
  },
  {
    id: "item-94",
    dimensionId: 8,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 224.08,
  },
  {
    id: "item-95",
    dimensionId: 1,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 219.89,
  },
  {
    id: "item-96",
    dimensionId: 1,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "€",
    value: 341.62,
  },
  {
    id: "item-97",
    dimensionId: 3,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "€",
    value: 57.35,
  },
  {
    id: "item-98",
    dimensionId: 1,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 550.4,
  },
  {
    id: "item-99",
    dimensionId: 5,
    info: "+ 60% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Montant total des amendes",
    labelEN: "Total amount of fines",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "€",
    value: 683.38,
  },
]
