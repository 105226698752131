import { DataPointDemo } from "@kiosk/front/api/dashboard/types"

export const Environment3: DataPointDemo[] = [
  {
    id: "clm17ghq80000mbelzj3ubc6",
    dimensionId: 6,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq80001mbelzj3ubc6",
    dimensionId: 5,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq80002mbelzj3ubc6",
    dimensionId: 8,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq80003mbelzj3ubc6",
    dimensionId: 1,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq80004mbelzj3ubc6",
    dimensionId: 6,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq80005mbelzj3ubc6",
    dimensionId: 3,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq80006mbelzj3ubc6",
    dimensionId: 1,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq80007mbelzj3ubc6",
    dimensionId: 3,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq80008mbelzj3ubc6",
    dimensionId: 2,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq80009mbelzj3ubc6",
    dimensionId: 8,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800010mbelzj3ubc6",
    dimensionId: 5,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800011mbelzj3ubc6",
    dimensionId: 4,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800012mbelzj3ubc6",
    dimensionId: 7,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800013mbelzj3ubc6",
    dimensionId: 1,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800014mbelzj3ubc6",
    dimensionId: 1,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800015mbelzj3ubc6",
    dimensionId: 3,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800016mbelzj3ubc6",
    dimensionId: 5,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800017mbelzj3ubc6",
    dimensionId: 8,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800018mbelzj3ubc6",
    dimensionId: 4,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800019mbelzj3ubc6",
    dimensionId: 9,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800020mbelzj3ubc6",
    dimensionId: 9,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800021mbelzj3ubc6",
    dimensionId: 2,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800022mbelzj3ubc6",
    dimensionId: 1,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800023mbelzj3ubc6",
    dimensionId: 5,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800024mbelzj3ubc6",
    dimensionId: 4,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800025mbelzj3ubc6",
    dimensionId: 2,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800026mbelzj3ubc6",
    dimensionId: 1,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800027mbelzj3ubc6",
    dimensionId: 7,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800028mbelzj3ubc6",
    dimensionId: 9,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800029mbelzj3ubc6",
    dimensionId: 7,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800030mbelzj3ubc6",
    dimensionId: 2,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800031mbelzj3ubc6",
    dimensionId: 3,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800032mbelzj3ubc6",
    dimensionId: 3,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800033mbelzj3ubc6",
    dimensionId: 2,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800034mbelzj3ubc6",
    dimensionId: 7,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800035mbelzj3ubc6",
    dimensionId: 4,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800036mbelzj3ubc6",
    dimensionId: 4,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800037mbelzj3ubc6",
    dimensionId: 7,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800038mbelzj3ubc6",
    dimensionId: 6,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800039mbelzj3ubc6",
    dimensionId: 6,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800040mbelzj3ubc6",
    dimensionId: 5,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800041mbelzj3ubc6",
    dimensionId: 5,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800042mbelzj3ubc6",
    dimensionId: 3,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800043mbelzj3ubc6",
    dimensionId: 1,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800044mbelzj3ubc6",
    dimensionId: 8,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800045mbelzj3ubc6",
    dimensionId: 6,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800046mbelzj3ubc6",
    dimensionId: 6,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800047mbelzj3ubc6",
    dimensionId: 2,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800048mbelzj3ubc6",
    dimensionId: 3,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800049mbelzj3ubc6",
    dimensionId: 7,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800050mbelzj3ubc6",
    dimensionId: 4,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800051mbelzj3ubc6",
    dimensionId: 1,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800052mbelzj3ubc6",
    dimensionId: 5,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800053mbelzj3ubc6",
    dimensionId: 6,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800054mbelzj3ubc6",
    dimensionId: 5,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800055mbelzj3ubc6",
    dimensionId: 9,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800056mbelzj3ubc6",
    dimensionId: 7,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800057mbelzj3ubc6",
    dimensionId: 3,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800058mbelzj3ubc6",
    dimensionId: 1,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800059mbelzj3ubc6",
    dimensionId: 1,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800060mbelzj3ubc6",
    dimensionId: 5,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800061mbelzj3ubc6",
    dimensionId: 9,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800062mbelzj3ubc6",
    dimensionId: 6,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800063mbelzj3ubc6",
    dimensionId: 7,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800064mbelzj3ubc6",
    dimensionId: 1,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800065mbelzj3ubc6",
    dimensionId: 2,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800066mbelzj3ubc6",
    dimensionId: 3,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800067mbelzj3ubc6",
    dimensionId: 5,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800068mbelzj3ubc6",
    dimensionId: 8,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800069mbelzj3ubc6",
    dimensionId: 4,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800070mbelzj3ubc6",
    dimensionId: 5,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800071mbelzj3ubc6",
    dimensionId: 2,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800072mbelzj3ubc6",
    dimensionId: 2,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800073mbelzj3ubc6",
    dimensionId: 1,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800074mbelzj3ubc6",
    dimensionId: 4,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800075mbelzj3ubc6",
    dimensionId: 3,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800076mbelzj3ubc6",
    dimensionId: 6,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800077mbelzj3ubc6",
    dimensionId: 4,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800078mbelzj3ubc6",
    dimensionId: 4,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800079mbelzj3ubc6",
    dimensionId: 7,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800080mbelzj3ubc6",
    dimensionId: 5,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800081mbelzj3ubc6",
    dimensionId: 7,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800082mbelzj3ubc6",
    dimensionId: 2,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800083mbelzj3ubc6",
    dimensionId: 8,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800084mbelzj3ubc6",
    dimensionId: 7,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800085mbelzj3ubc6",
    dimensionId: 5,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800086mbelzj3ubc6",
    dimensionId: 7,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800087mbelzj3ubc6",
    dimensionId: 6,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800088mbelzj3ubc6",
    dimensionId: 1,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800089mbelzj3ubc6",
    dimensionId: 4,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800090mbelzj3ubc6",
    dimensionId: 6,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800091mbelzj3ubc6",
    dimensionId: 3,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800092mbelzj3ubc6",
    dimensionId: 9,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800093mbelzj3ubc6",
    dimensionId: 2,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800094mbelzj3ubc6",
    dimensionId: 8,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800095mbelzj3ubc6",
    dimensionId: 5,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800096mbelzj3ubc6",
    dimensionId: 6,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800097mbelzj3ubc6",
    dimensionId: 1,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq800098mbelzj3ubc6",
    dimensionId: 8,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
  {
    id: "clm17ghq8000lastmbelzj3ubc6",
    dimensionId: 7,
    isMandatoryInDashboard: true,
    labelFR: "Emissions de gaz à effet de serre par revenu",
    labelEN: "Greenhouse gas emissions per revenue",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 0.000002,
  },
]
