import { IconBuilding, IconLeaf, IconUsersGroup } from "@tabler/icons-react"

import { TablerIconComponent } from "@kiosk/front/utils/icon"

type DashboardStyleKey = "environment" | "social" | "governance"

export const DASHBOARD_STYLE: Record<
  DashboardStyleKey,
  {
    color: string
    borderColor: string
    icon: TablerIconComponent
    iconBackgroundColor: string
  }
> = {
  environment: {
    color: "emerald.0",
    borderColor: "emerald.2",
    icon: IconLeaf,
    iconBackgroundColor: "emerald.5",
  },
  social: {
    color: "yellow.0",
    borderColor: "yellow.2",
    icon: IconUsersGroup,
    iconBackgroundColor: "yellow.5",
  },
  governance: {
    color: "blue.0",
    borderColor: "blue.2",
    icon: IconBuilding,
    iconBackgroundColor: "blue.5",
  },
}
