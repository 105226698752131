import { Flex, Group, MantineStyleProp } from "@mantine/core"
import { IconSparkles } from "@tabler/icons-react"
import { useTranslation } from "react-i18next"

import { colors } from "@kiosk/front/assets/theme/colors"

import "./SaveFooter.css"
import { SparklingButton } from "../generic/SparklingButton"

const stickyBottomStyle: MantineStyleProp = {
  position: "fixed",
  bottom: 0,
  maxWidth: 950,
  width: "51%",
  background: colors.gray[0],
  borderTop: "solid",
  borderTopWidth: 1,
  borderTopColor: colors.gray[2],
}

interface PreviewFooterProps {
  isPendingGeneration: boolean
  onGenerate: () => void
}
export const PreviewFooter = (props: PreviewFooterProps) => {
  const { t } = useTranslation("csrd")

  return (
    <Group
      justify="space-between"
      style={stickyBottomStyle}
      pt={12}
      pb={12}
      pr={26}
      pl={26}
    >
      <Flex direction="row" justify="flex-end" w="100%">
        <SparklingButton
          onPress={() => {
            props.onGenerate()
          }}
          loading={props.isPendingGeneration}
          loadingLabel={t("aiGeneration.loading")}
          icon={<IconSparkles />}
        >
          {t("aiGeneration.regenerate")}
        </SparklingButton>
      </Flex>
    </Group>
  )
}
