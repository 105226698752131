import { enUS, frFR } from "@clerk/localizations"
import type { LocalizationResource } from "@clerk/types"

import { i18n, Locale } from "@kiosk/i18n"

import { config } from "@kiosk/front/config"

const company: Record<"kiosk" | "greenster", string> = {
  kiosk: "Kiosk",
  greenster: "Greenster",
}

export const CLERK_LANGUAGES: Record<Locale, LocalizationResource> = {
  fr: {
    ...frFR,
    footerPageLink__privacy: "Politique de confidentialité",
    footerPageLink__terms: "Mentions légales",
    signIn: {
      start: {
        subtitle: undefined,
        title: i18n.t("clerk.signInTitle", {
          company: company[config.logo],
        }),
      },
    },
  },
  en: {
    ...enUS,
    signIn: {
      start: {
        subtitle: undefined,
        title: i18n.t("clerk.signInTitle", {
          company: company[config.logo],
        }),
      },
    },
  },
}
