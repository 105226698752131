import { DataPointDemo } from "@kiosk/front/api/dashboard/types"

export const Social3: DataPointDemo[] = [
  {
    id: "item-0",
    dimensionId: 8,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 7.31,
  },
  {
    id: "item-1",
    dimensionId: 1,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 8.26,
  },
  {
    id: "item-2",
    dimensionId: 2,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 9.51,
  },
  {
    id: "item-3",
    dimensionId: 9,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 9.73,
  },
  {
    id: "item-4",
    dimensionId: 7,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.35,
  },
  {
    id: "item-5",
    dimensionId: 1,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 5.18,
  },
  {
    id: "item-6",
    dimensionId: 3,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 3.19,
  },
  {
    id: "item-7",
    dimensionId: 4,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 9.46,
  },
  {
    id: "item-8",
    dimensionId: 3,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 2.93,
  },
  {
    id: "item-9",
    dimensionId: 5,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 4.46,
  },
  {
    id: "item-10",
    dimensionId: 5,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 1.64,
  },
  {
    id: "item-11",
    dimensionId: 2,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 6.53,
  },
  {
    id: "item-12",
    dimensionId: 5,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 6.09,
  },
  {
    id: "item-13",
    dimensionId: 2,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 7.97,
  },
  {
    id: "item-14",
    dimensionId: 3,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 5.66,
  },
  {
    id: "item-15",
    dimensionId: 7,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 7.06,
  },
  {
    id: "item-16",
    dimensionId: 1,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 11.77,
  },
  {
    id: "item-17",
    dimensionId: 4,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.28,
  },
  {
    id: "item-18",
    dimensionId: 6,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 9.55,
  },
  {
    id: "item-19",
    dimensionId: 8,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 6.46,
  },
  {
    id: "item-20",
    dimensionId: 7,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.34,
  },
  {
    id: "item-21",
    dimensionId: 2,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 9.93,
  },
  {
    id: "item-22",
    dimensionId: 5,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 6.66,
  },
  {
    id: "item-23",
    dimensionId: 4,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 10.08,
  },
  {
    id: "item-24",
    dimensionId: 3,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 4.07,
  },
  {
    id: "item-25",
    dimensionId: 8,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 4.6,
  },
  {
    id: "item-26",
    dimensionId: 3,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 5.13,
  },
  {
    id: "item-27",
    dimensionId: 7,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.27,
  },
  {
    id: "item-28",
    dimensionId: 2,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 11.26,
  },
  {
    id: "item-29",
    dimensionId: 2,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 1.01,
  },
  {
    id: "item-30",
    dimensionId: 8,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 11.53,
  },
  {
    id: "item-31",
    dimensionId: 2,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 3.53,
  },
  {
    id: "item-32",
    dimensionId: 3,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 11.89,
  },
  {
    id: "item-33",
    dimensionId: 6,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 5.12,
  },
  {
    id: "item-34",
    dimensionId: 8,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 13,
  },
  {
    id: "item-35",
    dimensionId: 6,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 1.96,
  },
  {
    id: "item-36",
    dimensionId: 6,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 7.85,
  },
  {
    id: "item-37",
    dimensionId: 5,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 11.07,
  },
  {
    id: "item-38",
    dimensionId: 8,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 13.73,
  },
  {
    id: "item-39",
    dimensionId: 2,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 4.9,
  },
  {
    id: "item-40",
    dimensionId: 6,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 13.04,
  },
  {
    id: "item-41",
    dimensionId: 1,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 4.63,
  },
  {
    id: "item-42",
    dimensionId: 5,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 7.02,
  },
  {
    id: "item-43",
    dimensionId: 4,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 1.81,
  },
  {
    id: "item-44",
    dimensionId: 6,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 4.5,
  },
  {
    id: "item-45",
    dimensionId: 1,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 7.51,
  },
  {
    id: "item-46",
    dimensionId: 4,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 11.3,
  },
  {
    id: "item-47",
    dimensionId: 5,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 1.16,
  },
  {
    id: "item-48",
    dimensionId: 6,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 1.23,
  },
  {
    id: "item-49",
    dimensionId: 1,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 8.37,
  },
  {
    id: "item-50",
    dimensionId: 8,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 8.75,
  },
  {
    id: "item-51",
    dimensionId: 4,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 2.25,
  },
  {
    id: "item-52",
    dimensionId: 7,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 3.5,
  },
  {
    id: "item-53",
    dimensionId: 8,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 11.53,
  },
  {
    id: "item-54",
    dimensionId: 8,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 13.68,
  },
  {
    id: "item-55",
    dimensionId: 4,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 6.63,
  },
  {
    id: "item-56",
    dimensionId: 4,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 15.94,
  },
  {
    id: "item-57",
    dimensionId: 2,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 5.73,
  },
  {
    id: "item-58",
    dimensionId: 5,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 7.93,
  },
  {
    id: "item-59",
    dimensionId: 6,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 16.37,
  },
  {
    id: "item-60",
    dimensionId: 5,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 12.89,
  },
  {
    id: "item-61",
    dimensionId: 5,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 16.25,
  },
  {
    id: "item-62",
    dimensionId: 1,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 4.55,
  },
  {
    id: "item-63",
    dimensionId: 8,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 4.58,
  },
  {
    id: "item-64",
    dimensionId: 1,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 13.46,
  },
  {
    id: "item-65",
    dimensionId: 8,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 8.47,
  },
  {
    id: "item-66",
    dimensionId: 4,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 1.13,
  },
  {
    id: "item-67",
    dimensionId: 6,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 18.37,
  },
  {
    id: "item-68",
    dimensionId: 4,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 2.53,
  },
  {
    id: "item-69",
    dimensionId: 4,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 14.14,
  },
  {
    id: "item-70",
    dimensionId: 3,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 11.77,
  },
  {
    id: "item-71",
    dimensionId: 9,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 10.27,
  },
  {
    id: "item-72",
    dimensionId: 2,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 8.71,
  },
  {
    id: "item-73",
    dimensionId: 8,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.81,
  },
  {
    id: "item-74",
    dimensionId: 2,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 16.49,
  },
  {
    id: "item-75",
    dimensionId: 6,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 15.25,
  },
  {
    id: "item-76",
    dimensionId: 5,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 8.48,
  },
  {
    id: "item-77",
    dimensionId: 9,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 1.5,
  },
  {
    id: "item-78",
    dimensionId: 1,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 9.71,
  },
  {
    id: "item-79",
    dimensionId: 6,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.1,
  },
  {
    id: "item-80",
    dimensionId: 5,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 13.07,
  },
  {
    id: "item-81",
    dimensionId: 3,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 17.21,
  },
  {
    id: "item-82",
    dimensionId: 8,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.05,
  },
  {
    id: "item-83",
    dimensionId: 6,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 13.48,
  },
  {
    id: "item-84",
    dimensionId: 3,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 22.31,
  },
  {
    id: "item-85",
    dimensionId: 1,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 16.96,
  },
  {
    id: "item-86",
    dimensionId: 5,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 26.6,
  },
  {
    id: "item-87",
    dimensionId: 6,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 24.41,
  },
  {
    id: "item-88",
    dimensionId: 3,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 20.25,
  },
  {
    id: "item-89",
    dimensionId: 4,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 4.74,
  },
  {
    id: "item-90",
    dimensionId: 1,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 8.2,
  },
  {
    id: "item-91",
    dimensionId: 5,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 23.4,
  },
  {
    id: "item-92",
    dimensionId: 1,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 26.68,
  },
  {
    id: "item-93",
    dimensionId: 6,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 4.33,
  },
  {
    id: "item-94",
    dimensionId: 5,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 40.91,
  },
  {
    id: "item-95",
    dimensionId: 1,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 10.89,
  },
  {
    id: "item-96",
    dimensionId: 7,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 29.54,
  },
  {
    id: "item-97",
    dimensionId: 8,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 10.52,
  },
  {
    id: "item-98",
    dimensionId: 4,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 90.45,
  },
  {
    id: "item-99",
    dimensionId: 9,
    info: "+ 14% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’hommes",
    labelEN: "Number of men",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 102.34,
  },
]
