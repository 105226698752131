import { Box, Button, Modal, ModalBaseProps, Stack, Text } from "@mantine/core"
import _ from "lodash"
import { useCallback, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { useTranslation } from "react-i18next"
import Swal from "sweetalert2"

import { DataPoint } from "@kiosk/types/data-points"

import { useUploadDsnMutation } from "@kiosk/front/api/files/dsn"
import { sourcesKeys } from "@kiosk/front/api/sources/keys"
import CustomSegmentedControl from "@kiosk/front/components/CustomSegmentedControl"
import { DashedDropzone } from "@kiosk/front/components/DashedDropzone"
import { queryClient } from "@kiosk/front/lib/queryClient"

import { DSNSummary } from "./DSNSummary"

interface DropFileModalProps extends ModalBaseProps {}

type SupportedFileType = "dsn" | "fec" | "custom"

interface Translations {
  tabLabel: string
  title: string
  description: string
}

const useSuccessModal = (dataPoints: DataPoint[]) => {
  const { t } = useTranslation("sources")
  const [show, setShow] = useState(false)

  const showSwal = useCallback(() => {
    Swal.fire({
      icon: "success",
      showCloseButton: true,
      showConfirmButton: false,
      didOpen: () => setShow(true),
      didClose: () => setShow(false),
    })
  }, [])

  const SwalLoader = () => (
    <>
      {show &&
        createPortal(
          <Stack my={40}>
            <DSNSummary dataPoints={dataPoints} />
            <Box>
              <Button onClick={() => Swal.close()}>{t("actions.close")}</Button>
            </Box>
          </Stack>,
          Swal.getHtmlContainer()!,
        )}
    </>
  )

  return { showSwal, SwalLoader }
}

export default function NewFileModal(props: DropFileModalProps) {
  const { t } = useTranslation("sources")

  const errorMessages: Record<number, string> = {
    409: t("error.dataPointConflict"),
    500: t("error.processingError"),
  }

  // this is very silly, but it all breaks down if we don’t statically
  // reference the translations
  const translations: Record<SupportedFileType, Translations> = {
    dsn: {
      tabLabel: t("newFile.dsn.tabLabel"),
      title: t("newFile.dsn.title"),
      description: t("newFile.dsn.description"),
    },
    fec: {
      tabLabel: t("newFile.fec.tabLabel"),
      title: t("newFile.fec.title"),
      description: t("newFile.fec.description"),
    },
    custom: {
      tabLabel: t("newFile.custom.tabLabel"),
      title: t("newFile.custom.title"),
      description: t("newFile.custom.description"),
    },
  }

  const [activeSegment, setActiveSegment] = useState<SupportedFileType>("dsn")
  const {
    mutate: uploadDSN,
    error,
    isError,
    isPending,
    data,
    isSuccess,
  } = useUploadDsnMutation()
  const { showSwal, SwalLoader } = useSuccessModal(data ?? [])

  const uploadError = error !== null ? new Error(error.error) : null

  useEffect(() => {
    if (!isSuccess) return
    showSwal()
  }, [isSuccess, showSwal])

  useEffect(() => {
    if (!isSuccess) return
    queryClient.invalidateQueries({ queryKey: sourcesKeys.list() })
  }, [isSuccess])

  return (
    <Modal {...props} title={t("importFile.title")} size="lg">
      <Stack>
        <Text size="md">{t("importFile.description")}</Text>
        <CustomSegmentedControl
          onChange={(s) => setActiveSegment(s as SupportedFileType)}
          data={[
            {
              label: translations.dsn.tabLabel,
              value: "dsn",
            },
            {
              label: translations.fec.tabLabel,
              value: "fec",
            },
            {
              label: translations.custom.tabLabel,
              value: "custom",
            },
          ]}
        />
        <SwalLoader />
        <DashedDropzone
          title={translations[activeSegment].title}
          description={translations[activeSegment].description}
          uploadFile={(f) =>
            activeSegment === "dsn" ? uploadDSN(f) : _.noop()
          }
          uploadError={uploadError}
          isUploading={isPending}
          hasUploadError={isError}
          clearFileUrl={_.noop}
          errorTitle={isError ? t("dsnImport.failTitle") : undefined}
          errorMessage={errorMessages[error?.status ?? 500]}
        />
      </Stack>
    </Modal>
  )
}
