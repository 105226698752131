import { Anchor, Flex, Stack, Switch, Text } from "@mantine/core"
import _ from "lodash"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { DatoDisclosureRequirementResponse } from "@kiosk/types/data/dato"
import { DisclosureRequirementParagraph } from "@kiosk/types/prisma-client"

import { useAIGenerateDisclosureRequirementParagraphMutation } from "@kiosk/front/api/dashboard/mutations"
import { useDisclosureRequirementParagraphByDisclosureRequirementId } from "@kiosk/front/api/dashboard/queries"
import {
  useDisclosureRequirementAnswersQuery,
  useMaterialityQuery,
} from "@kiosk/front/api/reports/index"
import { DatoStructuredText } from "@kiosk/front/components/DatoStructuredText"
import { CSRDForm } from "@kiosk/front/components/csrd/CSRDForm"
import { DisclosureRequirementTab } from "@kiosk/front/components/csrd/DisclosureRequirementTab"
import { EsrsBadge } from "@kiosk/front/components/csrd/EsrsBadge"
import { MultiQueryWrapper } from "@kiosk/front/components/layout/QueryWrapper"
import configIro2, { findEsrsByCode } from "@kiosk/front/features/iro2/config"
import { useAIGenerateDisclosureRequirementParagraphMutationMock } from "@kiosk/front/specs/mocks/disclosureRequirementQueriesMocks"

import { config } from "../../config"

import { DisclosureRequirementPreview } from "./DisclosureRequirementPreview"
import { MaterialitySwitch } from "./MaterialSwitch"

interface DisclosureRequirementFormLayoutProps
  extends DatoDisclosureRequirementResponse {
  progress?: number
}

export const DisclosureRequirementFormLayout = ({
  disclosureRequirement,
  progress,
}: DisclosureRequirementFormLayoutProps) => {
  const { esrsCode, name, instructions } = disclosureRequirement
  const { t } = useTranslation("csrd")
  const disclosureRequirementAnswersQuery =
    useDisclosureRequirementAnswersQuery(disclosureRequirement.id)

  const esrs = extractEsrs(esrsCode)
  const hasMaterialitySwitch = esrs !== "ESRS2"

  const materialityQuery = useMaterialityQuery()
  const disclosureRequirementParagraphQuery =
    useDisclosureRequirementParagraphByDisclosureRequirementId(
      disclosureRequirement.id,
    )

  const queries = {
    nonMaterialEsrs: {
      query: materialityQuery,
      allowEmptyArray: true,
    },
    disclosureRequirementAnswers: {
      query: disclosureRequirementAnswersQuery,
      allowEmptyArray: true,
    },
    disclosureRequirementParagraph: {
      query: disclosureRequirementParagraphQuery,
      allowEmptyObject: true,
    },
  }

  // TODO: persist in DB.
  const [isMaterial, setIsMaterial] = useState(true)
  const [showXml, setShowXml] = useState(false)
  const [tab, setTab] = useState(t("preview.tabForm"))
  const [
    disclosureRequirementParagraphCurrentlyGenerated,
    setDisclosureRequirementParagraphCurrentlyGenerated,
  ] = useState<DisclosureRequirementParagraph | undefined>(undefined)

  const useConfigAiGeneratedDisclosureRequirementParagraphMutation =
    config.enableAICalls
      ? useAIGenerateDisclosureRequirementParagraphMutation
      : useAIGenerateDisclosureRequirementParagraphMutationMock

  const { mutateAsync: aiGenerateParagraph, isPending: isPendingParagraph } =
    useConfigAiGeneratedDisclosureRequirementParagraphMutation({
      onSuccess: (data: DisclosureRequirementParagraph) => {
        setTab(t("preview.tabPreview"))
        setDisclosureRequirementParagraphCurrentlyGenerated(data)
      },
      onCancel: () => {},
    })

  return (
    <MultiQueryWrapper absolute fluid queries={queries}>
      {({
        nonMaterialEsrs,
        disclosureRequirementAnswers,
        disclosureRequirementParagraph,
      }) => {
        const _esrs = findEsrsByCode(esrs)

        const esrsIsMaterial =
          hasMaterialitySwitch && !nonMaterialEsrs.includes(_esrs?.datoId ?? "")

        return (
          <Stack flex={1} pos="relative">
            <Stack pb={24} align="stretch" justify="center">
              {
                // Disclosure requirement header
              }
              <Stack
                p="lg"
                pos="sticky"
                top={0}
                bg="gray.0"
                style={{ zIndex: 1 }}
              >
                <Flex gap={16} wrap="nowrap" align="center">
                  <EsrsBadge topic={esrsCode} />
                  <Text flex="1" fz="xl" c="gray.9" fw={600} lineClamp={2}>
                    {name}
                  </Text>
                  {hasMaterialitySwitch && tab === t("preview.tabForm") ? (
                    <MaterialitySwitch
                      disabled={!esrsIsMaterial}
                      value={esrsIsMaterial && isMaterial}
                      onClick={() => setIsMaterial(!isMaterial)}
                    />
                  ) : null}
                  {tab === t("preview.tabPreview") && (
                    <Switch
                      checked={showXml}
                      onChange={() => setShowXml(!showXml)}
                      label={t("preview.showXbrl")}
                    />
                  )}
                  {(disclosureRequirementParagraph ||
                    disclosureRequirementParagraphCurrentlyGenerated) && (
                    <DisclosureRequirementTab
                      value={tab}
                      onChange={(selectedTab) => setTab(selectedTab)}
                    />
                  )}
                </Flex>
              </Stack>

              {tab === t("preview.tabForm") && (
                <Stack px={24}>
                  <DatoStructuredText content={instructions} />
                </Stack>
              )}

              {tab === t("preview.tabForm") ? (
                <>
                  {!hasMaterialitySwitch || esrsIsMaterial ? (
                    <CSRDForm
                      aiGenerateParagraph={aiGenerateParagraph}
                      progress={progress}
                      isPendingParagraph={isPendingParagraph}
                      key={disclosureRequirement.id}
                      initialValues={disclosureRequirementAnswers}
                      disclosureRequirement={disclosureRequirement}
                    />
                  ) : (
                    <NonMaterialEsrsComponent />
                  )}
                </>
              ) : (
                <DisclosureRequirementPreview
                  onGenerate={() => {
                    aiGenerateParagraph(disclosureRequirement.id)
                  }}
                  loading={isPendingParagraph}
                  previewParagraph={
                    disclosureRequirementParagraphCurrentlyGenerated ||
                    disclosureRequirementParagraph
                  }
                  xmlMode={showXml}
                />
              )}
            </Stack>
          </Stack>
        )
      }}
    </MultiQueryWrapper>
  )
}

const NonMaterialEsrsComponent = () => {
  const navigate = useNavigate()

  return (
    <Stack>
      <Text>This ESRS was determined to be non-material to your business.</Text>
      <Text>
        You can edit it in the{" "}
        <Anchor
          onClick={() =>
            navigate(
              `/csrd/${configIro2.esrs2}/${configIro2.disclosureRequirement.datoId}`,
            )
          }
        >
          IRO-2 page
        </Anchor>
        .
      </Text>
    </Stack>
  )
}

const extractEsrs = (code: string): string => {
  // ESRS2.BP-1 => ESRS2
  if (code.includes(".")) {
    return _.head(code.split("."))!
  }
  // E1-1 => E1
  if (code.includes("-")) {
    return _.head(code.split("-"))!
  }
  return code
}
