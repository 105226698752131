import {
  parseThemeColor,
  SimpleGrid,
  Stack,
  useMantineTheme,
} from "@mantine/core"
import _, { isEmpty } from "lodash"
import { useTranslation } from "react-i18next"

import { isDefined } from "@kiosk/shared/utils/helpers"

import {
  DashboardTopicDemo,
  DataPointDemo,
  DimensionedDataPointDemo,
  SingleValueDataPointDemo,
} from "@kiosk/front/api/dashboard/types"

import DataPointsDimensionedDemo from "./DataPointsDimensionedDemo"
import DataPointsSingleDemo from "./DataPointsSingleDemo"
import EmptyChart from "./EmptyChart"
import { TopicTitleDemo } from "./TopicTitleDemo"

type Props = {
  color?: string
  index?: number
  topic: DashboardTopicDemo
}

// TODO: I think it will be better if we simplify this, with chart and pie components and choose witch one to use, here all the data come from Dato, and its really not good
export const TopicDashboardDemo = ({ color, index, topic }: Props) => {
  const filterDataPointsByType = (dataPoints: DataPointDemo[]) => {
    const singleValueDataPoints = dataPoints.filter(
      (dataPoint) => dataPoint.type === "single-value",
    ) as SingleValueDataPointDemo[]

    const dimensionedDataPoints = dataPoints.filter(
      (dataPoint) => dataPoint.type === "dimensioned",
    ) as DimensionedDataPointDemo[]

    return { singleValueDataPoints, dimensionedDataPoints }
  }
  const { i18n } = useTranslation(["dashboard"])
  const currentLanguage = i18n.language

  const { singleValueDataPoints, dimensionedDataPoints } =
    filterDataPointsByType(topic.dataPoints)

  function aggregateData(objects: SingleValueDataPointDemo[]) {
    return _(objects)
      .groupBy(
        (obj) =>
          `${currentLanguage === "fr" ? obj.labelFR : obj.labelEN}-${obj.type}`,
      )
      .mapValues((points) => {
        const { id, info, labelFR, labelEN, type, unit, value } = points[0]
        const attributes = {
          id,
          info,
          label: currentLanguage === "fr" ? labelFR : labelEN,
          type,
          unit,
          value,
        }
        return {
          ...attributes,
          value: points.some(isDefined)
            ? _.sumBy(
                points.filter((p) => isDefined(p.value)),
                (p) => p.value!,
              )
            : undefined,
        }
      })
      .values()
      .value()
  }

  function aggregateDimensionedData(
    dataPoints: DimensionedDataPointDemo[],
  ): DimensionedDataPointDemo[] {
    const aggregatedData: Record<string, DimensionedDataPointDemo> = {}

    dataPoints.forEach((dataPoint) => {
      const {
        id,
        dimensionId,
        info,
        baseColor,
        chart,
        isMandatoryInDashboard,
        labelFR,
        labelEN,
        numberType,
        time,
        type,
        unit,
        values,
      } = dataPoint

      // Utiliser une clé unique en combinant le label, type et chart
      const uniqueKey = `${currentLanguage === "fr" ? labelFR : labelEN}-${type}-${chart}`

      // Si la clé unique n'existe pas dans l'objet agrégé, on l'initialise avec le dataPoint courant
      if (!aggregatedData[uniqueKey]) {
        aggregatedData[uniqueKey] = {
          id,
          dimensionId,
          info,
          baseColor,
          chart,
          isMandatoryInDashboard,
          labelFR,
          labelEN,
          numberType,
          time,
          type,
          unit,
          values: [...(values ?? [])], // Copier les valeurs initiales
        }
      } else {
        // Si la clé existe déjà, additionner les valeurs pour chaque dimension
        ;(values ?? []).forEach((value) => {
          const existingValue = aggregatedData[uniqueKey].values!.find((v) =>
            v.dimensions.some(
              (d) => d.dimensionLabel === value.dimensions[0].dimensionLabel,
            ),
          )

          if (existingValue) {
            existingValue.value += value.value
          } else {
            aggregatedData[uniqueKey].values!.push({ ...value })
          }
        })
      }
    })

    // Retourner un tableau d'objets à partir des valeurs agrégées
    return Object.values(aggregatedData)
  }

  const theme = useMantineTheme()
  const parsedColor = parseThemeColor({ color, theme })

  return (
    <Stack gap={40} p={24}>
      {(topic.nameFR || topic.nameEN) && (
        <TopicTitleDemo
          icon={topic.icon}
          iconColor={topic.iconColor}
          iconBackgroundColor={topic.iconBackgroundColor}
          name={currentLanguage === "fr" ? topic.nameFR : topic.nameEN}
        />
      )}

      {topic.grid === 3 ? (
        <SimpleGrid cols={topic.grid} spacing={48}>
          {aggregateData(singleValueDataPoints).map((dataPoint, index) => (
            <DataPointsSingleDemo
              info={dataPoint.info}
              key={index}
              label={dataPoint.label}
              unit={dataPoint.unit}
              value={dataPoint.value}
            />
          ))}
        </SimpleGrid>
      ) : (
        <SimpleGrid cols={2} spacing={0}>
          {index! % 2 === 0 ? (
            <>
              <SimpleGrid cols={2} pr={24} spacing={24} verticalSpacing={60}>
                {aggregateData(singleValueDataPoints).map(
                  (dataPoint, index) => (
                    <DataPointsSingleDemo
                      key={index}
                      info={dataPoint.info}
                      label={dataPoint.label}
                      unit={dataPoint.unit}
                      value={dataPoint.value}
                    />
                  ),
                )}
              </SimpleGrid>

              <Stack
                pl={24}
                style={{ borderLeft: `1px solid ${parsedColor.value}` }}
                justify="center"
              >
                {aggregateDimensionedData(dimensionedDataPoints).filter(
                  (points) => !isEmpty(points.values ?? []),
                ).length > 0 ? (
                  <>
                    {aggregateDimensionedData(dimensionedDataPoints).map(
                      (dataPoint, index) => (
                        <DataPointsDimensionedDemo
                          chart={dataPoint.chart}
                          baseColor={dataPoint.baseColor}
                          key={index}
                          label={
                            currentLanguage === "fr"
                              ? dataPoint.labelFR
                              : dataPoint.labelEN
                          }
                          values={dataPoint.values}
                          unit={dataPoint.unit}
                        />
                      ),
                    )}
                  </>
                ) : (
                  <EmptyChart />
                )}
              </Stack>
            </>
          ) : (
            <>
              <Stack
                pr={24}
                style={{ borderRight: `1px solid ${parsedColor.value}` }}
                justify="center"
              >
                {aggregateDimensionedData(dimensionedDataPoints).filter(
                  (points) => !isEmpty(points.values ?? []),
                ).length > 0 ? (
                  <>
                    {aggregateDimensionedData(dimensionedDataPoints).map(
                      (dataPoint, index) => (
                        <DataPointsDimensionedDemo
                          chart={dataPoint.chart}
                          baseColor={dataPoint.baseColor}
                          key={index}
                          label={
                            currentLanguage === "fr"
                              ? dataPoint.labelFR
                              : dataPoint.labelEN
                          }
                          values={dataPoint.values}
                          unit={dataPoint.unit}
                        />
                      ),
                    )}
                  </>
                ) : (
                  <EmptyChart />
                )}
              </Stack>

              <SimpleGrid pl={24} cols={2} spacing={24} verticalSpacing={60}>
                {aggregateData(singleValueDataPoints).map(
                  (dataPoint, index) => (
                    <DataPointsSingleDemo
                      key={index}
                      info={dataPoint.info}
                      label={dataPoint.label}
                      unit={dataPoint.unit}
                      value={dataPoint.value}
                    />
                  ),
                )}
              </SimpleGrid>
            </>
          )}
        </SimpleGrid>
      )}
    </Stack>
  )
}
