import { DataPointDemo } from "@kiosk/front/api/dashboard/types"

export const Social1: DataPointDemo[] = [
  {
    id: "item-0",
    dimensionId: 1,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 7.68,
  },
  {
    id: "item-1",
    dimensionId: 7,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 14.68,
  },
  {
    id: "item-2",
    dimensionId: 8,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.04,
  },
  {
    id: "item-3",
    dimensionId: 4,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 17.62,
  },
  {
    id: "item-4",
    dimensionId: 3,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 15.88,
  },
  {
    id: "item-5",
    dimensionId: 8,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 11.08,
  },
  {
    id: "item-6",
    dimensionId: 3,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 4.78,
  },
  {
    id: "item-7",
    dimensionId: 7,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 9.35,
  },
  {
    id: "item-8",
    dimensionId: 9,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 14.39,
  },
  {
    id: "item-9",
    dimensionId: 5,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 5.7,
  },
  {
    id: "item-10",
    dimensionId: 4,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 18.48,
  },
  {
    id: "item-11",
    dimensionId: 2,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 4.07,
  },
  {
    id: "item-12",
    dimensionId: 2,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 2.8,
  },
  {
    id: "item-13",
    dimensionId: 2,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 5.65,
  },
  {
    id: "item-14",
    dimensionId: 4,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 19.6,
  },
  {
    id: "item-15",
    dimensionId: 2,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 12.37,
  },
  {
    id: "item-16",
    dimensionId: 3,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 20.06,
  },
  {
    id: "item-17",
    dimensionId: 9,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 2.43,
  },
  {
    id: "item-18",
    dimensionId: 1,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 14.59,
  },
  {
    id: "item-19",
    dimensionId: 9,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 14.27,
  },
  {
    id: "item-20",
    dimensionId: 3,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 10.61,
  },
  {
    id: "item-21",
    dimensionId: 2,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 4,
  },
  {
    id: "item-22",
    dimensionId: 2,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 18.68,
  },
  {
    id: "item-23",
    dimensionId: 3,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 1.14,
  },
  {
    id: "item-24",
    dimensionId: 3,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 15.25,
  },
  {
    id: "item-25",
    dimensionId: 9,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 19.94,
  },
  {
    id: "item-26",
    dimensionId: 7,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 12.36,
  },
  {
    id: "item-27",
    dimensionId: 9,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 6.35,
  },
  {
    id: "item-28",
    dimensionId: 6,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 3.67,
  },
  {
    id: "item-29",
    dimensionId: 2,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 5.46,
  },
  {
    id: "item-30",
    dimensionId: 4,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 18.24,
  },
  {
    id: "item-31",
    dimensionId: 5,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 19.41,
  },
  {
    id: "item-32",
    dimensionId: 1,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 16.72,
  },
  {
    id: "item-33",
    dimensionId: 8,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 5.49,
  },
  {
    id: "item-34",
    dimensionId: 2,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 20.13,
  },
  {
    id: "item-35",
    dimensionId: 4,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 7.42,
  },
  {
    id: "item-36",
    dimensionId: 1,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 8.36,
  },
  {
    id: "item-37",
    dimensionId: 6,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 1.42,
  },
  {
    id: "item-38",
    dimensionId: 6,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 4.53,
  },
  {
    id: "item-39",
    dimensionId: 7,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 20.73,
  },
  {
    id: "item-40",
    dimensionId: 1,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 2.82,
  },
  {
    id: "item-41",
    dimensionId: 2,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 21.99,
  },
  {
    id: "item-42",
    dimensionId: 8,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 17.14,
  },
  {
    id: "item-43",
    dimensionId: 8,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 22.11,
  },
  {
    id: "item-44",
    dimensionId: 7,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 1.24,
  },
  {
    id: "item-45",
    dimensionId: 7,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 24.95,
  },
  {
    id: "item-46",
    dimensionId: 2,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 5.87,
  },
  {
    id: "item-47",
    dimensionId: 6,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 5.35,
  },
  {
    id: "item-48",
    dimensionId: 5,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 6.46,
  },
  {
    id: "item-49",
    dimensionId: 3,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 21.68,
  },
  {
    id: "item-50",
    dimensionId: 4,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 3.62,
  },
  {
    id: "item-51",
    dimensionId: 7,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 20.3,
  },
  {
    id: "item-52",
    dimensionId: 1,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 14.81,
  },
  {
    id: "item-53",
    dimensionId: 3,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 15.98,
  },
  {
    id: "item-54",
    dimensionId: 1,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 27.21,
  },
  {
    id: "item-55",
    dimensionId: 7,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 17.36,
  },
  {
    id: "item-56",
    dimensionId: 3,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 15.24,
  },
  {
    id: "item-57",
    dimensionId: 2,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 25.18,
  },
  {
    id: "item-58",
    dimensionId: 9,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 7.38,
  },
  {
    id: "item-59",
    dimensionId: 5,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 22.51,
  },
  {
    id: "item-60",
    dimensionId: 3,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 5.92,
  },
  {
    id: "item-61",
    dimensionId: 8,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 23.68,
  },
  {
    id: "item-62",
    dimensionId: 3,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 4.07,
  },
  {
    id: "item-63",
    dimensionId: 2,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 10.24,
  },
  {
    id: "item-64",
    dimensionId: 7,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 7.25,
  },
  {
    id: "item-65",
    dimensionId: 7,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 21.22,
  },
  {
    id: "item-66",
    dimensionId: 2,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 26.45,
  },
  {
    id: "item-67",
    dimensionId: 4,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 24.52,
  },
  {
    id: "item-68",
    dimensionId: 5,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 15.21,
  },
  {
    id: "item-69",
    dimensionId: 7,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 22.5,
  },
  {
    id: "item-70",
    dimensionId: 6,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 19.86,
  },
  {
    id: "item-71",
    dimensionId: 5,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 18.78,
  },
  {
    id: "item-72",
    dimensionId: 5,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.97,
  },
  {
    id: "item-73",
    dimensionId: 4,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 23.14,
  },
  {
    id: "item-74",
    dimensionId: 2,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 20.55,
  },
  {
    id: "item-75",
    dimensionId: 1,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 25.58,
  },
  {
    id: "item-76",
    dimensionId: 2,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 14.29,
  },
  {
    id: "item-77",
    dimensionId: 7,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 21.66,
  },
  {
    id: "item-78",
    dimensionId: 3,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 4.86,
  },
  {
    id: "item-79",
    dimensionId: 9,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 21.02,
  },
  {
    id: "item-80",
    dimensionId: 9,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 30.8,
  },
  {
    id: "item-81",
    dimensionId: 6,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 33.71,
  },
  {
    id: "item-82",
    dimensionId: 9,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 8.24,
  },
  {
    id: "item-83",
    dimensionId: 6,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 2.43,
  },
  {
    id: "item-84",
    dimensionId: 8,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 5.75,
  },
  {
    id: "item-85",
    dimensionId: 1,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 28.42,
  },
  {
    id: "item-86",
    dimensionId: 5,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 19.6,
  },
  {
    id: "item-87",
    dimensionId: 2,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 52.83,
  },
  {
    id: "item-88",
    dimensionId: 1,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 42.66,
  },
  {
    id: "item-89",
    dimensionId: 4,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 42.02,
  },
  {
    id: "item-90",
    dimensionId: 9,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 15.23,
  },
  {
    id: "item-91",
    dimensionId: 3,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 43.99,
  },
  {
    id: "item-92",
    dimensionId: 3,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 36.45,
  },
  {
    id: "item-93",
    dimensionId: 4,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 17.1,
  },
  {
    id: "item-94",
    dimensionId: 9,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 70.63,
  },
  {
    id: "item-95",
    dimensionId: 5,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 8.89,
  },
  {
    id: "item-96",
    dimensionId: 6,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 17.83,
  },
  {
    id: "item-97",
    dimensionId: 8,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 69.8,
  },
  {
    id: "item-98",
    dimensionId: 4,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 69.68,
  },
  {
    id: "item-99",
    dimensionId: 5,
    info: "+ 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés permanents",
    labelEN: "Number of permanent employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 207.54,
  },
]
