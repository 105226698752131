import { Button, Group, Loader, Stack } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import Sparkle from "react-sparkle"

interface SparkleProps {
  children: React.ReactNode
  loading?: boolean
  loadingLabel?: string
  disabled?: boolean
  icon?: React.ReactNode
  onPress?: () => void
}

export const SparklingButton = (props: SparkleProps) => {
  const isDisabled = props.disabled || props.loading
  const matches = useMediaQuery("(min-width: 100em)")
  return (
    <Stack pos="relative">
      <Button
        onClick={() => !isDisabled && props.onPress?.()}
        style={{
          cursor: isDisabled ? "not-allowed" : "pointer",
        }}
        bg={isDisabled ? "violet.3" : "violet.5"}
      >
        <Group justify="space-between" gap={8}>
          {props.loading ? <Loader size="xs" color="gray.0" /> : props.icon}
          {matches && ((props.loading && props.loadingLabel) || props.children)}
        </Group>
      </Button>
      {!isDisabled && (
        <Sparkle
          overflowPx={8}
          flickerSpeed="slowest"
          fadeOutSpeed={10}
          flicker={false}
          count={5}
        />
      )}
    </Stack>
  )
}
