import { DataPointDemo } from "@kiosk/front/api/dashboard/types"

export const Social2: DataPointDemo[] = [
  {
    id: "item-0",
    dimensionId: 5,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 1.29,
  },
  {
    id: "item-1",
    dimensionId: 2,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 2.01,
  },
  {
    id: "item-2",
    dimensionId: 6,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 1.5,
  },
  {
    id: "item-3",
    dimensionId: 8,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 1.74,
  },
  {
    id: "item-4",
    dimensionId: 2,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 2.19,
  },
  {
    id: "item-5",
    dimensionId: 5,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.75,
  },
  {
    id: "item-6",
    dimensionId: 6,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 2.24,
  },
  {
    id: "item-7",
    dimensionId: 5,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 1.59,
  },
  {
    id: "item-8",
    dimensionId: 5,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 1.11,
  },
  {
    id: "item-9",
    dimensionId: 3,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 1.95,
  },
  {
    id: "item-10",
    dimensionId: 3,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.95,
  },
  {
    id: "item-11",
    dimensionId: 9,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.62,
  },
  {
    id: "item-12",
    dimensionId: 7,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 1.99,
  },
  {
    id: "item-13",
    dimensionId: 8,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 1.32,
  },
  {
    id: "item-14",
    dimensionId: 3,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 1.04,
  },
  {
    id: "item-15",
    dimensionId: 1,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 1.18,
  },
  {
    id: "item-16",
    dimensionId: 3,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.65,
  },
  {
    id: "item-17",
    dimensionId: 9,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.17,
  },
  {
    id: "item-18",
    dimensionId: 5,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.32,
  },
  {
    id: "item-19",
    dimensionId: 9,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 2.38,
  },
  {
    id: "item-20",
    dimensionId: 3,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 1.76,
  },
  {
    id: "item-21",
    dimensionId: 2,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 1.78,
  },
  {
    id: "item-22",
    dimensionId: 3,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.42,
  },
  {
    id: "item-23",
    dimensionId: 8,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 1.46,
  },
  {
    id: "item-24",
    dimensionId: 1,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.8,
  },
  {
    id: "item-25",
    dimensionId: 3,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.16,
  },
  {
    id: "item-26",
    dimensionId: 7,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 1.54,
  },
  {
    id: "item-27",
    dimensionId: 8,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 1.38,
  },
  {
    id: "item-28",
    dimensionId: 2,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 1.33,
  },
  {
    id: "item-29",
    dimensionId: 9,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 1.83,
  },
  {
    id: "item-30",
    dimensionId: 7,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.06,
  },
  {
    id: "item-31",
    dimensionId: 1,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.44,
  },
  {
    id: "item-32",
    dimensionId: 3,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 2.69,
  },
  {
    id: "item-33",
    dimensionId: 1,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 2.8,
  },
  {
    id: "item-34",
    dimensionId: 8,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 1.43,
  },
  {
    id: "item-35",
    dimensionId: 5,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 1,
  },
  {
    id: "item-36",
    dimensionId: 1,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 2.45,
  },
  {
    id: "item-37",
    dimensionId: 7,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 2.35,
  },
  {
    id: "item-38",
    dimensionId: 2,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.72,
  },
  {
    id: "item-39",
    dimensionId: 5,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.7,
  },
  {
    id: "item-40",
    dimensionId: 7,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 1.61,
  },
  {
    id: "item-41",
    dimensionId: 6,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 2.02,
  },
  {
    id: "item-42",
    dimensionId: 6,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 1.13,
  },
  {
    id: "item-43",
    dimensionId: 4,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.8,
  },
  {
    id: "item-44",
    dimensionId: 4,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.6,
  },
  {
    id: "item-45",
    dimensionId: 9,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 2.66,
  },
  {
    id: "item-46",
    dimensionId: 7,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 2.74,
  },
  {
    id: "item-47",
    dimensionId: 6,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 1.15,
  },
  {
    id: "item-48",
    dimensionId: 5,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 1.86,
  },
  {
    id: "item-49",
    dimensionId: 4,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 2.48,
  },
  {
    id: "item-50",
    dimensionId: 9,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.05,
  },
  {
    id: "item-51",
    dimensionId: 1,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 2.62,
  },
  {
    id: "item-52",
    dimensionId: 3,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 2.68,
  },
  {
    id: "item-53",
    dimensionId: 7,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 3.1,
  },
  {
    id: "item-54",
    dimensionId: 8,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 2.1,
  },
  {
    id: "item-55",
    dimensionId: 3,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 1.29,
  },
  {
    id: "item-56",
    dimensionId: 4,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.13,
  },
  {
    id: "item-57",
    dimensionId: 4,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 2.2,
  },
  {
    id: "item-58",
    dimensionId: 4,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 2.42,
  },
  {
    id: "item-59",
    dimensionId: 7,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 2.25,
  },
  {
    id: "item-60",
    dimensionId: 8,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 1.25,
  },
  {
    id: "item-61",
    dimensionId: 8,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 3.45,
  },
  {
    id: "item-62",
    dimensionId: 2,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 1.84,
  },
  {
    id: "item-63",
    dimensionId: 2,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 3.56,
  },
  {
    id: "item-64",
    dimensionId: 6,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 2.44,
  },
  {
    id: "item-65",
    dimensionId: 2,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 2.77,
  },
  {
    id: "item-66",
    dimensionId: 2,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 3.36,
  },
  {
    id: "item-67",
    dimensionId: 6,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 1.57,
  },
  {
    id: "item-68",
    dimensionId: 6,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 1.83,
  },
  {
    id: "item-69",
    dimensionId: 4,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 2.35,
  },
  {
    id: "item-70",
    dimensionId: 9,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 3.42,
  },
  {
    id: "item-71",
    dimensionId: 4,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 1.03,
  },
  {
    id: "item-72",
    dimensionId: 6,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 3.99,
  },
  {
    id: "item-73",
    dimensionId: 1,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 2.81,
  },
  {
    id: "item-74",
    dimensionId: 8,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 1.35,
  },
  {
    id: "item-75",
    dimensionId: 2,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 3.63,
  },
  {
    id: "item-76",
    dimensionId: 4,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 3.07,
  },
  {
    id: "item-77",
    dimensionId: 6,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 0.3,
  },
  {
    id: "item-78",
    dimensionId: 7,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 2.69,
  },
  {
    id: "item-79",
    dimensionId: 5,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 4.11,
  },
  {
    id: "item-80",
    dimensionId: 6,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 3.19,
  },
  {
    id: "item-81",
    dimensionId: 5,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.96,
  },
  {
    id: "item-82",
    dimensionId: 8,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 0.06,
  },
  {
    id: "item-83",
    dimensionId: 7,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 2.9,
  },
  {
    id: "item-84",
    dimensionId: 9,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 3.18,
  },
  {
    id: "item-85",
    dimensionId: 3,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 5.01,
  },
  {
    id: "item-86",
    dimensionId: 3,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 5.21,
  },
  {
    id: "item-87",
    dimensionId: 1,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 3.61,
  },
  {
    id: "item-88",
    dimensionId: 5,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.35,
  },
  {
    id: "item-89",
    dimensionId: 3,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 3.4,
  },
  {
    id: "item-90",
    dimensionId: 3,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 3.26,
  },
  {
    id: "item-91",
    dimensionId: 9,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 5.6,
  },
  {
    id: "item-92",
    dimensionId: 9,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 3.07,
  },
  {
    id: "item-93",
    dimensionId: 6,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 0.72,
  },
  {
    id: "item-94",
    dimensionId: 8,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 6.05,
  },
  {
    id: "item-95",
    dimensionId: 1,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 1.02,
  },
  {
    id: "item-96",
    dimensionId: 2,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    value: 10.76,
  },
  {
    id: "item-97",
    dimensionId: 1,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    value: 3.97,
  },
  {
    id: "item-98",
    dimensionId: 9,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 14.37,
  },
  {
    id: "item-99",
    dimensionId: 3,
    info: "- 17% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Nombre d’employés temporaires",
    labelEN: "Number of temporary employees",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    value: 15.51,
  },
]
