import { DataPointDemo } from "@kiosk/front/api/dashboard/types"

export const Social6: DataPointDemo[] = [
  {
    id: "item-0",
    dimensionId: 5,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 1.21,
  },
  {
    id: "item-1",
    dimensionId: 1,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.59,
  },
  {
    id: "item-2",
    dimensionId: 7,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 1.24,
  },
  {
    id: "item-3",
    dimensionId: 2,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.32,
  },
  {
    id: "item-4",
    dimensionId: 5,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.02,
  },
  {
    id: "item-5",
    dimensionId: 4,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.22,
  },
  {
    id: "item-6",
    dimensionId: 7,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 1.14,
  },
  {
    id: "item-7",
    dimensionId: 3,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.47,
  },
  {
    id: "item-8",
    dimensionId: 9,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.55,
  },
  {
    id: "item-9",
    dimensionId: 1,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 1.03,
  },
  {
    id: "item-10",
    dimensionId: 8,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.19,
  },
  {
    id: "item-11",
    dimensionId: 6,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 1.07,
  },
  {
    id: "item-12",
    dimensionId: 4,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.48,
  },
  {
    id: "item-13",
    dimensionId: 9,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 1.36,
  },
  {
    id: "item-14",
    dimensionId: 7,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 1.07,
  },
  {
    id: "item-15",
    dimensionId: 3,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.75,
  },
  {
    id: "item-16",
    dimensionId: 4,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.83,
  },
  {
    id: "item-17",
    dimensionId: 3,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.06,
  },
  {
    id: "item-18",
    dimensionId: 1,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 1.13,
  },
  {
    id: "item-19",
    dimensionId: 1,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.96,
  },
  {
    id: "item-20",
    dimensionId: 9,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.05,
  },
  {
    id: "item-21",
    dimensionId: 3,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.95,
  },
  {
    id: "item-22",
    dimensionId: 7,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 1.52,
  },
  {
    id: "item-23",
    dimensionId: 7,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.89,
  },
  {
    id: "item-24",
    dimensionId: 8,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.17,
  },
  {
    id: "item-25",
    dimensionId: 9,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 1.33,
  },
  {
    id: "item-26",
    dimensionId: 7,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.01,
  },
  {
    id: "item-27",
    dimensionId: 6,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 1.41,
  },
  {
    id: "item-28",
    dimensionId: 7,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.56,
  },
  {
    id: "item-29",
    dimensionId: 4,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.11,
  },
  {
    id: "item-30",
    dimensionId: 2,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.74,
  },
  {
    id: "item-31",
    dimensionId: 9,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.09,
  },
  {
    id: "item-32",
    dimensionId: 4,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 1.18,
  },
  {
    id: "item-33",
    dimensionId: 7,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.31,
  },
  {
    id: "item-34",
    dimensionId: 1,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.01,
  },
  {
    id: "item-35",
    dimensionId: 1,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.46,
  },
  {
    id: "item-36",
    dimensionId: 3,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 1.35,
  },
  {
    id: "item-37",
    dimensionId: 8,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 1.45,
  },
  {
    id: "item-38",
    dimensionId: 2,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.79,
  },
  {
    id: "item-39",
    dimensionId: 2,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.26,
  },
  {
    id: "item-40",
    dimensionId: 1,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 1.03,
  },
  {
    id: "item-41",
    dimensionId: 5,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.69,
  },
  {
    id: "item-42",
    dimensionId: 3,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 1.41,
  },
  {
    id: "item-43",
    dimensionId: 1,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.02,
  },
  {
    id: "item-44",
    dimensionId: 9,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 1.23,
  },
  {
    id: "item-45",
    dimensionId: 7,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.2,
  },
  {
    id: "item-46",
    dimensionId: 2,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.49,
  },
  {
    id: "item-47",
    dimensionId: 5,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.33,
  },
  {
    id: "item-48",
    dimensionId: 6,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 1.3,
  },
  {
    id: "item-49",
    dimensionId: 8,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.22,
  },
  {
    id: "item-50",
    dimensionId: 2,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 1.75,
  },
  {
    id: "item-51",
    dimensionId: 8,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 1.95,
  },
  {
    id: "item-52",
    dimensionId: 2,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 1.94,
  },
  {
    id: "item-53",
    dimensionId: 8,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.17,
  },
  {
    id: "item-54",
    dimensionId: 3,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 1.49,
  },
  {
    id: "item-55",
    dimensionId: 8,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 1.8,
  },
  {
    id: "item-56",
    dimensionId: 1,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.35,
  },
  {
    id: "item-57",
    dimensionId: 4,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.8,
  },
  {
    id: "item-58",
    dimensionId: 4,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 1.27,
  },
  {
    id: "item-59",
    dimensionId: 1,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 2.21,
  },
  {
    id: "item-60",
    dimensionId: 5,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 1.14,
  },
  {
    id: "item-61",
    dimensionId: 6,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.24,
  },
  {
    id: "item-62",
    dimensionId: 2,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.01,
  },
  {
    id: "item-63",
    dimensionId: 6,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.77,
  },
  {
    id: "item-64",
    dimensionId: 1,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 1.86,
  },
  {
    id: "item-65",
    dimensionId: 6,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.84,
  },
  {
    id: "item-66",
    dimensionId: 1,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.33,
  },
  {
    id: "item-67",
    dimensionId: 7,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.55,
  },
  {
    id: "item-68",
    dimensionId: 7,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 1.59,
  },
  {
    id: "item-69",
    dimensionId: 3,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 1.05,
  },
  {
    id: "item-70",
    dimensionId: 6,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 1.37,
  },
  {
    id: "item-71",
    dimensionId: 7,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 2.33,
  },
  {
    id: "item-72",
    dimensionId: 6,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.07,
  },
  {
    id: "item-73",
    dimensionId: 8,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 1.34,
  },
  {
    id: "item-74",
    dimensionId: 1,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 2.82,
  },
  {
    id: "item-75",
    dimensionId: 1,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 2.56,
  },
  {
    id: "item-76",
    dimensionId: 3,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 2.7,
  },
  {
    id: "item-77",
    dimensionId: 9,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 2.41,
  },
  {
    id: "item-78",
    dimensionId: 9,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.84,
  },
  {
    id: "item-79",
    dimensionId: 5,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 2.51,
  },
  {
    id: "item-80",
    dimensionId: 2,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 0.27,
  },
  {
    id: "item-81",
    dimensionId: 2,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 2.25,
  },
  {
    id: "item-82",
    dimensionId: 7,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 2.14,
  },
  {
    id: "item-83",
    dimensionId: 8,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 0.69,
  },
  {
    id: "item-84",
    dimensionId: 7,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 2.28,
  },
  {
    id: "item-85",
    dimensionId: 5,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.57,
  },
  {
    id: "item-86",
    dimensionId: 9,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 3.96,
  },
  {
    id: "item-87",
    dimensionId: 5,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 0.48,
  },
  {
    id: "item-88",
    dimensionId: 4,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 1.09,
  },
  {
    id: "item-89",
    dimensionId: 1,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 2.58,
  },
  {
    id: "item-90",
    dimensionId: 4,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 3.04,
  },
  {
    id: "item-91",
    dimensionId: 4,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 4.88,
  },
  {
    id: "item-92",
    dimensionId: 6,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 4.31,
  },
  {
    id: "item-93",
    dimensionId: 8,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 2.17,
  },
  {
    id: "item-94",
    dimensionId: 9,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 3,
  },
  {
    id: "item-95",
    dimensionId: 3,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 3.72,
  },
  {
    id: "item-96",
    dimensionId: 2,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "%",
    value: 6.38,
  },
  {
    id: "item-97",
    dimensionId: 5,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "%",
    value: 3.02,
  },
  {
    id: "item-98",
    dimensionId: 8,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 5.63,
  },
  {
    id: "item-99",
    dimensionId: 2,
    info: "- 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Ratio entre le salaire d'entrée et le salaire minimum",
    labelEN: "Entry salary to minimum wage ratio",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "%",
    value: 11.23,
  },
]
