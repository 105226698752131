import { i18n } from "@kiosk/i18n/index"
import { useQuery } from "@tanstack/react-query"

import {
  DatoDisclosureRequirementNamesResponse,
  DatoDisclosureRequirementResponse,
  DatoRequest,
} from "@kiosk/types/data/dato"
import { DatoProxyRequest } from "@kiosk/types/endpoints/dato"

import {
  ALL_CATEGORIES_TOPICS_NAMES_QUERY,
  CSRD_DISCLOSURE_REQUIREMENT_BY_ID,
} from "@kiosk/shared/datocms/queries"

import { apiClient } from "@kiosk/front/lib/apiClient"

type PaginatedDatoRequest = DatoRequest & {
  fieldName: string
  metaFieldName: string
}

export const makeDatoRequest = ({
  query,
  variables,
  paginated,
}: DatoProxyRequest) =>
  apiClient.post("/dato", {
    data: {
      query,
      variables: { locale: i18n.language, ...variables },
      paginated,
    },
  })

export const makeDatoPaginatedRequest = async <T>({
  query,
  variables,
  fieldName,
  metaFieldName,
}: PaginatedDatoRequest): Promise<T> => {
  const response = await makeDatoRequest({
    query,
    variables,
    paginated: {
      metadataField: metaFieldName,
      field: fieldName,
    },
  })
  return response.data.data
}

export const useDatoQuery = <T>({ query, variables }: DatoRequest) => {
  return useQuery<T>({
    queryKey: ["dato", query, variables],
    queryFn: async () => {
      const response = await makeDatoRequest({ query, variables })
      return response.data.data
    },
  })
}

export const useDatoDisclosureRequirement = (id: string) => {
  return useDatoQuery<DatoDisclosureRequirementResponse>({
    query: CSRD_DISCLOSURE_REQUIREMENT_BY_ID,
    variables: { id },
  })
}

export const useDatoCategoriesTopicsNames = () => {
  return useDatoQuery<DatoDisclosureRequirementNamesResponse>({
    query: ALL_CATEGORIES_TOPICS_NAMES_QUERY,
  })
}
