import { DataPointDemo } from "@kiosk/front/api/dashboard/types"

export const Environment2: DataPointDemo[] = [
  {
    id: "item-0",
    dimensionId: 8,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 230.34,
  },
  {
    id: "item-1",
    dimensionId: 6,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 63,
  },
  {
    id: "item-2",
    dimensionId: 4,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 20,
  },
  {
    id: "item-3",
    dimensionId: 7,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 71.51,
  },
  {
    id: "item-4",
    dimensionId: 5,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 242.09,
  },
  {
    id: "item-5",
    dimensionId: 5,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 123.17,
  },
  {
    id: "item-6",
    dimensionId: 7,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 143.92,
  },
  {
    id: "item-7",
    dimensionId: 8,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 80.02,
  },
  {
    id: "item-8",
    dimensionId: 1,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 200,
  },
  {
    id: "item-9",
    dimensionId: 6,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 48.01,
  },
  {
    id: "item-10",
    dimensionId: 5,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 49.93,
  },
  {
    id: "item-11",
    dimensionId: 3,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 93.87,
  },
  {
    id: "item-12",
    dimensionId: 1,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 219.62,
  },
  {
    id: "item-13",
    dimensionId: 3,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 213.51,
  },
  {
    id: "item-14",
    dimensionId: 2,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 7.77,
  },
  {
    id: "item-15",
    dimensionId: 9,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 174.43,
  },
  {
    id: "item-16",
    dimensionId: 3,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 144.67,
  },
  {
    id: "item-17",
    dimensionId: 1,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 139.58,
  },
  {
    id: "item-18",
    dimensionId: 3,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 197.34,
  },
  {
    id: "item-19",
    dimensionId: 7,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 25.98,
  },
  {
    id: "item-20",
    dimensionId: 1,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 243.79,
  },
  {
    id: "item-21",
    dimensionId: 2,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 89.16,
  },
  {
    id: "item-22",
    dimensionId: 1,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 15.32,
  },
  {
    id: "item-23",
    dimensionId: 1,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 64.57,
  },
  {
    id: "item-24",
    dimensionId: 1,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 114.86,
  },
  {
    id: "item-25",
    dimensionId: 6,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 282.21,
  },
  {
    id: "item-26",
    dimensionId: 1,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 112.42,
  },
  {
    id: "item-27",
    dimensionId: 3,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 211.5,
  },
  {
    id: "item-28",
    dimensionId: 8,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 20.74,
  },
  {
    id: "item-29",
    dimensionId: 5,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 13.53,
  },
  {
    id: "item-30",
    dimensionId: 8,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 30.93,
  },
  {
    id: "item-31",
    dimensionId: 6,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 34.73,
  },
  {
    id: "item-32",
    dimensionId: 6,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 23.05,
  },
  {
    id: "item-33",
    dimensionId: 8,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 224.53,
  },
  {
    id: "item-34",
    dimensionId: 5,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 78.48,
  },
  {
    id: "item-35",
    dimensionId: 5,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 65.11,
  },
  {
    id: "item-36",
    dimensionId: 7,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 52.43,
  },
  {
    id: "item-37",
    dimensionId: 5,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 183.51,
  },
  {
    id: "item-38",
    dimensionId: 6,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 200.41,
  },
  {
    id: "item-39",
    dimensionId: 4,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 297.23,
  },
  {
    id: "item-40",
    dimensionId: 7,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 295.8,
  },
  {
    id: "item-41",
    dimensionId: 2,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 215.19,
  },
  {
    id: "item-42",
    dimensionId: 4,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 62.18,
  },
  {
    id: "item-43",
    dimensionId: 3,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 308.19,
  },
  {
    id: "item-44",
    dimensionId: 7,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 141.27,
  },
  {
    id: "item-45",
    dimensionId: 5,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 48.23,
  },
  {
    id: "item-46",
    dimensionId: 5,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 282.94,
  },
  {
    id: "item-47",
    dimensionId: 8,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 10.92,
  },
  {
    id: "item-48",
    dimensionId: 8,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 75.44,
  },
  {
    id: "item-49",
    dimensionId: 6,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 70.92,
  },
  {
    id: "item-50",
    dimensionId: 1,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 77.68,
  },
  {
    id: "item-51",
    dimensionId: 7,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 41.09,
  },
  {
    id: "item-52",
    dimensionId: 6,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 320.18,
  },
  {
    id: "item-53",
    dimensionId: 7,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 321.9,
  },
  {
    id: "item-54",
    dimensionId: 8,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 197.13,
  },
  {
    id: "item-55",
    dimensionId: 8,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 200.99,
  },
  {
    id: "item-56",
    dimensionId: 8,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 277.86,
  },
  {
    id: "item-57",
    dimensionId: 3,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 185.45,
  },
  {
    id: "item-58",
    dimensionId: 9,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 26.32,
  },
  {
    id: "item-59",
    dimensionId: 8,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 286.02,
  },
  {
    id: "item-60",
    dimensionId: 1,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 67.3,
  },
  {
    id: "item-61",
    dimensionId: 9,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 148.31,
  },
  {
    id: "item-62",
    dimensionId: 2,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 0.46,
  },
  {
    id: "item-63",
    dimensionId: 8,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 254.49,
  },
  {
    id: "item-64",
    dimensionId: 3,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 248.46,
  },
  {
    id: "item-65",
    dimensionId: 2,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 292.3,
  },
  {
    id: "item-66",
    dimensionId: 5,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 266.97,
  },
  {
    id: "item-67",
    dimensionId: 5,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 215.34,
  },
  {
    id: "item-68",
    dimensionId: 5,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 409.44,
  },
  {
    id: "item-69",
    dimensionId: 2,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 29.68,
  },
  {
    id: "item-70",
    dimensionId: 9,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 368.1,
  },
  {
    id: "item-71",
    dimensionId: 7,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 119.2,
  },
  {
    id: "item-72",
    dimensionId: 3,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 49.63,
  },
  {
    id: "item-73",
    dimensionId: 9,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 433.25,
  },
  {
    id: "item-74",
    dimensionId: 2,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 162.72,
  },
  {
    id: "item-75",
    dimensionId: 1,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 282.03,
  },
  {
    id: "item-76",
    dimensionId: 7,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 67.15,
  },
  {
    id: "item-77",
    dimensionId: 3,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 100.03,
  },
  {
    id: "item-78",
    dimensionId: 8,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 76.41,
  },
  {
    id: "item-79",
    dimensionId: 4,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 157.3,
  },
  {
    id: "item-80",
    dimensionId: 8,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 201.24,
  },
  {
    id: "item-81",
    dimensionId: 5,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 400.31,
  },
  {
    id: "item-82",
    dimensionId: 6,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 313.3,
  },
  {
    id: "item-83",
    dimensionId: 1,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 11.82,
  },
  {
    id: "item-84",
    dimensionId: 5,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 500.98,
  },
  {
    id: "item-85",
    dimensionId: 4,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 174.46,
  },
  {
    id: "item-86",
    dimensionId: 8,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 658.37,
  },
  {
    id: "item-87",
    dimensionId: 4,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 143.12,
  },
  {
    id: "item-88",
    dimensionId: 1,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 355,
  },
  {
    id: "item-89",
    dimensionId: 8,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 716.09,
  },
  {
    id: "item-90",
    dimensionId: 5,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 335.72,
  },
  {
    id: "item-91",
    dimensionId: 1,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 471.38,
  },
  {
    id: "item-92",
    dimensionId: 7,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 241.46,
  },
  {
    id: "item-93",
    dimensionId: 5,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 1006.99,
  },
  {
    id: "item-94",
    dimensionId: 2,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 796.65,
  },
  {
    id: "item-95",
    dimensionId: 7,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 1110.75,
  },
  {
    id: "item-96",
    dimensionId: 6,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "tCO2e",
    value: 827.35,
  },
  {
    id: "item-97",
    dimensionId: 9,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 1144.84,
  },
  {
    id: "item-98",
    dimensionId: 7,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "tCO2e",
    value: 282.5,
  },
  {
    id: "item-99",
    dimensionId: 8,
    info: "+ 12% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Emissions de GES scope 3",
    labelEN: "Scope 3 GHG emissions",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "tCO2e",
    value: 2719.13,
  },
]
