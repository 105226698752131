import { Stack } from "@mantine/core"
import { useForm } from "@mantine/form"
import { noop } from "lodash"

import { CSRDQuestionAnswer } from "@kiosk/types/csrd"
import { DatoDisclosureRequirementResponse } from "@kiosk/types/data/dato"

import {
  useMarkDisclosureRequirementAsDoneMutation,
  useUpsertDisclosureRequirementAnswersMutation,
} from "@kiosk/front/api/reports/index"
import { CSRDQuestionTree } from "@kiosk/front/components/csrd/CSRDQuestionTree"
import { FormShape } from "@kiosk/front/components/csrd/types"

import { SaveFooter } from "./SaveFooter"

type FormProps = {
  // TODO: weird type. To clean.
  disclosureRequirement: DatoDisclosureRequirementResponse["disclosureRequirement"]
  initialValues: Record<string, CSRDQuestionAnswer>
  progress?: number
  aiGenerateParagraph: (disclosureRequirementId: string) => void
  isPendingParagraph?: boolean
}

export const CSRDForm = ({
  disclosureRequirement,
  initialValues,
  progress,
  aiGenerateParagraph,
  isPendingParagraph,
}: FormProps) => {
  const form = useForm<FormShape>({
    initialValues,
  })

  const { mutateAsync: upsertDisclosureRequirements, isPending } =
    useUpsertDisclosureRequirementAnswersMutation(disclosureRequirement.id)

  const { mutateAsync: markDisclosureRequirementAsDone } =
    useMarkDisclosureRequirementAsDoneMutation()

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        upsertDisclosureRequirements(Object.values(values)),
      )}
    >
      <Stack gap={40} mb={40}>
        {disclosureRequirement.questions.map((question) => (
          <CSRDQuestionTree key={question.id} question={question} form={form} />
        ))}
        <SaveFooter
          isPendingGeneration={Boolean(isPendingParagraph)}
          onGenerate={() => {
            aiGenerateParagraph(disclosureRequirement.id)
          }}
          isCompleted={progress === 100}
          hasUnsavedChanges={form.isDirty()}
          isPendingUpdate={isPending}
          onCancel={() => (form.isDirty() ? form.reset() : noop())}
          onSave={() => form.resetDirty()}
          onMarkAsDone={() =>
            markDisclosureRequirementAsDone(disclosureRequirement.id)
          }
        />
      </Stack>
    </form>
  )
}
