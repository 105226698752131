import { DataPointDemo } from "@kiosk/front/api/dashboard/types"

export const Environment12: DataPointDemo[] = [
  {
    id: "item-0",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 46.12,
  },
  {
    id: "item-1",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 124.62,
  },
  {
    id: "item-2",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 16.4,
  },
  {
    id: "item-3",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 98.9,
  },
  {
    id: "item-4",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 39.01,
  },
  {
    id: "item-5",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 144.11,
  },
  {
    id: "item-6",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 117.89,
  },
  {
    id: "item-7",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 25.7,
  },
  {
    id: "item-8",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 114.19,
  },
  {
    id: "item-9",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 2.6,
  },
  {
    id: "item-10",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 32.58,
  },
  {
    id: "item-11",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 63.19,
  },
  {
    id: "item-12",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 137.81,
  },
  {
    id: "item-13",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 145.6,
  },
  {
    id: "item-14",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 104.23,
  },
  {
    id: "item-15",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 143.87,
  },
  {
    id: "item-16",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 119.73,
  },
  {
    id: "item-17",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 17.44,
  },
  {
    id: "item-18",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 89.29,
  },
  {
    id: "item-19",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 152.63,
  },
  {
    id: "item-20",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 127.81,
  },
  {
    id: "item-21",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 116.13,
  },
  {
    id: "item-22",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 142.97,
  },
  {
    id: "item-23",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 57.68,
  },
  {
    id: "item-24",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 150.7,
  },
  {
    id: "item-25",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 87.51,
  },
  {
    id: "item-26",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 162.49,
  },
  {
    id: "item-27",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 20.71,
  },
  {
    id: "item-28",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 24.07,
  },
  {
    id: "item-29",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 83.35,
  },
  {
    id: "item-30",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 47.55,
  },
  {
    id: "item-31",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 162.57,
  },
  {
    id: "item-32",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 3.54,
  },
  {
    id: "item-33",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 50.2,
  },
  {
    id: "item-34",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 94.2,
  },
  {
    id: "item-35",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 58.22,
  },
  {
    id: "item-36",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 61.65,
  },
  {
    id: "item-37",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 128.58,
  },
  {
    id: "item-38",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 59.52,
  },
  {
    id: "item-39",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 75.39,
  },
  {
    id: "item-40",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 134.64,
  },
  {
    id: "item-41",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 71.82,
  },
  {
    id: "item-42",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 51.18,
  },
  {
    id: "item-43",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 111.07,
  },
  {
    id: "item-44",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 69.47,
  },
  {
    id: "item-45",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 35.37,
  },
  {
    id: "item-46",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 130.35,
  },
  {
    id: "item-47",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 5.38,
  },
  {
    id: "item-48",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 13.2,
  },
  {
    id: "item-49",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 97.5,
  },
  {
    id: "item-50",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 100.36,
  },
  {
    id: "item-51",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 74.49,
  },
  {
    id: "item-52",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 92.36,
  },
  {
    id: "item-53",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 165.7,
  },
  {
    id: "item-54",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 24.64,
  },
  {
    id: "item-55",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 18.51,
  },
  {
    id: "item-56",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 61.08,
  },
  {
    id: "item-57",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 155.66,
  },
  {
    id: "item-58",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 214.52,
  },
  {
    id: "item-59",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 83.78,
  },
  {
    id: "item-60",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 4.44,
  },
  {
    id: "item-61",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 76.09,
  },
  {
    id: "item-62",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 38.8,
  },
  {
    id: "item-63",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 32.69,
  },
  {
    id: "item-64",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 27.87,
  },
  {
    id: "item-65",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 265.7,
  },
  {
    id: "item-66",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 55.07,
  },
  {
    id: "item-67",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 70.1,
  },
  {
    id: "item-68",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 263.33,
  },
  {
    id: "item-69",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 241.5,
  },
  {
    id: "item-70",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 136.73,
  },
  {
    id: "item-71",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 30.32,
  },
  {
    id: "item-72",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 29.29,
  },
  {
    id: "item-73",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 183.68,
  },
  {
    id: "item-74",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 188.98,
  },
  {
    id: "item-75",
    dimensionId: 2,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 130.1,
  },
  {
    id: "item-76",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 181.97,
  },
  {
    id: "item-77",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 202.42,
  },
  {
    id: "item-78",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 171.56,
  },
  {
    id: "item-79",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 118.18,
  },
  {
    id: "item-80",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 101.24,
  },
  {
    id: "item-81",
    dimensionId: 7,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 37.37,
  },
  {
    id: "item-82",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 156.49,
  },
  {
    id: "item-83",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 198.46,
  },
  {
    id: "item-84",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 130.4,
  },
  {
    id: "item-85",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 146.14,
  },
  {
    id: "item-86",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 145.42,
  },
  {
    id: "item-87",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 314.82,
  },
  {
    id: "item-88",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 318.15,
  },
  {
    id: "item-89",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 135.47,
  },
  {
    id: "item-90",
    dimensionId: 6,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 263.79,
  },
  {
    id: "item-91",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 371.12,
  },
  {
    id: "item-92",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 158.02,
  },
  {
    id: "item-93",
    dimensionId: 5,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 545.77,
  },
  {
    id: "item-94",
    dimensionId: 9,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 565.04,
  },
  {
    id: "item-95",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2023,
    type: "single-value",
    unit: "kg",
    value: 372.75,
  },
  {
    id: "item-96",
    dimensionId: 3,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 388.83,
  },
  {
    id: "item-97",
    dimensionId: 4,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 194.76,
  },
  {
    id: "item-98",
    dimensionId: 8,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2024,
    type: "single-value",
    unit: "kg",
    value: 93.07,
  },
  {
    id: "item-99",
    dimensionId: 1,
    info: "- 50% vs 2023",
    isMandatoryInDashboard: true,
    labelFR: "Qté de déchets recyclés ou réutilisés",
    labelEN: "Quantity of recycled or reused waste",
    numberType: "decimal",
    time: 2022,
    type: "single-value",
    unit: "kg",
    value: 2422.24,
  },
]
